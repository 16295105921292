import React from 'react'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Mentors from '../components/Mentors'
import Message from '../components/Message'
import Messagebis from '../components/Messagebis'
import LandingTop from '../components/LandingTopP'
import styled from "styled-components";
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";

import Newsletter from '../components/Newsletter'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';

const Slide = styled.div`
  width: 90vw;
  height: 70vh;
  display: flex;
  align-items: center;
  ${tabletteLanding({ width:"100vw", height:"26vh"})};

  ${mobileLanding({flexDirection:"column", height:"auto", width:"100vw"})};

`;
const Slide1 = styled.div`
  width: 90vw;
  height: 70vh;
  display: flex;
  align-items: center;
  ${mobileLanding({flexDirection:"column", height:"auto", width:"100vw", marginTop:"-20px"})};
  ${tabletteLanding({ width:"100vw", height:"26vh"})};

`;

const ImgContainer = styled.div`
  height: 100%;
  
`;
const ImgContainer1 = styled.div`
  height: 100%;
  ${mobileLanding({display:"none"})};

`;
const ImgContainer2 = styled.div`
  height: 100%;
  ${tabletteLanding({display:"none"})};
  ${computerLanding({display:"none"})};

`;
const Image = styled.img`
  width: 49vw;
  ${computerLanding({height: "min(65vh, 40vw)"})};
  object-fit:cover;
  ${mobileLanding({width:"100vw", height:"30vh"})};
  ${tabletteLanding({width:"52vw",height: "100%"})};

`;

const InfoContainer = styled.div`
  ${computerLanding({height: "min(70vh, 40vw)"})};

  padding: 50px;
  margin-top: 0px;
  ${mobileLanding({marginTop:"0", padding:"30px"})};
  ${tabletteLanding({ display:"flex", alignItems:"center",padding:"20px", marginTop:"20px", height:"26vh", width:"44vw"})};

`;

const InfoContainer4 = styled.div`
  ${computerLanding({height: "min(70vh, 40vw)"})};

  padding: 50px;
  margin-top: 0px;
  ${mobileLanding({marginTop:"0", padding:"30px", paddingBottom:"5px"})};
  ${tabletteLanding({ display:"flex", alignItems:"center",padding:"20px", height:"100%", width:"44vw"})};

`;

const Title = styled.h1`
  font-size: 35px;
  ${computerLanding({marginTop:"0"})};
  @media only screen and (min-width: 1361px){
      margin-top:7vw;
  }
  width:calc(50vw - 100px);
  overflow: hidden;
  ${mobileLanding({fontSize:"20px", width:"100%", textAlign:"center"})};
  ${tabletteLanding({ fontSize:"27px", marginLeft:"auto", marginRight:"auto", lineHeight:"40px"})};

`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", display:"none"})};
  ${computerLanding({ display:"none"})};
  ${tabletteLanding({fontSize:"14px", width:"100%", textAlign:"center", marginTop:"4vh", marginBottom:"2vh", width:"95%",marginLeft:"auto", marginRight:"auto"})};

`;

const Desc1 = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", display:"none"})};
  ${computerLanding({ display:"none"})};

  ${tabletteLanding({fontSize:"14px", width:"100%", textAlign:"center", marginTop:"4vh", marginBottom:"4vh", width:"95%",marginLeft:"auto", marginRight:"auto"})};

`;
const Desc3 = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", margin:"30px 0px"})};


  ${tabletteLanding({ display:"none"})};

`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

const Subscribe1 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto", display:"none"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"15px",width:"55%", height:"45px", marginLeft:"auto", marginRight:"auto",marginBottom:"4vh"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px",display:"none"})}

`;

const Subscribe11 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({display:"none"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;

const Subscribe3 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({display:"none"})}
${tabletteLanding({display:"none"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;

const Home = ({ads}) => {
 const loc="uploads/stat"
    return (
       <div>
            <LandingTop/>

            <Slide1 >


<InfoContainer>
  <Title>NOTRE VOCATION EST D'ACCOMPAGNER LES PROFESSEURS DE YOGA DANS LEUR RÉUSSITE </Title> 
  <Desc3>NOUS SOMMES À VOTRE ECOUTE POUR CONSTAMMENT AMELIORER NOS SERVICES ET VOUS FAVORISER DANS LE DEVELOPPEMENT DE VOTRE ACTIVITÉ</Desc3>
  <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

  <Subscribe11>S'INSCRIRE</Subscribe11>
  </Link>
</InfoContainer>
<ImgContainer>
  <Image src={ads+loc+"/yogaco45.jpg"} />
</ImgContainer>
</Slide1>
<Desc>CRÉEZ UN PROFIL VOUS RESSEMBLANT ET MONETISEZ VOTRE CONTENU EN TOUTE LIBERTÉ</Desc>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Subscribe1>S'INSCRIRE</Subscribe1>
              </Link>
            <Slide >
            <ImgContainer1>
              <Image src={ads+loc+"/publi.jpeg"} />
            </ImgContainer1>
            <InfoContainer4>
              <Title>PUBLIEZ ET MONÉTISEZ VOTRE CONTENU EN TOUTE SIMPLICITÉ</Title> 
              <Desc3>CRÉEZ UN PROFIL VOUS RESSEMBLANT ET MONETISEZ VOTRE CONTENU EN TOUTE LIBERTÉ </Desc3>
              <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Subscribe3>S'INSCRIRE</Subscribe3>
              </Link>
            </InfoContainer4>

          </Slide>
          <Desc1>NOUS SOMMES À VOTRE ÉCOUTE POUR CONSTAMMENT AMÉLIORER NOS SERVICES ET VOUS FAVORISER DANS LE DEVELOPPEMENT DE VOTRE ACTIVITÉ </Desc1>
            {/* <Announcement/> */}
            <Slide >
            <ImgContainer2>
              <Image src={ads+loc+"/rapproche.jpeg"} />
            </ImgContainer2>
            <InfoContainer4>
              <Title>SOYEZ PLUS PROCHE DE VOS ÉLÈVES</Title> 
              <Desc3>ECHANGEZ, DISCUTEZ ET PARTAGEZ SANS LIMITES AVEC VOS ÉLÈVES POUR CRÉER UNE COMMAUNAUTÉ ENGAGÉE</Desc3>
              <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Subscribe3>S'INSCRIRE</Subscribe3>
              </Link>
            </InfoContainer4>
            <ImgContainer1>
              <Image src={ads+loc+"/rapproche.jpeg"} />
            </ImgContainer1>
          </Slide>
                        <Desc1>ECHANGEZ, DISCUTEZ ET PARTAGEZ SANS LIMITES AVEC VOS ÉLÈVES POUR CREER UNE COMMAUNAUTÉ ENGAGÉE</Desc1>
              {/* <Subscribe1>S'INSCRIRE</Subscribe1> */}
          <Slide >
          <ImgContainer>
              <Image src={ads+loc+"/payer.jpg"} />
            </ImgContainer>
            <InfoContainer>
              <Title>PAYEZ UNIQUEMENT LORSQUE VOUS GAGNEZ DE L'ARGENT</Title> 
              <Desc3>L'ENSEMBLE DE NOS FRAIS SE LIMITENT À 5% DES MONTANTS PAYÉS PAR VOS ÉLÈVES PLUS LES FRAIS DE TRAITEMENT DES PAIEMENTS</Desc3>
              <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Subscribe11>S'INSCRIRE</Subscribe11>
              </Link>
            </InfoContainer>

          </Slide>
          <Desc>L'ENSEMBLE DE NOS FRAIS SE LIMITENT À 8% DES MONTANTS QUE VOUS PERCEVEZ</Desc>
          <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Subscribe1>S'INSCRIRE</Subscribe1>
              </Link>
            
            <Newsletter ads={ads}/>
            <Footer/>
    <FooterS/>
       </div>

    )
}

export default Home
