import styled from "styled-components";
import { Link } from "react-router-dom";


const H1 = styled.h1`
margin-bottom:20px;
margin-top:20px;
`;

const H2 = styled.h2`
margin-bottom:15px;
margin-top:15px;
`;

const H4 = styled.h4`
margin-bottom:10px;
margin-top:10px;
`;

const Login = () => {
    const date = "13/03/23";
    const professeur="Professeur";
    const eleve="Élève"
    const professeurs="Professeurs";
    const eleves="Élèves"
    const appName="SoYogis";
    const entrepriseFull = "OmTech Innovations S.A.S.U (dont le siège social est situé au 229 rue Saint-Honoré, 75001, Paris),"

    const entreprise="OmTech Innovations"

  return (
<div style={{marginLeft:"15px", marginRight:"15px"}}>

<div style={{borderBottom:"1px solid grey", marginBottom:"50px", paddingBottom:"30px", paddingTop:"10px"}}>

          <H1>
            Conditions Générales d'Utilisation
            </H1>
          <div >
            Version du {date}
            </div>
</div>
            <div>
              Bienvenue sur {appName}, la plateforme qui permet aux {professeurs} de yoga de vivre de leur passion 
              et aux {eleves} de profiter d'un environnement propice à l'épanouissement et à la progression.
              </div>
              <br/>
              <br/>

<div>
 {appName} a été créée et est exploitée par la société {entrepriseFull}.
  </div>


            <br/>
              <br/>
              <div>En temps qu'opérateur de la Plateforme {appName}, la société {entreprise} propose les services suivants :</div>
              <br/>
              <ul>
                <li>
                  Le référencement et l'hébergement des Médias et des Profils des utilisateurs.                 
                  <div>
                  Chaque utilisateur, qu'il soit {eleve} ou {professeur}, peut poster du contenu en rapport avec la pratique du yoga. {appName} référence et héberge 
                  les Médias et les Profils dans le but de fournir la meilleure expérience possible à ses utilisateur. 
                  </div>

                  </li>
                  <br/>

                <li>
                  La mise en relation entre des {professeurs} de yoga et des {eleves} en vue de créer des échanges personnalisés comportant ou non différents Médias et se faisant à titre gratuit ou onéreux.
                  <div>
                  
                  Chez {appName} nous pensons que l'intéractions entre un {professeur} et son {eleve} est centrale pour permettre à l'{eleve} de s'épanouir pleinement en profitant d'informations 
                  adaptées à son niveau de pratique et aux contraintes de son corps. Dans cette démarche la plateforme {appName} permet aux {eleves} de souscrire à un Abonnement auprès de leurs {professeurs} favoris. Cette Abonnement 
                  permet aux {eleves} d'accéder aux Médias des {professeurs} et d'échanger directement avec les Professeurs par le biai d'une messagerie instantannée pour receuillir des informations personnalisées et être accompagné pour évoluer à son rythme.
                  Chaque {professeur} est libre de pratiquer les tarifs lui semblant être le plus en adéquation avec son offre. 
                  
                  </div>
                  </li>
                  <br/>

                  <li>
                    La mise à disposition d'un espace de partage orienté vers la pratique du yoga et sa philosophie, dans lequel les pratiquants de yoga peuvent s'apanouir et s'immerger.
                    <div>
                    {appName} encourage le partage de Médias relatifs au yoga et les échanges entre utilisateurs pour inspirer, motiver et guider tous les pratiquants.
                    </div>
                    </li>
<br/>
                    <li>
Intermédiaire de paiements.
                    </li>

                </ul>
 
                      <br/>
              <br/>
              <div>

              Les présentes Conditions d’utilisation régissent votre utilisation de {appName}, 
              sauf lorsque nous indiquons expressément que des conditions distinctes (et non les présentes) s’appliquent, 
              et fournissent des informations sur le Service {appName}, détaillé ci-dessous. 
              En créant un compte {appName} ou en utilisant {appName}, vous acceptez sans réserves les présentes Conditions.             
            </div>


            <H1>
          1.    Conditions Générales d'Utilisation pour tous les Utilisateurs
              </H1>


            <H2>
              1.1 Définitions
              </H2>
              <div>
                Les termes mentionnés ci-dessous, ont dans les présentes Conditions Générales d’Utilisation, la signification suivante :

                </div>
                <br/>
<ul>
  <li>
    « Abonnement » : désigne le droit d’accès aux Médias exclusifs et à la messagerie d'un Professeur en contrepartie de son prix ;

    </li>
    <br/>
    <li>
      « Abonné » : désigne l’Utilisateur ayant souscrit à un Abonnement auprès d’un Professeur ; 

    </li>
    <br/>
    <li>
      « Frais de service » : désigne un pourcentage du montant des transactions {eleve}/{professeur} retenu par {appName} en contrepartie de ses Services ;
   
    </li>
    <br/>
<li>
  « Contrat type entre {eleves} et {professeurs} »  : désigne les termes qui régissent chaque Transaction en un {eleve} et un {professeur};  

  </li>
  <br/>
<li>
  « Conditions Générales d’Utilisation » ou « CGU » ou « Contrat » : désigne les présentes conditions contractuelles mises à disposition sur la Plateforme, afin d’encadrer l’utilisation de celle-ci par tout Utilisateur ; 

  </li>
  <br/>



  <li>
    « Conditions d’Utilisation » : désigne l'ensemble des accords qu'un utilisateur s'engage à respecter lorsqu'il utilise {appName}. Les Conditions d'Utilisation
    regroupent : les présentes Conditions Générales d'Utilisation, <a href="/contrattype">le Contrat type entre {eleves} et {professeurs}</a>, <a href="/politiqueconfidentialite">la Politique de confidentialité et de protection des données</a>
, les politique d'utilisation des cookies.
  </li>
  <br/>
  <li>
    « Contenu » : désigne tout élément publié par un Utilisateur sur la Plateforme, que ce soit un Média publié par un {professeur}, 
    ou un message ou contenu de quelque nature que ce soit (texte, image, vidéo, son, multimédia) publié par tout Utilisateur ;

    
    </li>
    <br/>
    <li>
    « TVA » : désigne la taxe sur la valeur ajoutée en France et toute autre taxe imposée en plus ou en remplacement de celle-ci au taux imposé de temps à autre et toute taxe équivalente ou similaire gouvernementale
    , étatique, fédérale, provinciale ou municipale indirecte, charge, droit, impôt ou prélèvement dans toute autre juridiction ; et

    </li>
    <br/>
    <li>
      « Taxe » : comprend toutes les formes d'impôts et de charges, droits, impôts, contributions, prélèvements, retenues ou obligations statutaires, gouvernementaux, étatiques, fédéraux, provinciaux, locaux ou municipaux, partout où ils sont exigibles et qu'ils soient de France ou de toute autre juridiction.
      </li>
    <br/>
<li>
  « Professeur » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné sa volonté d'"enseigner le yoga" à son inscription sur {appName} ;

  </li>
  <br/>
  <li>
  «Élève » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné sa volonté d'"apprendre le yoga" à son inscription sur {appName} ou un Professeur ayant souscrit à au moins un abonnement depuis la création de son compte ;

  </li>
  <br/>
<li>
  
  « Médias » : désigne l’ensemble des photographies, vidéos, musiques, fichier audio, podcast, Lives, informations, textes, logos, marques, animations, dessins et modèles, 
  données et de façon générale tous les éléments et contenus créés par un {professeur} et publiés par ce dernier sous sa seule responsabilité sur la Plateforme selon les modalités, 
  la forme et les conditions qui lui sont proposées dans le cadre des Services ;

  </li>
  <br/>
  <li>
    « Média Public » : désigne tout Média publié par un Professeur sur son Profil et accessible à tout Utilisateur,  à titre gratuit ;  

    </li>
    <br/>
    <li>
      « Média Non-Public » : désigne tout Média communiqué sur le Profil, de manière floutée. Le Média Non-Public peut être un Média visionnable contre un Abonnement ;

      </li>
      <br/>
    <li>
      « Plateforme » : désigne la Plateforme en ligne accessible à l’adresse suivante : https://www.soyogis.com/. La Plateforme regroupe l’ensemble des pages web, application, Services et fonctionnalités proposés aux Utilisateurs ; 

      </li>
      <br/>
  <li>
    « Profil » : désigne la page de présentation des Utilisateurs. Dans le cas d'un {professeur}, sur cette page est présentée tant 
    l’activité de ce dernier que les Médias et Abonnements qu’il propose;

    
    </li>
    <br/>
  <li>
    « Utilisateur » : désigne toute personne qui accède et navigue sur la Plateforme ;

    </li>
  </ul>






<H2>
  1.2 Modification des conditions générales d'utilisation
  </H2>

<div>
  La Plateforme {appName} se réserve le droit de modifier les conditions générales d'utilisation. 
<div>
  <div>
    {appName} pourra modifier les conditions d'utilisation sans en avertir les utilisateur dans les cas suivants : 
    </div>
  </div>

<ul>
  <li>
    Pour s'aligner avec une loi ou certaines demandes de différentes instances de régulation ;

    </li>
    <br/>
  <li>
    Pour adresser un imprévu ou un danger éminent mettant en péril {appName} ou ses Utilisateurs (fraude, malware, spam, fuites de données ou autres 
    risques liés aux activités créatrices en ligne).

    </li>

  </ul>


  <div>
    </div>
  
  Lorsqu'il s'agit de modifier les Condititions Générales d'Utilisations pour des raisons différentes des cas listés ci-dessus,  {appName} informera ses Utilisateurs des modifications
  par email ou directement par le biai de {appName}. La plateforme {appName} laissera un délai raisonnable aux Utilisateurs pour se manifester s'ils n'acceptent 
  pas les nouvelles
  Conditions Générales d'Utilisation, et s'ils souhaitent voir leur compte être supprimé de la plateforme avant la mise en place des nouvelles conditions. 
  Une fois que les
  nouvelles Conditions Générales d'Utilisation seront effectives et sans manifestation explicite, il sera considéré que chaque Utilisateur à accepté les nouvelles
   conditions et de ce fait chaque utilisateur devra les respecter.
  
  </div>
<H2>
 1.3  Modifications des services ou suspension de {appName}
  </H2>
<div>
  {appName} peut être modifiée ou suspendue. {appName} peut se voir modifiée régulièrement pour n'importe quelle raison pouvant par exemple être une modification 
  des intérets des utilisateurs ou de {entreprise}, pour améliorer la performance ou améliorer la sécurité de ses 
  Utilisateurs. {appName} s'efforcera d'avertir ses Utilisateur de tout changement les impactant directement. De plus nous ne garantissons pas que {appName} ne 
  sera jamais supprimé ou que son contenu sera toujours disponible. {appName} pourra être suspendu pour des raisons divers.
  Dans ce cas nous nous efforcerons d'informer les Utilisateurs pour qu'ils puissent prendre les meilleurs dispositions et qu'ils soient le moins impactés possible.
  </div>

  <H2>1.4 Connection et création de compte</H2>

  Pour utiliser l'ensemble des services de {appName}, les Utilisateurs doivent êtres enregistrés et doivent créer un compte sur {appName}. 
  Les utilisateurs doivent fournir une adresse email valide. Votre mot de passe doit être unique et les utilisateurs s'engagent à le garder secret. 
  De plus, pour créer un compte les utilisateurs doivent :
  
  <ul>
    <li>
      Avoir au moins 18 ans ;

      </li>
      <br/>
    <li>
      Ne pas avoir été interdit d'utiliser nos services par le passé en raison d'un non respect de nos Conditions d'Utilisation ;

      </li>
      <br/>

      <li>
        Ne pas être interdit d'utiliser nos services en vertu des lois applicables ou d'une decision de justice. 
        En particulier vous ne devez pas être un délinquant sexuel condamné.  

        </li>
    </ul>
  

<H2>
  1.5 Obligations des Utilisateurs 
  </H2>
<div>
  
  <div>
  Lors de l'utilisation de {appName}, les Utilisateurs s'engagent à :
    </div>

<ul>
  <li>
    Ne pas creer un compte si un de leurs anciens compte a été suspendu ou résilié ;

    </li>
    <br/>

    <li>
      Fournir des informations authentiques, véridiques et complètes ;

      </li>
      <br/>

    <li>
      Mettre à jour leurs informations lorsqu'elles changent ;

      
      </li>
  </ul>

<ul>
  <li>Si vous aviez déjà un compte chez {appName}, vous confirmez que votre ancien compte n'a pas été résilié ou suspendu par nous en raison de la violation de l'un de nos termes ou politiques inscrits dans l'ensemble de nos Conditions d'Utilisation ;</li>
  <br/>
  <li>Vous mettrez à jour rapidement toutes les informations que vous nous avez soumises lorsqu'elles changent ;</li>
  <br/>
  <li>Vous acceptez de recevoir des communications de notre part électroniquement, y compris par courrier électronique et messages publiés sur votre compte {appName}, et le traitement de vos données personnelles, comme plus amplement détaillé dans notre Politique de Confidentialité.</li>
  <br/>
  <li>Vous veillerez à ce que les informations de votre compte ainsi que les informations nécessaires à votre connection 
    soient confidentielles et sécurisées, y compris vos informations d'utilisateur, mots de passe et toute autre information faisant partie de nos
     procédures de sécurité, et vous ne les divulguerez à personne d'autre que vous. Vous nous contacterez rapidement par courrier électronique à l'adresse support@soyogis.com si vous pensez que quelqu'un a utilisé ou utilise 
     votre compte sans votre autorisation ou si votre compte a été soumis à une autre violation de sécurité. Vous vous engagez également à vous déconnecter de 
     votre compte à la fin de chaque session et à être particulièrement prudent lors de l'accès à votre compte à partir d'un ordinateur public ou partagé, 
     afin que d'autres ne puissent pas accéder, visualiser ou enregistrer votre mot de passe ou d'autres informations personnelles ;</li>
     <br/>
     <li>Vous êtes responsable de toutes les activités sur votre compte, même si, contrairement aux Conditions Générales d'Utilisation, quelqu'un d'autre utilise votre compte ;</li>
     <br/>
     <li>Vous vous conformerez pleinement à ces Conditions Générales d'Utilisation "pour tous les utilisateurs" et à toutes autres parties des Conditions d'Utilisation qui s'appliquent à votre utilisation de {appName}.</li>
</ul>

<H2>1.6 Droits et suspensions/résiliation de compte</H2>
    <ul>

      <li>Nous pouvons, mais nous n'avons pas l'obligation, de modérer ou de vérifier tout votre contenu pour vérifier le respect des Conditions d'Utilisation 
        et/ou de toute loi applicable ;</li>
        <br/>
      <li>Notre politique consiste à suspendre l'accès à tout contenu que vous publiez sur {appName} que nous savons pouvant être en désaccord avec les Conditions d'Utilisation 
       et/ou de toute loi applicable pendant que nous enquêtons sur la non-conformité ou l'illégalité présumées de ce contenu.
        Si nous suspendons l'accès à l'un de vos contenus, vous pouvez demander un examen de notre décision de suspendre l'accès au contenu en question en nous 
        contactant par courrier électronique à l'adresse support@soyogis.com. Suite à notre enquête sur la non-conformité ou l'illégalité présumées du contenu en question, nous pouvons prendre toute mesure que nous jugeons 
        appropriées, y compris la réinstauration de l'accès au contenu ou la suppression permanente ou la désactivation de l'accès au contenu en question sans 
        devoir obtenir votre consentement et sans vous donner de préavis. Vous acceptez de nous fournir à vos propres frais dans les meilleurs délais toute assistance
         raisonnable (y compris en nous fournissant des copies des informations que nous demandons) dans notre enquête. Nous ne serons pas responsables de toute perte 
         que vous subissez en raison de la suspension de l'accès à votre contenu ou de tout autre mesure que nous prenons de bonne foi pour enquêter sur toute 
         non-conformité ou illégalité présumées de votre contenu en vertu de cette section ;</li>
         <br/>
      <li>Si nous suspendons l'accès ou supprimons l'un de vos contenus, nous vous en informerons par e-mail ou message électronique à votre compte {appName}, mais nous n'avons pas l'obligation de vous donner un préavis ;</li>
  </ul>
  <ul>
    <li>
      
    <p>Nous nous réservons le droit, à notre seule discrétion, de mettre fin à votre accord avec nous et votre accès à {appName} pour n'importe quelle raison en vous donnant 30 jours de préavis par courrier électronique ou message électronique à votre compte {appName}.
     Nous pouvons également suspendre l'accès à votre compte utilisateur ou mettre fin à votre accord avec nous et votre accès à {appName} immédiatement et sans préavis :</p>
  <ul>
    <li>Si nous pensons que vous avez ou pourriez avoir gravement ou répétitivement violé une partie des Conditions d'Utilisation 
   , ou si vous tentez ou menacez de violer une partie des Conditions d'Utilisation de manière qui a ou pourrait avoir des conséquences graves pour nous ou un 
   autre utilisateur ; ou</li>
    <li>Si vous prenez une mesure qui, à notre avis, nous a causé ou est susceptible de nous causer une perte ou qui nuit autrement à la réputation de {appName} ;</li>
 
    </ul>
  </li>
  <br/>
  <li>
    Si nous suspendons l'accès à votre compte utilisateur ou mettons fin à votre accord avec nous et votre accès à {appName}, nous vous le ferons savoir. Pendant toute période où l'accès à votre compte utilisateur est suspendu, tout paiement 
    d'{eleve} qui aurait autrement dû être dû pendant la période de suspension sera suspendu et nous pouvons retenir tout ou une partie des gains de {professeur}  dus à vous mais non encore payés.
    </li>
    <br/>
    <li>
      
      À la résiliation de votre compte, nous pouvons traiter votre contenu de la manière appropriée conformément à notre Politique de confidentialité 
      (y compris en le supprimant) et vous ne serez plus en droit d'accéder à votre contenu. Il n'y a aucune installation technique sur {appName} pour que vous 
      puissiez accéder à votre contenu après la résiliation de votre compte.

      </li>
      <br/>
      <li>
    Nous pouvons enquêter sur tout usage suspect ou allégué ou illégal de {appName} et coopérer avec les organismes de contrôle de la conformité en matière 
    de telles enquêtes.

        </li>
    </ul>


  
  

 <H2>1.7 Responsabilité de {appName} et {entreprise}</H2>
 Nous rappelons que {entreprise} intervient en temps qu'opérateur de la plateforme en ligne {appName}. Dans ce cadre notre rôle est limité à 
 l’hébergement et au référencement des Médias, au rôle d'intermédiaire de paiement ainsi qu’à la mise en relation des {professeurs} et des {eleves}, pour le partage et la valorisation de Médias.  Les informations 
 fournies dans cette section dressent plus en détails les limites de notre responsabilité. Cette liste peut se voir compléter par d'autres éléments présentés dans d'autre sections de nos Conditions d'Utilisation.
  <ul>

<li>
  
Nous n'autorisons ni n'approuvons le contenu sur {appName}, et les opinions exprimées par les Professeurs ou les Elèves sur {appName} ne représentent pas nécessairement nos opinions.
  
  </li>
<br/>
<li>
  
Nous ne vous accordons aucun droit sur les Médias publiés sur {appName}. Ces droits ne peuvent vous être accordés que par les Professeurs ou les {eleves} en possédant eux-même les droits.

  </li>
  <br/>
  {/*<li>
    Votre contenu peut être vu par des personnes qui reconnaissent votre identité. Nous ne serons en aucun cas responsables envers vous si vous êtes identifié 
    à partir de votre Contenu.
     Bien que nous puissions, de temps à autre et à notre seule discrétion, proposer certaines technologies de géorepérage ou de géolocalisation sur {appName}, 
     vous comprenez 
     et acceptez que nous ne garantissons pas l'exactitude ou l'efficacité de cette technologie, et vous n'aurez aucune réclamation contre nous découlant de 
     votre utilisation ou votre confiance dans toute 
     technologie de géorepérage ou de géolocalisation sur {appName}.
    </li>
    <br/>*/}
  <li>
      Tout le contenu est créé, sélectionné et fourni par les Utilisateurs et non par nous. Nous ne sommes pas responsables de l'examen ou de la modération du 
      contenu,
       et nous ne sélectionnons ni ne modifions le contenu qui est stocké ou transmis via {appName}. Nous n'avons aucune obligation de surveiller le contenu ou de 
       détecter les 
       violations des Conditions d'Utilisation.

      </li>
      <br/>
      <li>
        Vous reconnaissez qu'une fois votre contenu publié sur {appName}, nous ne pouvons pas contrôler et ne serons pas responsables envers vous de l'utilisation que d'autres utilisateurs ou des tiers font de ce contenu. 
        Vous pouvez supprimer votre compte à tout moment, mais vous reconnaissez que la suppression de votre compte n'empêchera pas à elle seule la diffusion de tout Contenu qui aurait été enregistré par d'autres 
        Utilisateurs en violation des Conditions d'Utilisation ou par des tiers avant la suppression de votre compte.
        </li>
        <br/>
<li>
  
Nous ne sommes pas responsables de la perte, du vol ou de la compromission des comptes d'utilisateurs, des mots de passe, des comptes de messagerie ou de toute activité non autorisée qui en résulte ou des paiements ou retraits de fonds non autorisés qui en résultent.
  
  
  
  </li>
  <br/>
<li>
  Bien que nous essayions de nous assurer que {appName} est sécurisé et exempt de bogues et de virus, nous ne pouvons pas promettre que ce sera le cas et n'avons aucun contrôle sur le contenu fourni par les utilisateurs.
  </li>
  <br/>
<li>
  
Nous ne sommes pas responsables de la disponibilité d'Internet, ni de toute erreur dans vos connexions, appareil ou autre équipement, ou logiciel pouvant survenir en relation avec votre utilisation de {appName}.
  </li>
  <br/>

      <li>
        
Nous ne promettons pas que {appName} est compatible avec tous les appareils et systèmes d'exploitation. Vous êtes responsable de la configuration de votre technologie de l'information, 
de votre appareil et de vos programmes informatiques pour accéder à {appName}. Vous devez utiliser votre propre logiciel de protection antivirus.
        
        </li>
        <br/>
  <li>
    
    Les documents que nous rendons accessibles sur {appName} pour les utilisateurs sont uniquement à titre d'information générale. Nous ne faisons aucune 
    promesse ou garantie 
    quant à l'exactitude ou autre de ces matériaux, ou que les utilisateurs obtiendront un résultat particulier en utilisant ces matériaux.
    </li>
    <br/>
      <li>
        
        Vous convenez que vous n'avez aucune obligation de suivre les suggestions, commentaires, critiques ou instructions reçues d'un autre utilisateur de 
        {appName} et que si vous choisissez 
        de le faire, vous le faites entièrement à vos risques et périls.
        </li>
        <br/>
    <li>
      Nous ne faisons aucune promesse ou garantie d'aucune sorte que les Professeurs  gagneront une somme d'argent particulière 
      (ou toute somme d'argent) grâce à leur utilisation de {appName}.

      </li>
      <br/>
    <li>
      En cas d’impossibilité d’accéder temporairement à la Plateforme pour des opérations de maintenance technique ou d’actualisation des informations publiées. 
      Les Utilisateurs reconnaissent que la responsabilité de {appName} ne saurait être engagée en cas de dysfonctionnements ou d’interruptions des dits réseaux de 
      transmission, en particulier {appName} ne saurait être responsable d'une perte de gains subit par les {professeurs} ;
      </li>

      <br/>

      <li>
        {appName} n'est pas responsable en cas de défaut de règlement du Prix de l’Abonnement par l'Utilisateur.
        </li>
        <br/>
        <li>
          En cas d’utilisation anormale ou d’une exploitation illicite de la Plateforme, l’Utilisateur est alors seul responsable des dommages causés aux tiers et des conséquences des réclamations ou actions qui pourraient en découler.

          </li>
      </ul>
      <H2>1.8 Propriété intellectuelle</H2>
  </div>
<ul>
  
  <li>
    Vous confirmez que vous possédez tous les droits de propriété intellectuelle (par exemple, les droits d'auteur et les marques de commerce) sur votre contenu ou que vous avez obtenu tous les droits nécessaires sur votre contenu qui sont nécessaires pour nous accorder, ainsi qu'aux autres utilisateurs, des licences concernant votre contenu. 
  Cela inclut tous les droits requis pour se livrer aux actes couverts par cette section sur tout territoire dans lequel {appName} est accessible et, en particulier, aux États-Unis d'Amérique, au Royaume-Uni et dans l'Union européenne.
  
    
    </li>
    <br/>

  <li>
    Vous acceptez de nous accorder une licence sur tout votre contenu pour effectuer tout acte restreint par tout droit de propriété intellectuelle (y compris le droit d'auteur) sur ce contenu, à toute fin raisonnablement liée à la fourniture et au fonctionnement de {appName}. 
    Ces actes incluent la reproduction, la mise à disposition et la communication au public, l'affichage, l'exécution, la distribution, la traduction et la création d'adaptations ou d'œuvres dérivées de votre Contenu, et autrement la gestion de votre Contenu.

    </li>
    <br/>
    <li>
      La licence que vous nous accordez en vertu cette section est perpétuelle, non exclusive, mondiale, libre de droits, sous-licenciable, cessible et transférable par nous. 
      Cela signifie que la licence continuera même après la fin de votre accord avec nous et que vous cesserez d'utiliser {appName}, que nous n'avons pas à vous payer la licence et que nous pouvons accorder une sous-licence de votre contenu à quelqu'un d'autre ou céder ou transférer la licence à quelqu'un d'autre. 
      Cette licence nous permettra, par exemple, d'ajouter des autocollants, du texte et des filigranes à votre contenu, de mettre votre contenu à la disposition d'autres utilisateurs de {appName}, ainsi que d'utiliser votre contenu pour d'autres opérations normales de {appName}. 
      Nous ne vendrons jamais votre contenu à d'autres plateformes, bien que nous puissions vendre ou transférer toute licence que vous nous accordez dans les conditions d'utilisation en cas de vente de notre société ou de ses actifs à un tiers.
      </li>
  
      <br/>
<li>
  
  Bien que nous ne soyons pas propriétaires de votre contenu, vous nous accordez le droit limité de soumettre des notifications de violation (y compris du droit d'auteur ou de la marque) en votre nom à tout site Web ou service tiers qui héberge ou traite autrement des copies contrefaites de votre contenu sans votre permission. 
  Bien que nous ne soyons aucunement obligés de le faire, nous pouvons à tout moment soumettre ou retirer une telle notification à tout site Web ou service tiers lorsque nous le jugeons approprié. 
  Cependant, nous ne sommes pas et ne sommes pas tenus de contrôler les violations de votre contenu. Vous acceptez que si nous le demandons, vous nous fournirez tous les consentements et autres informations dont nous avons raisonnablement besoin pour soumettre des notifications d'infraction en votre nom. 
  
  </li>
  <br/>
  <li>
    
    Vous renoncez à tout droit moral que vous pourriez avoir en vertu de toute loi applicable de vous opposer au traitement dérogatoire de tout contenu que vous publiez sur {appName}. 
    Cette renonciation n'affecte en aucune manière votre propriété des droits de propriété intellectuelle sur votre contenu ou les droits dont vous disposez pour empêcher la copie de votre contenu sans votre autorisation. 
    La renonciation est destinée à nous permettre, lors de la gestion de votre contenu, d'ajouter des filigranes, des autocollants ou du texte à votre contenu.
    </li>
  </ul>


<H2>1.9 Lien vers {appName}
  </H2>
<ul>
  <li>
    Vous pouvez créer un lien vers la page d'accueil de {appName}, à condition que vous le fassiez d'une manière juste et légale et que cela ne nuise 
    pas à notre réputation ou n'en profite pas, 
    mais vous ne devez pas établir un lien de manière à suggérer une quelconque forme d'association ou approbation de notre part.
    
    </li>
    <br/>
  <li>
    Si vous êtes un Professeur, lors de la promotion de votre compte de Professeur, vous devez vous conformer à nos Conditions d'Utilisation et aux conditions 
    de service de tout autre site Web sur lequel vous placez un lien vers ou faites la promotion de votre compte de Professeur.
     Lors de la promotion de votre compte Professeur, vous ne devez pas vous faire passer pour {appName} ou donner l'impression que votre compte Professeur 
     est promu par nous si ce n'est pas le cas. 
     Vous ne devez pas promouvoir votre compte {appName} en utilisant Google Ads ou toute plate-forme publicitaire similaire ou service de publicité sur 
     moteur de recherche.
    </li>
  </ul>



  <H2>1.10 Lien à parti de {appName}
  </H2>
<ul>
  
  <li>
    
   Si {appName} contient des liens vers d'autres sites et ressources fournis par des tiers, ces liens sont fournis uniquement pour votre commodité. 
    Ces liens ne doivent pas être interprétés comme une approbation de notre part de ces sites Web liés ou des informations que vous pouvez obtenir d'eux. 
    Nous n'avons aucun contrôle sur le contenu de ces sites ou ressources et n'acceptons aucune responsabilité pour eux ou pour toute perte ou dommage pouvant 
    résulter de votre utilisation de ceux-ci. 
    Si vous décidez d'accéder à l'un des sites Web tiers liés à {appName}, vous le faites entièrement à vos propres risques et sous réserve des conditions 
    d'utilisation de ces sites Web.
    
    </li>
  
  
  </ul>

  <H2>
   1.11 Suppression de compte</H2>

<ul>
  <li>
  
      Si vous souhaitez supprimer votre compte {appName}, vous pouvez le faire dans la section "Paramètres" de votre compte {appName}.

   
    </li>
    <br/>


  <li>
    Si vous êtes un {eleve}, la suppression de votre compte interviendra dans un délai raisonnable suivant votre demande.
    </li>
    <br/>
  <li>
    Si vous êtes un Professeur, une fois que vous avez lancé le processus de "suppression de compte", votre compte restera ouvert jusqu'au dernier jour de la 
    période d'Abonnement payant de vos Elèves, après quoi vous recevrez votre paiement final et votre compte sera supprimé.
    </li>
    <br/>
<li>
  Une fois votre compte supprimé, vous ne serez plus facturé ni n'aurez accès à votre ancien compte {appName} ou à son contenu, et tous les Abonnements seront supprimés et ne pourront plus être renouvelés par la suite. 
  Vous recevrez un e-mail de confirmation une fois la suppression de votre compte réussie. 
  Une fois votre compte supprimé, nous pouvons traiter votre contenu de toute manière appropriée conformément à notre Politique de Confidentialité (y compris en le supprimant) et vous n'aurez plus le droit d'accéder à votre contenu. 
  Il n'y a aucune installation technique sur {appName} pour que vous puissiez accéder à votre contenu après la résiliation de votre compte.
  
  </li>



  </ul>

<H2>

1.12 Responsabilité en regard de toute perte ou dommage que vous pourriez subir.
  </H2>


  <ul>
    <li>
      Que vous ayez le statut de professionnel ou de consommateur, nous n'excluons ni ne limitons en aucune façon notre responsabilité envers vous lorsqu'il 
      serait illégal de le faire. 
      Cela inclut (i) la responsabilité en cas de décès ou de blessures causées par notre négligence ou la négligence de nos employés, agents ou sous-traitants, 
      et (ii) la fraude ou de fausses déclaration frauduleuse.
      </li>

<br/>
      <li>
        Utilisateur avec le statut de consommateur, vous acceptez que :

        </li>
        <ul>
        <li>
          Nous et nos filiales, employés, propriétaires, représentants et agents ne seront pas responsables envers vous de toute perte de profit, 
          perte d'activité ou de revenus, interruption d'activité, perte d'opportunité commerciale ou perte d'économies anticipées subies par vous 
          résultant de ou en relation avec votre utilisation de {appName}.
          </li>

<br/>
          <li>
            Si vous êtes un utilisateur consommateur et que vous résidez aux États-Unis d'Amérique, notre responsabilité totale envers vous pour les 
            réclamations découlant de ou liées à votre accord avec nous sera limitée à 250 USD par réclamation.
            </li>
          </ul>
    </ul>



<ul>
  <li>
    Si vous êtes un Utilisateur avec le statut de professionnel, vous acceptez que :

    </li>
    
    <ul>
     
  <li>
    Nous et nos filiales, employés, propriétaires, représentants et agents :
    <ul>
      <li>
          Excluent (dans la mesure permise par la loi) toutes les conditions, garanties, représentations ou autres conditions implicites pouvant s'appliquer 
          à {appName} ou à tout contenu qui s'y trouve. 
          Cela signifie que si les Conditions d'Utilisation n'incluent pas expressément une promesse ou un engagement de notre part, cela ne peut être 
          implicite par la loi ;
          </li>
          <br/>

        <li>
          Ne sont pas responsables envers vous de toute perte ou dommage subi par vous qui n'est pas un résultat prévisible du fait du respect 
          des Conditions d'Utilisation ou de notre manquement à faire preuve de diligence et de compétence raisonnables. 
          Une perte ou un dommage est prévisible s'il est évident qu'il se produira ou si, au moment où vous avez accepté les Conditions d'Utilisation, 
          vous et nous savions que cela pourrait arriver;
          
          </li>
          <br/>
<li>
  Ne sera pas responsable envers vous de toute perte ou dommage, qu'il soit contractuel, délictuel (y compris la négligence), manquement à une obligation légale ou autre, même prévisible, découlant de ou en relation avec :
<ul>
  <li>
    
    votre incapacité à utiliser {appName} ou l'un de ses services, fonctionnalités ou programmes ; ou
        </li>
      <li>
        votre utilisation ou votre confiance dans tout contenu (y compris le contenu) stocké sur {appName} ;
        </li>
  </ul>
  </li>

      </ul>
    </li>
    <br/>
    <li>
    {appName} ne sera pas responsable envers vous pour :
    <ul>
      <li>
  un manque à gagner ;
des pertes de ventes, d'activité ou de revenus ;
des interruption de travail;
des pertes d'économies anticipées ;
des pertes d'opportunités commerciales, de clientèle ou de réputation ;
des pertes de données ou d'informations, y compris tout contenu ; ou des
pertes ou dommages indirects ou consécutifs ;
  </li>

<br/>
  <li>
     de toute perte ou dommage causé par une attaque par déni de service distribué, un virus, un logiciel malveillant, un logiciel de rançon ou tout autre 
     matériel technologiquement nuisible qui pourrait infecter votre équipement informatique, vos programmes informatiques, vos données ou tout autre matériel propriétaire en raison de votre utilisation de {appName} ou de l'un de ses services, fonctionnalités ou programmes, ou en raison de votre téléchargement de tout matériel publié sur celui-ci ou sur tout site Web lié à celui-ci ;

    
    </li>

    <br/>
    <li>
      si votre contenu est copié, distribué, republié ailleurs ou si son droit d'auteur est violé par un autre utilisateur ou un tiers ;
      
      </li>
      <br/>
      <li>
        pour toute divulgation de votre identité, ou de toute divulgation ou publication de vos informations personnelles par d'autres Utilisateurs 
        ou des tiers sans votre consentement (également connu sous le nom de "doxing") ;
        
        </li>

        <br/>

        <li>
en cas de manquement ou de retard de notre part dans le respect de toute partie des Conditions d'Utilisation résultant d'un 
          événement hors de notre contrôle. Si cela devait arriver nous vous contacterons dès que possible pour vous en informer et nous prendrons des mesures pour minimiser 
          l'effet de ce délai.
          </li>


      </ul>
    </li>
    <br/>
    <li>
      Notre responsabilité totale envers vous pour toute réclamation découlant de ou liée à votre accord avec nous, que ce soit dans le cadre d'un contrat, d'un délit (y compris la négligence), d'un manquement à une obligation légale ou autre, sera limitée au plus élevé de :
      <ul>

  <li>

    100 % du total des frais que vous nous avez payés dans le cadre de votre utilisation de {appName} ; et
    </li>
    <li>
      1000€
      </li>
</ul>
      </li>
    </ul>
  </ul>


<H2>1.13 Aspects généraux</H2>
<div>
  En acceptant ces conditions générale d'utilisation vous convenez que :
  </div>
<ul>
  <li>
    Si un aspect de votre accord avec nous est inapplicable, le reste restera en vigueur.
Si nous ne parvenons pas à faire respecter un aspect de votre accord avec nous, il ne s'agira pas d'une renonciation ;
Nous nous réservons tous les droits qui ne vous sont pas expressément accordés.
Aucune licence implicite ou autre droit ne vous est accordé en relation avec toute partie de {appName}, à l'exception de ce qui est expressément 
indiqué dans les Conditions d'Utilisation.
    </li>
    <br/>
  <li>
    
Vous ne pouvez pas transférer vos droits ou obligations en vertu de votre accord avec nous sans notre consentement écrit préalable.
Nos droits et obligations en vertu de votre accord avec nous peuvent être cédés ou transférés par nous à des tiers. Par exemple, 
cela pourrait se produire si notre propriété change (comme dans le cas d'une fusion, d'une acquisition ou d'une vente d'actifs) ou par la loi. 
En outre, nous pouvons choisir de déléguer l'exécution de l'une de nos obligations en vertu de votre accord avec nous à un tiers, mais nous resterons 
responsables envers vous de l'exécution de ces obligations.
Les Conditions d'Utilisation constituent l'intégralité de l'accord entre nous et vous concernant votre accès et votre utilisation de {appName}, 
et remplacent tous les accords ou accords oraux ou écrits antérieurs entre nous et vous.
    
    </li>
  </ul>
    <H2>
1.14 Conditions relatives aux litiges - les lois s'appliquant aux litiges et réclamations concernant votre utilisation de {appName} (y compris ceux découlant de ou liés à vos accords avec nous)
      </H2>
<H4>
  Pour les élèves et utilisateurs non professionnels
  </H4>
<ul>
  <li>
    
    Si vous êtes un consommateur, votre accord avec nous est régi par le droit français et le droit français s'appliquera à (i) toute réclamation que vous avez découlant de ou en relation avec votre accord avec nous ou votre utilisation de {appName}, 
    et (ii) toute réclamation que nous avons contre vous qui découle de ou en relation avec votre accord avec nous ou votre utilisation de {appName} (y compris, dans les deux cas, les litiges ou réclamations non contractuels). 
    Vous pourrez également vous prévaloir des règles impératives de la loi du pays où vous résidez.

    </li>
  
  </ul>



<ul>
  <li>
    
    Si vous êtes un consommateur résidant en France ou dans l'Union européenne, toute réclamation que vous avez ou que nous avons découlant de ou en relation avec votre accord avec nous ou votre utilisation de {appName} (y compris, dans les deux cas, les réclamations non contractuelles litiges ou réclamations)
     peuvent être portés devant les tribunaux de France ou les tribunaux du pays où vous vivez.

    </li>
    <br/>
  <li>
    Si vous êtes un consommateur résidant en dehors de France ou de l'Union européenne, toute réclamation que vous avez ou que nous avons découlant de ou en relation avec votre accord avec nous ou votre utilisation de {appName} (y compris, dans les deux cas, non- litiges contractuels ou réclamations) 
    doivent être portées devant les tribunaux de France.
    </li>
  </ul>

<H4>

  Pour les Utilisateurs professionnels 
    </H4>


<ul>
  <li>
    Si vous êtes un utilisateur professionnel, votre accord avec nous est régi par le droit français et le droit français s'appliquera à 
    (i) toute réclamation que vous avez découlant de ou en relation avec votre accord avec nous ou votre utilisation de {appName}, 
    et (ii ) toute réclamation que nous avons découlant de ou en relation avec votre accord avec nous ou votre utilisation de {appName} (y compris, dans les deux cas, les litiges ou réclamations non contractuels),
     sans égard aux dispositions relatives aux conflits de lois.

    </li>
  </ul>

<ul>
  <li>
    Si vous êtes un utilisateur professionnel, vous et nous convenons que les tribunaux de France auront compétence exclusive pour résoudre tout litige ou réclamation (y compris les litiges ou réclamations non contractuels) 
    que vous avez ou que nous avons découlant de ou en relation avec avec votre accord avec nous (y compris son objet ou sa formation) ou votre utilisation de {appName}.

    
    </li>
  
  
  </ul>

  <H2>
    1.15 Les Frais de service sur {appName} 
 
    
    </H2>
<ul>
<li>
  Sur chaque transaction entre un {eleve} et un {professeur}, la plateforme {appName} retient des frais de service d'une hauteur de cinq pourcent (5 %) du montant toutes taxes comprises (TTC) plus les frais de traitement des paiements ;
</li>
<br/>
<li>
  Sur chaque don réalisé par un {eleve}, la plateforme {appName} retient des frais de service d'une hauteur de cinq pourcent (5 %) du montant toutes taxes comprises (TTC) plus les frais de traitement des paiements ;

</li>
<br/>
<li>
  Les quatre-vingt-quize pourcent (95 %) restants du Paiement d'{eleve} (à l'exclusion de tout élément de TVA du paiement des élèves et des frais de traitement des paiements) sont versés au Professeur participant à la transaction ;
</li>
<br/>
<li>
  Nos frais de service comprennent les coûts de fourniture, de maintenance et d'exploitation de {appName} et de stockage de votre contenu ainsi que les frais facturés par le prestataire de service Stripe ;
</li>
</ul>

<H2>
1.16 Contact
</H2>
Pour toute réclamation ou demande d'informations vous pouvez nous contacter par courrier électronique à l’adresse support@soyogis.com ou par courrier à {entreprise}, 229 rue Saint-Honoré, 75001, Paris.


<H1>
  2. Conditions d'utilisation spécifiques aux {eleves}
  
  </H1>
<H4>

EN UTILISANT NOTRE PLATEFORME EN TANT QU'ELEVE, VOUS ACCEPTEZ SES CONDITIONS D'UTULISATION, VEUILLEZ LES LIRE ATTENTIVEMENT
  </H4>





<ul>
  <li>
    Ces Conditions Générales d'Utilisation pour les {eleves} sont des conditions supplémentaires qui s'appliquent si vous utilisez {appName} avec un compte {eleve}. 
  Ces Conditions Générales d'Utilisation pour les élèves font partie de votre accord avec nous.

    </li>
    <br/>
  <li>
    Dans les présentes Conditions Générales d'Utilisation pour les {eleves}, les termes définis ont la même signification que celle qui leur est donnée dans les 
    Conditions Générales d'Utilisation pour tous les Utilisateurs. 

    </li>
<br/>

      <li>
        Vous êtes d'accord avec les Conditions Générales d'Utilisation pour tous les Utilisateurs, la politique de confidentialité, le contrat type entre Eleves et Professeurs, la politique d'utilisation des cookies et la chartre de bonne conduite sur {appName}.</li>





  </ul>
<H2>
2.1 Vos engagements envers nous 
  
  </H2>
  Vous garantissez (ce qui signifie que vous faites une promesse juridiquement exécutoire) que :
<ul>
  <li>
    Vous avez au moins 18 ans ;
    </li>
    <br/>
<li>
  Vous fournirez les informations complémentaires ou enregistrements de vérification dont nous avons besoin ;
  </li>
  <br/>
  <li>
    Vous êtes autorisé par les lois du pays ou de l'État/province où vous vous trouvez à rejoindre {appName} et à afficher tout contenu disponible sur {appName} et à utiliser toute fonctionnalité fournie par {appName} ; et
    </li>
    <br/>
    <li>
      Vous êtes en mesure et vous êtes disposé à effectuer un paiement (le cas échéant) pour afficher le contenu exclusif disponible sur {appName} que vous souhaitez afficher et utiliser toute fonctionnalité fournie par {appName} que vous souhaitez utiliser.
      </li>
  </ul>


  <H2>
    2.1 Contenu - conditions générales : Outre les conditions énoncées ailleurs dans les Conditions d'Utilisation 
    , les conditions suivantes s'appliquent au Contenu publié, affiché, téléchargé ou publié par vous en tant qu'{eleve} sur {appName} 
    </H2>


    <ul>
      <li>
        Vous garantissez (ce qui signifie que vous nous faites une promesse légalement exécutoire) que pour chaque élément de contenu que vous publiez, 
        affichez, téléchargez ou publiez sur {appName} :
        </li>
        <ul>
          
<li>
  
Le Contenu est entièrement conforme aux Conditions d'Utilisation ;
  </li>
  <li>
    Vous êtes soit propriétaire de votre Contenu (et de tous les droits de propriété intellectuelle sur celui-ci), soit vous détenez une licence valide pour votre Contenu ; et

    </li>
<li>
  Si votre contenu comprend ou utilise du matériel tiers, vous avez obtenu tous les droits, licences, consentements écrits et autorisations nécessaires 
  à l'utilisation de cette propriété tierce dans votre contenu et à l'utilisation et à l'exploitation ultérieures de ce contenu sur {appName}.
  </li>

</ul>
<br/>
  <li>
    Vous acceptez que vous serez responsable envers nous et que vous nous indemniserez si vous ne respectez pas l'une des garanties énoncées ci-dessus. 
    Cela signifie que vous serez responsable de toute perte ou dommage que nous subissons à la suite d'une garantie non respectée ou inexacte.
    </li>
<br/>

  <li>
    
Nous ne sommes pas responsables et n'approuvons aucun aspect de tout contenu publié par vous ou tout autre utilisateur de {appName}. 
Nous n'avons aucune obligation de surveiller tout Contenu et n'avons aucun contrôle direct sur ce que votre Contenu peut comprendre.
    
    </li>
    <br/>
    <li>
    
      {appName} n'a pas vocation à être une plateforme de partage de contenu pour adultes. 
      Vous vous engagez à ne pas publier de contenu pornographique ou érotique, vous vous engagez également à ne pas publier de Contenu montrant une nudité importante, des organes génitaux ou des gros plans de fessiers.

        </li>
      </ul>

<H2>
2.2 Abonnements et dons
</H2>
<ul>
            
            <li>
                L'abonnement permet à l'Elève d'accéder à du contenu exclusive proposé par le Professeur proposant l'abonnement (vidéos, photographies, articles, etc), ainsi qu'à une messagerie instantannée permettant des échanges personnaliés avec le Professeur 
proposant l'abonnement. 
                </li>
                <br/>
             <li>
                Pour souscrire à un abonnement l'utilisateur est invité à cliquer sur le bouton s'abonner visible sur le profil du Professeur. Si le Professeur propose plusieurs offres, l'élève est libre de choisir l'option 
qui lui semble être la plus avantageuse pour lui (entre les durées 1 mois, 3 mois, 6 mois et 1 an).
                </li>   
                <br/>
            <li>
                Le prix des abonnements sont fixés par les Professeurs. 

                </li> 
                <br/>

<li>
    L'Elève peut à tout moment annuler son abonnement. Il restera abonné jusqu'à la date à laquelle le prochain paiement devait avoir lieu. Passé cette date il n'aura plus accès au contenu excusif du Professeur et ne pourra plus utiliser la messagerie instantannée pour echanger avec le Professeur proposant l'abonnement.

    </li>
    <br/>
    <li>
        Si l'Elève n'annule pas son abonnement, il reconnaît que le prix de l’Abonnement est dû lors de de chaque renouvellement du dit Abonnement. 
        La date du renouvellement étant visible sur le profil du Professeur.
 
        </li>
<br/>

<li>Chaque Abonnement n’est valable que pour le seul Professeur auprès duquel l’Utilisateur a souscrit cet Abonnement.

    
    </li>
    <br/>
    <li>
        Il est précisé que l'abonnement souscrit auprès d'un Professeur ne confère aucun droit à l'utilisateur en ce qui concerne la quantité et la qualité, nécessairement subjective, des médias publiés par le Professeur.
        </li>
        <br/>
        <li>
            L’Elève s’engage à lire attentivement les informations en lien avec l'abonnement avant de souscrire au dit Abonnement sur la Plateforme. En effet le
             Professeur s'engage à décrire le plus précisemment possible sont offre (nombre de vidéos, contenu, présence en ligne pour interagir via la messagerie instantannée, etc). Une lecture attentive détaillera
             ce que l'Elève peut attendre du Professeur après s'être abonné.
            

 
            </li>
<br/>

            <li>
                Le paiement de l'abonnement se fait par carte bancaire et par l'intermédiaire de la société Stripe, l'Eleve donne son accord pour que ses données personnelles relatives au paiement lui soit transmisent.
                </li>
<br/>
                <li>
                    Une fois le paiement effectué l'Eleve recevra la confirmation de son règlement (soit directement sur {appName} soit par email).
                    </li>
<br/>

        <li>
            L’Elève peut à tout moment mettre fin à son Abonnement à durée indéterminée, pour toute raison, et notamment en cas d’absence d’activité de la part du Professeur proposant l'abonnement.
            </li>
<br/>
        <li>
            Pour résilier son abonnement l'Elève doit aller sur le profil du Professeur et cliqué sur le bouton "Abonné" puis valider son choix d'annuler son abonnement.
            
            </li>
            <br/>
            <li>
              Les Elèves peuvent utiliser la fonctionnalité "buy me a tea" disponible sur le profil des Professeurs 
  pour soutenir le Professeur associé au profil par l'inermédiaire d'un dont ;
 

            </li>
            <br/>
            <li>
              L'élève reconnait qu'un dont est réalisé sans contreparties et est fait librement. Le montant est fixé par l'Elève.

            </li>

                </ul>

        <div>
        </div>

<H2>
 2.3 Transactions 
  </H2>

  <ul>
    <li>
      Toutes les Transactions Eleve/Professeur sont des contrats entre Eleve et Professeur. 
      Bien que nous facilitons les Transactions Eleve/Professeur en fournissant la plate-forme {appName} et en stockant du Contenu,
       nous ne sommes pas partie au Contrat Standard entre Eleve et Professeur ou à tout autre contrat pouvant exister entre un Eleve et un Professeur, 
       et ne sommes pas responsables de la moindre transation Eleve/Professeur. 

      </li>
      <br/>
      <li>
        Les Professeurs sont seuls responsables de la détermination de la tarification applicable aux Transactions Eleve/Professeur 
        et du Contenu auquel vous pouvez avoir accès.
        </li>
        <br/>

      <li>
        
Les prix affichés s'entendent toutes taxes comprises  (TTC), ils incluent entre autre la TVA dont le montant est définie au taux en vigueur à la date du paiement.
        </li>
        <br/>

      <li>
        Vous nous autorisez, ainsi que nos filiales, à fournir les détails de votre carte de paiement à un fournisseur de paiement tiers dans le but de traiter votre paiement.
         Nous et nos filiales ne serons pas responsables du paiement des frais ou frais imposés par votre fournisseur de carte de paiement ou votre banque lors de vos paiement d'élève.

        </li>
        <br/>
        <li>
          Si vous choisissez de fournir les détails de deux cartes de paiement ou plus, si vous essayez d'effectuer un paiement à partir de la première carte et que la carte est rejetée pour une raison quelconque, 
          l'autre carte de paiement sera utilisée pour régler la totalité du paiement.

          </li>

<br/>
      <li>
        Le fournisseur de paiement prélèvera (i) des paiements périodiques sur votre carte de paiement pour les paiements qui sont des abonnements ; et 
        (ii) des paiements immédiats à partir de votre carte de paiement pour les paiements autres que les abonnements (y compris les paiements via la fonctionnalité "Buy me a Tea" que vous versez à un Professeur). 
        Vous autorisez et consentez à ce que chacun de ces paiements soit débité en utilisant les détails de votre carte de paiement fournis.

        </li>

  <br/>
        <li>
          À l'exception des abonnements d'essai gratuit, tous les abonnements au profil d'un Professeur seront automatiquement renouvelés à la fin de la période 
          d'abonnement correspondante, sauf si votre carte de paiement est refusée,
           si le prix de l'abonnement a augmenté ou si vous avez choisi d'annuler votre abonnement en utilisant le bouton "Abonné" situé sur le profil du Professeur concerné. 
           Cela signifie que si vous souhaitez arrêter de vous abonner au profil d'un Professeur et de payer des frais d'abonnement mensuels continus, vous devrez désactiver ce renouvellement en vous rendant sur le profil du Professeur concerné.
          </li>

<br/>
      <li>
        Si vous annulez un abonnement, vous continuerez à être autorisé à voir le contenu du professeur concerné jusqu'à la fin de la période d'abonnement au cours de laquelle vous avez annulé, 
        après quoi aucun autre paiement ne sera prélevé sur votre carte de paiement en ce qui concerne les abonnements au profil de ce professeur ( sauf si vous choisissez de payer pour un nouvel abonnement au profil de ce professeur), 
        et vous ne pourrez plus voir le contenu du professeur concerné.        </li>
        <br/>
        <li>
          Vous vous engagez à ne pas faire de demandes injustifiées de remboursement concernant une Transaction Eleve/Professeur ou une transaction faite à un Professeur via la fonctionnalité "Buy me a Tea", 
          ou des demandes de rétrofacturation injustifiées auprès de votre fournisseur de carte de paiement concernant une Transaction Elève/Professeur ou un soutient via la fonctionnalité "Buy Me a Tea" à un Professeur.
           Si nous considérons que toute demande de remboursement ou de rétrofacturation a été faite par vous de mauvaise foi, nous avons le droit de suspendre ou de supprimer votre compte Utilisateur.

          
          </li>
          <br/>
          <li>
            
            
        En cas de suspension de votre compte d'utilisateur conformément à nos droits en vertu des Conditions d'Utilisation, tous les paiements d'{eleve} qui seraient autrement arrivés à échéance pendant la période de suspension de votre compte d'utilisateur seront suspendus pendant la période de suspension de votre compte Utilisateur.

        
            </li>
      </ul>


        

        <H1>
          3. Conditions d'utilisation pour les Professeurs
          </H1>


          <H4>

EN UTILISANT NOTRE SITE WEB EN TANT QUE PROFESSEUR, VOUS ACCEPTEZ SES CONDITIONS D'UTILISATION- VEUILLEZ LES LIRE ATTENTIVEMENT
  </H4>




<ul>
  <li>
    Ces Conditions Générales d'Utilisation  pour les {professeurs} sont des conditions supplémentaires qui s'appliquent si 
    vous utilisez {appName} avec un compte {professeur}. 
  Ces Conditions Générales d'Utilisation pour les {professeurs} font partie de votre accord avec nous.

    </li>
    <br/>
  <li>
    Dans les présentes Conditions Générales d'Utilisation pour les Professeurs, les termes définis ont la même signification que celle 
    qui leur est donnée dans les Conditions Générales d'Utilisation pour tous les Utilisateurs. 
    
    </li>
  

<br/>
      <li>
        Vous êtes d'accord avec les Conditions Générales d'Utilisation pour tous les Utilisateurs, la politique de confidentialité, le contrat type entre Eleves et Professeurs, la politique d'utilisation des cookies et la chartre de bonne conduite sur {appName}.</li>



  </ul>


    <H2>

3.1 Configurer un compte Professeur 
      </H2>

<ul>
  <li>
    Pour créer un compte {professeur}, lors de votre insciption vous devez sélectionner l'option "Enseigner le yoga" lorsqu'il vous est demandé ce que vous voulez faire sur la plateforme {appName}.
    
    </li>
  <br/>
  <li>
    Si vous souhaitez pouvoir vendre du contenu, vous devrez sur votre page de compte utilisateur ajouter un compte bancaire ou les détails de paiement de votre compte bancaire ou un mode de paiement. Ces détails sont directement 
    transmis à l'entreprise Stripe qui s'occupe de l'ensemble des transations.
  
    
    </li>
    <br/>
    <li>
      Si vous êtes inscrit à la TVA en France, vous devrez nous fournir votre numéro de TVA français valide. 
      </li>

      <br/>
    <li>
      
Vous devrez peut-être soumettre des informations supplémentaires en fonction du pays dans lequel vous vivez.
      </li>

<br/>
    <li>
      
Nous pouvons vous demander des informations supplémentaires pour vérifier votre âge ou pour vérifier votre identité, et cela à tout moment. Nous pouvons rejeter votre demande de création d'un compte Professeur pour quelque raison que ce soit.
      </li>

      <br/>
  <li>
    
Une fois que vous avez renseigné vos informations bancaire permettant à Stripe de faire les transferts d'argent futurs, si vous souhaitez facturer à vos Eleves des frais d'abonnement mensuels,
 vous devez définir les tarifs de votre Abonnement (par défaut les frais d'abonnements mensuel sont nuls).
    
    </li>
<br/>

  <li>
    Une fois les étapes décrites ci-dessus validées, vous pourrez commencer à monétiser votre activité en permettant aux Utilisateurs de {appName} de s'abonner à votre compte pour devenir vos {eleves}.
    </li>
    <br/>

<li>
  Si vous perdez l'accès à votre compte, vous pouvez réinitialiser votre mot de passe, mais vous devrez connaître l'adresse e-mail utilisée pour configurer le compte. 
  Si vous ne vous souvenez pas de l'adresse e-mail utilisée pour créer le compte, nous pouvons vous demander de fournir des documents d'identification et des photos et toute preuve supplémentaire que nous pouvons raisonnablement exiger pour 
  prouver votre identité.
  </li>
  </ul>

<H2>
  3.2 Responsabilité légale personnelle des {professeurs} 
  </H2>
  <div>
    Seuls les individus peuvent être Professeur. Chaque Professeur est personnellement lié par les Conditions d'Utilisation. 
    Si vous avez un agent, une agence, une société de gestion ou un autre tiers qui vous assiste dans le fonctionnement de votre compte de Professeur
     (ou le gère en votre nom), cela n'affecte pas votre responsabilité juridique personnelle. Notre relation est avec vous, et non avec un tiers, et vous serez légalement responsable 
     de vous assurer que tout le contenu publié et toute utilisation de votre compte soient conformes 
     aux Conditions d'Utilisation.

    
    </div>


<H2>
  
 3.3 Transactions Eleve/Professeur
  </H2>

<ul>
  <li>
    Toutes les Transactions {eleve}/Professeur sont des contrats entre {eleve} et Professeur. 
    Bien que nous facilitons les Transactions Eleve/Professeur en fournissant la plate-forme {appName} et en stockant du Contenu, 
    nous ne sommes pas partie au Contrat entre Eleve et Professeur ou à tout autre contrat pouvant exister entre un Eleve et un Professeur, 
    et ne sommes pas responsables de la moindre transaction Eleve/Professeur.

    </li>
    <br/>
  <li>
    Lorsque vous recevez la confirmation de {appName}, soit sur la page "Dashboard" de votre compte utilisateur, soit par e-mail (ou les deux), que la Transaction Eleve/Professeur a été 
    confirmée, vous devez effectuer votre part de cette Transaction Eleve/Professeur (par exemple , en permettant a l'élève de visualiser le Contenu sur votre compte Professeur 
    et/ou en fournissant le Contenu personnalisé payé 
    par l'élève et/ou en permettant à l'élève d'utiliser la fonction d'interaction avec le Professeur payée (le cas échéant)). 
    Vous acceptez de nous indemniser pour tout manquement de votre part à cette obligation (ce qui signifie que vous serez responsable de toute perte ou de tout dommage 
    (y compris la perte de profit) que nous subirons en raison de 
    votre non-respect de cette obligation).

    </li>
  </ul>


  <H2>
   3.3 Contenu – conditions générales : En plus des conditions énoncées ailleurs dans les Conditions d'Utilisation, les conditions suivantes s'appliquent au Contenu publié, 
    affiché, téléchargé ou publié par 
    vous en tant que Professeur sur {appName} 

    </H2>
<ul>
  <li>
    
En dehors des Médias téléchargés dans votre Galerie, Votre Contenu n'est pas confidentiel, et vous autorisez vos {eleves} à accéder et à visualiser votre Contenu sur {appName} pour leur propre usage légal 
et personnel, et conformément aux licences que vous accordez à vos {eleves}.
    </li>
  
  </ul>




<ul>
  <li>
    Vous garantissez (ce qui signifie que vous nous faites une promesse légalement exécutoire) que pour chaque élément de contenu que vous publiez, affichez, téléchargez ou publiez sur {appName} :

    </li>
    <ul>
      
      
  <li>
    Le Contenu est entièrement conforme aux Conditions d'Utilisation ;
    </li>
    
    <li>
      Vous détenez tous les droits nécessaires pour concéder sous licence et vendre votre contenu sur {appName}, y compris dans chaque territoire où vous avez des {eleves} et en particulier en France ;
      </li>
 <li>
  
Soit vous êtes propriétaire de votre Contenu (et de tous les droits de propriété intellectuelle sur celui-ci), soit vous disposez d'une licence valide pour proposer et fournir votre Contenu à vos Elèves ;
  </li>   
    
    <li>
      Si votre contenu comprend ou utilise du matériel tiers, vous avez obtenu tous les droits, licences, consentements écrits et autorisations nécessaires à l'utilisation de cette propriété tierce dans votre contenu et à l'utilisation et à l'exploitation ultérieures de ce contenu sur {appName} ; et

      
      </li>
      <li>
      le contenu est :
      <ul>
        <li>
          De qualité satisfaisante, compte tenu de toute description du Contenu, du prix et de toutes les autres circonstances pertinentes,
 y compris toute déclaration ou représentation que vous faites sur la nature du Contenu sur votre compte ou dans toute publicité ;
          </li>
          <li>
            Raisonnablement adapté à toute fins que l'{eleve} vous a fait connaître, et la fin pour laquelle l'{eleve} utilise le Contenu ; et
tel que décrit par vous.
            </li>
        </ul>



      
      
      </li>
    </ul>


<br/>
      <li>
        
        
Vous acceptez que vous serez responsable envers nous et que vous nous indemniserez si l'une des garanties décrites ci-dessus est fausse. 
Cela signifie que vous serez responsable de toute perte ou dommage (y compris la perte de profit) que nous subissons en raison de l'inexactitude de l'une des garanties.

        </li>

<br/>
        <li>
    
    {appName} n'a pas vocation à être une plateforme de partage de contenu pour adultes. 
    Vous vous engagez à ne pas publier de contenu pornographique ou érotique, vous vous engagez également à ne pas publier de Contenu 
    montrant une nudité importante, des organes génitaux ou des gros plans de fessiers.

      </li>
      <br/>
      <li>
        Nous ne sommes pas responsables et n'approuvons aucun aspect de tout contenu publié par vous ou tout autre utilisateur de {appName}. 
  Nous n'avons aucune obligation de surveiller tout Contenu et n'avons aucun contrôle direct sur ce que votre Contenu peut comprendre.  
  Vous acceptez également d'agir en tant que dépositaire des enregistrements pour le contenu que vous téléchargez sur {appName}.

        </li>
    </ul>





    <H2>
      3.4 Publicité sur {appName}
      </H2>
      <div>
        Si vous publiez ou mettez en ligne sur votre compte de {professeur} du contenu vidéo conçu pour promouvoir, directement ou indirectement, des biens, des services ou une 
        image de tiers en échange d'un paiement,
           d'une autre contrepartie de valeur ou à des fins d'autopromotion (y compris la publicité, le parrainage, et placement de produit) (ensemble « Contenu publicitaire »), 
           vous devez alors vous conformer aux exigences énoncées 
           dans cette section.
        </div>


<ul>
  <li>
    Vous devez vous assurer que tout Contenu publicitaire que vous publiez sur votre compte Professeur :
<ul>
  <li>
    Ne porte pas atteinte au respect de la dignité humaine;
n'inclut pas ou ne  promouvoit pas une discrimination fondée sur le sexe, l'origine raciale ou ethnique, la nationalité, la religion ou les convictions, un handicap, l'âge ou l'orientation sexuelle ;
n'encourage pas des comportements préjudiciables à la santé ou à la sécurité ;
n'encourage pas des comportements manifestement préjudiciables à la protection de l'environnement ;
ne cause pas un préjudice physique, mental ou moral à toute personne ;
n'incite pas directement des personnes à acheter ou à louer des biens ou des services d'une manière qui exploite leur inexpérience ou leur crédulité ;
n'encourage pas directement des personnes à en persuader d'autres d'acheter ou de louer des biens ou des services ;
n'exploite pas la confiance des personnes envers les autres ; 
ne montre pas déraisonnablement des personnes dans des situations dangereuses ;
    
    </li>
    <li>
            Ne fait pas de publicité pour les cigarettes et autres produits du tabac, 
            les cigarettes électroniques et les flacons de recharge de cigarettes électroniques, 
            les drogues illégales ou tout médicament délivré uniquement sur ordonnance ;

            </li>
            <li>
              Ne fait pas de publicité de boissons alcoolisées;
              </li>
              <li>
  Ne fait pas de publicité, ne promeut pas ou ne facilite pas les jeux d'argent illégaux.
  </li>
  </ul>
    </li>
    <br/>
    <li>
  Vous devez déclarer que tout contenu publicitaire que vous publiez ou téléchargez sur {appName} contient de la publicité en incluant le signifiant #ad dans la légende du contenu publicitaire avant de publier ou de télécharger.

  </li>
  </ul>





  


<H2>
  3.5 Contenu co-écrit
  
  </H2>

<ul>
  <li>
    Si vous téléchargez du contenu sur votre compte de {professeur} qui montre quelqu'un d'autre que vous ou en plus de vous 
    (même si cette personne ne peut pas être identifiée à partir du contenu) ("contenu co-écrit"), vous garantissez (ce qui signifie que vous faites une déclaration juridiquement 
    exécutoire) 
    que chaque individu figurant dans tout contenu co-écrit téléchargé sur votre compte est i) un Professeur sur {appName} ; ou 
    ii) un adulte consentant, et que vous avez vérifié l'identité et l'âge de chacun de ces individus et que vous fournirez les pièces justificatives que nous pouvons demander à notre discrétion.
    
    </li>
    <br/>
    <li>
      Vous garantissez en outre que vous avez obtenu et conservez le consentement écrit de chaque personne présente dans votre contenu co-écrit et que chaque personne :
      <ul>
        <li>
          a donné son consentement exprès, préalable et pleinement éclairé à son apparition dans le Contenu co-écrit ; et
          </li>
        <li>
          a consenti à ce que le contenu co-écrit dans lequel il apparaît soit publié sur {appName}.
          </li>
        </ul>
      
      
      </li>
      <br/>
      <li>
        En plus des confirmations ci-dessus, vous acceptez que si vous téléchargez du contenu co-écrit où l'autre personne ou les personnes apparaissant dans le contenu maintiennent un compte professeur sur {appName}, 
        vous marquerez le compte {appName} de toute personne ou personnes apparaissant dans le Contenu co-écrit qui peut être identifiée à partir de celui-ci.

        
        </li>
<br/>

        <li>
          Si un contenu co-écrit est une œuvre de coauteur, vous êtes seul responsable de l'obtention des licences ou des consentements requis de tout autre coauteur du contenu qui sont suffisants pour 
          permettre à ce contenu d'être téléchargé et mis à disposition sur {appName}.

          
          </li>
<br/>
          <li>
            Vous acceptez que nous nous chargeons uniquement de verser les Gains du Professeur sur le compte du Professeur sur lequel le Contenu co-écrit est téléchargé. 
            Le Professeur qui a mis en ligne le Contenu co-écrit est seul responsable de la division et de la distribution des revenus générés par le Contenu co-écrit entre les personnes figurant dans ce Contenu co-écrit. 
            Un tel accord de partage des revenus sera un accord indépendant et privé entre vous et cette ou ces personnes, et nous ne sommes pas responsables de fournir ou d'appliquer de tels accords. 
            Vous comprenez et acceptez que vous n'avez droit à aucun Revenu de Professeur gagné sur tout Contenu co-écrit dans lequel vous apparaissez mais qui est publié sur le compte d'un autre Professeur. 
            Si vous publiez du contenu co-écrit sur votre compte, nous pouvons vous demander de fournir des informations juridiques valides et complètes pour toutes les personnes qui apparaissent dans le contenu co-écrit. 
            Si vous ne fournissez pas les informations demandées par nous à notre demande, nous pouvons supprimer le contenu co-écrit, restreindre vos droits et autorisations de publication en tant que professeur, 
            résilier votre compte et/ou retenir tout ou partie des gains de professeur gagnés, mais pas encore payé pour vous.

            
            </li>
            <br/>
        <li>
          Vous acceptez de ne faire aucune réclamation contre nous découlant du contenu co-écrit. Vous acceptez que toutes les réclamations découlant du contenu co-écrit soient faites contre le ou les professeurs qui ont publié le contenu co-écrit 
          ou la ou les personnes qui sont apparues dans le contenu co-écrit (le cas échéant).
          </li>
  </ul>
<H2>
  3.6 Paiements aux Professeurs
  </H2>


  <ul>
    <li>
      Tous les paiements d'{eleves} seront reçus par un fournisseur de paiement tiers approuvé par nous.
      
      </li>
<br/>
      <li>
        Si l'option de paiement Stripe est en vigueur, Stripe collectera le paiement de l'{eleve} et versera vos gains de Professeur sur votre compte bancaire.

        </li>
        <br/>
    <li>
      Votre compte {appName} sera mis à jour dans un délai raisonnable avec vos gains de Professeur. 
      
      </li>
      <br/>
      <li>
        Si l'option de paiement "Stripe" est sélectionnée, les chiffres des paiements des {eleves} et des revenus des {professeurs} seront reflétés dans votre devise locale,
         à un taux de change contrôlé par Stripe. Votre banque peut vous facturer des frais de conversion ou de transfert de devises pour recevoir l'argent. 
          Nous n'avons aucun contrôle sur les taux de change ou les frais imposés par votre banque, 
         et nous et nos filiales ne serons pas responsables du paiement et des frais imposés par votre banque.

        </li>
<br/>
      <li>

        Si un  {eleve} réussit à obtenir un remboursement ou une rétrofacturation auprès de son fournisseur de carte de crédit pour un paiement d'{eleve} qui vous a été versé,
         nous pouvons enquêter et décider de déduire de votre compte un montant égal aux revenus de Professeur que vous avez gagnés sur la rétrofacturation ou montant remboursé.        </li>

<br/>
        <li>
Nous ne stockons aucune donnée bancaire que vous divulguez auprès de nos fournisseurs de paiement tiers  mais nous pouvons accéder à certaines informations comme les 4 derniers numéros de votre carte de crédit.
          </li>
    </ul>

<H2>
  3.7 Circonstances dans lesquelles nous pouvons retenir les revenus des professeurs 
  
  </H2>

<ul>
  
  <li>
    Nous pouvons retenir tout ou partie des Revenus de Professeur qui vous sont dus mais non encore versés :
    <ul>
      <li>

        si nous pensons que vous avez ou pourriez avoir gravement ou à plusieurs reprises enfreint une partie des Conditions d'Utilisation ;
        </li>
        <li>
          si vous tentez ou menacez d'enfreindre une partie des Conditions d'Utilisation d'une manière qui, selon nous, 
          a ou pourrait avoir des conséquences graves pour nous ou un autre utilisateur (y compris une perte réelle ou possible causée à nous ou à un autre utilisateur) ; ou

          </li>
      
      <li>
        si nous soupçonnons que tout ou partie des Gains du Professeur résultent d'une activité illégale ou frauduleuse, soit de votre part, 
        soit de la part de l'élève qui a effectué le Paiement donnant lieu aux Gains du Professeur,
        
        </li>
      </ul>
    

    </li>
    <br/>
    <li>
      Nous pouvons retenir tout ou partie des Revenus de Professeur qui vous sont dus mais non encore versés aussi longtemps que nécessaire pour enquêter sur la violation réelle, menacée ou suspectée de votre part ou sur l'activité illégale suspectée (le cas échéant). Si, à la suite de notre enquête, nous concluons que 
      (i) vous avez gravement ou à plusieurs reprises enfreint une partie des Conditions d'utilisation ; 
      (ii) vous avez tenté ou menacé d'enfreindre une partie des Conditions d'utilisation d'une manière qui a ou pourrait avoir des conséquences graves pour nous ou un autre utilisateur (y compris une perte réelle ou possible causée à nous ou à un autre utilisateur), 
      et/ou ( iii) les Gains de professeur résultent d'une activité illégale ou frauduleuse, nous pouvons vous informer que vous avez perdu vos Gains de Professeur.

    
      </li>
      <br/>
  <li>
    Nous pouvons également retenir tout ou partie des Gains de Professeur qui vous sont dus mais non encore payés si nous recevons un avis indiquant que vous avez sécurisé, 
    grevé, mis en gage, cédé ou autrement permis qu'un privilège soit placé sur les Gains de Professeur. Nous n'assumons aucune obligation de payer les revenus du Professeur aux titulaires 
    de privilège tiers et pouvons retenir le paiement des revenus du Professeur jusqu'à ce que le privilège ait été supprimé.

    
    </li>
    <br/>
  <li>
    
   
Nous n'aurons aucune responsabilité envers vous si nous retenons ou perdons l'un de vos Gains de Professeur lorsque nous avons le droit de le faire en vertu des présentes Conditions d'Utilisation 
pour les professeurs. 
    </li>
<br/>
    <li>
      Si nous retenons tout ou partie des revenus de Professeur qui vous sont dus et que nous déterminons qu'une partie des revenus de Professeur que nous retenons n'est pas liée à des violations par vous des 
      Conditions d'Utilisation ou à une activité illégale ou frauduleuse présumée, nous pouvons prendre des dispositions pour que vous receviez la part des revenus du Professeur que nous déterminons comme n'étant pas 
      liée à des violations de votre part des Conditions d'Utilisation ou à une activité présumée illégale ou frauduleuse. Cependant, vous acceptez que si nous considérons que votre ou vos violations des Conditions d'Utilisation 
      nous ont causé ou peuvent nous causer une perte, nous pouvons retenir tous les Gains de Professeur qui vous sont dus mais non encore payés et nous pouvons déduire ces montants de toute perte subie par nous.

      
      </li>

<br/>
    <li>
      Si, une fois notre enquête terminée, nous déterminons que vos Gains de Professeur ne vous seront pas versés, nous ferons de notre mieux (sauf si la loi l'interdit) pour nous assurer que 
      tous les paiements d'{eleves} associés soient restitués aux Elèves concernés.
      
      </li>
  </ul>
<H2>
  3.8 Promotion de la conformité fiscale et de la TVA 
  
  </H2>

<ul>
  <li>
    Nous recommandons à tous les Professeurs de demander conseil à un professionnel pour s'assurer qu'ils respectent les règles fiscales et de TVA 
    locales, en fonction de leur situation personnelle.
    </li>
<br/>

  <li>
    En utilisant {appName} en tant que Professeur, vous garantissez (ce qui signifie que vous faites une promesse juridiquement exécutoire) que vous avez signalé et signalerez à l'avenir la 
    réception de tous les paiements qui vous sont versés dans le cadre de votre utilisation de {appName} à l'autorité fiscale compétente en votre juridiction, comme l'exige la loi.

    
    </li>
<br/>
<li>
  En utilisant {appName} en tant que Professeur, vous garantissez (ce qui signifie que vous faites une promesse juridiquement exécutoire) que vous vous conformerez à tout moment à 
  toutes les lois et réglementations relatives à la fiscalité qui s'appliquent à vous. Si, à tout moment pendant que vous avez un compte {appName}, une non-conformité fiscale se produit 
  à votre égard (y compris un défaut de votre part de déclarer des revenus ou l'imposition de toute pénalité ou intérêt lié à l'impôt) ou en cas de litige, ou si une enquête est 
  ouverte contre vous, ou si une enquête est en relation avec vous, vous acceptez de :


<ul>
  <li>

    nous informer par e-mail par écrit dans les 7 jours suivant la survenance de la non-conformité fiscale, le début du litige ou de l'enquête (selon le cas) ; et    
    </li>

    <li>
      
      nous fournir rapidement par e-mail : les détails des mesures que vous prenez pour remédier à la survenance de la non-conformité fiscale et pour éviter qu'elle ne se reproduise,
       ainsi que tout facteur atténuant que vous jugez pertinent ; et 
toute autre information relative à la survenance de la non-conformité fiscale que nous pouvons raisonnablement exiger.

      </li>
  </ul>

  </li>
  <br/>
  <li>
    Pour éviter tout doute, vous êtes responsable de vos propres affaires fiscales et nous et nos filiales 
    (i) ne sommes pas responsables de vous conseiller sur vos affaires fiscales et ne serons pas responsables des informations générales fournies 
    sur {appName} ou par support en ce qui concerne les Taxes, 
    et (ii) ne sommes pas responsable de tout non-paiement des Taxes par les Professeurs.

    </li>
    <br/>
    <li>
      
Nous nous réservons le droit de fermer votre compte {appName} si nous sommes informés ou prenons connaissance d'une non-conformité fiscale de votre part.
      </li>
      <br/>
      <li>
       Les Professeurs soumis à la TVA française sont invités à consulter les informations fournies par l'administration française : https://www.impots.gouv.fr/professionnel/je-recherche-les-regles-de-territorialite-tva-page-en-cours-de-creation .

        </li>
  </ul>


<div>
  

  </div>

<div>
  </div>
          </div>
  );
};

export default Login;