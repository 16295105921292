import styled from "styled-components";
import { mobile,tablette,computer } from "../responsive";
import { useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Container = styled.div`
  width:300px;
  margin: 3px;
  height: 220px;
  position: relative;
  background-color: white;
  ${tablette({height:"auto"})};
  ${mobile({height:"auto", marginBottom:"35px"})};
margin-top:30px;
border:1px solid lightgrey;
border-radius:5px;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  ${tablette({height:"21.3vw"})};
  ${mobile({height:"54.0vw"})};

`;


const Picture = styled.img`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
  position:relative;
  top:-40px;
  border: 3px solid #f2f9fb;
  left:-10%;
  ${tablette({width:"12vw",height:"12vw", top:"-40px"})};
  ${mobile({width:"25vw",height:"25vw", top:"-30px"})};

`;
const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${tablette({top:"10vw", height:"0"})};

`;

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
    color:white;
    margin-bottom: 20px;
    word-spacing: 8px;
`;

const Button = styled.button`
    border: solid 2px gray;
    padding: 10px;
    background-color: white;
    color:gray;
    cursor: pointer;
    font-weight: 600;
`;
const Follow = styled.button`
    border: none;
  background-color: #8798ef;
  color: white;
  border-radius: 30px;
    display:flex;
    align-items: center;
  cursor: pointer;
  width:auto;
  height: auto;
  font-size: 10px;
  margin-left:0px;
  padding:10px;
  margin-right: 30px;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px",height:"25px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700",  padding:"5px", height:"30px"

})}`
;


const UnFollow = styled.button`
    border: solid 1px #8798ef;
  background-color: white;
  color: #8798ef;
  border-radius: 30px;
    display:flex;
    align-items: center;
  cursor: pointer;
  width:auto;
  height: auto;
  font-size: 10px;
  margin-left:0px;
  padding:10px;
  margin-right: 30px;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px",height:"25px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700",  padding:"5px", height:"30px"

})}`
;
const Subscribe = styled.button`
    text-align: center;
    border: none;
  background-color: #8798ef;
  color: white;
  border-radius: 50px;

  cursor: pointer;
  width:auto;
  padding: 10px;
  font-size:10px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"15px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px", height:"40px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", height:"30px", padding:"5px"
})};`;


const MentorItem = ({ ads,item,user, socket }) => {
    const [follower, setFollower]=useState(false);

    const startSub = async()=>{
    //     if(user=="unknown"){
    //       setOpen3(true)
    //       return
    //   }
    //     if(user.freeSub){
    //       const body =  {
    //         buyer:user._id
    //       }
    //       const check=await axios.post(ads+'api/checkout/subscription/free/'+Video.creatorId, body);
    //       window.location.reload();
    //     }else{
    //       setOpen1(true)
      
    //     }
      }

    const follow = async () =>{
    //     if(user=="unknown"){
    //       setOpen3(true)
    //       return
    //   }
    //     if(user._id!="unknown"){
            const fol = (await axios.post(ads+'api/connections/follow/'+user._id,
             {
                 id:item._id
             })).data
             socket.current.emit("newFollower", {follower:user._id, following:item._id})
  
            setFollower(true);
    //         window.location.reload();
    //     }
  
    }

    const unfollow = async () =>{
        //     if(user=="unknown"){
        //       setOpen3(true)
        //       return
        //   }
        //     if(user._id!="unknown"){
            const unfol = (await axios.put(ads+'api/connections/follow/'+user._id,
            {
                id:item._id
            })).data
                setFollower(false);
        //         window.location.reload();
        //     }
      
        }

  return (
    <Container>
        <Link to={"/profile/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <Image src={ads+item.coverPicture} />
        </Link>
        <Info>
            <Title>{item.username}</Title>
        </Info> 
        <Wrapper>
        <Link to={"/profile/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <Picture src={ads+item.profilePicture} />
                </Link>
                {/* <div
      dangerouslySetInnerHTML={{__html:item.description}}
    /> */}
{follower?
<div style={{display:"flex", marginTop:"-35px"}}>
<UnFollow onClick={() => unfollow()}>
                 NE PLUS SUIVRE
        </UnFollow> 

</div>
:
    <div style={{display:"flex", marginTop:"-35px"}}>
                  <Follow onClick={() => follow()}>
                  SUIVRE
        </Follow> 
    {/* <Subscribe onClick={() => startSub()}>
    S'ABONNER

</Subscribe> */}
   </div>
}

        </Wrapper>

    </Container>
  );
};

export default MentorItem;