import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState, useEffect} from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

import PaymentS from '../components/PaymentS';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51MGtI7DCYXA5jB6u9SOvUgSKLWytCLb4mkMpQRxy8nC2pqoIbnuIP4iUfc1PMRPviKraaNyU5wt95tXA6G51yKwH00vYK0zzsK');

function App({ads,socket,user, setUser}) {
    const [options, setOptions] = useState("");
    const [validate, setValidate] = useState(false);
    const [owner, setOwner] = useState(null);

    useEffect( async() => {
      setOwner(JSON.parse(localStorage.subscriptioninfo))
        const pp=(await axios.get(ads+'api/checkout/secretk/'+user._id)).data;
        setOptions ({
            // passing the client secret obtained in step 3
            clientSecret: pp,
            // Fully customizable with appearance API.
            appearance: {theme: 'stripe'},
           
                
              
          });
          const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent'
          );
          const qq=(await axios.post(ads+'api/checkout/payments/'+user._id,{payment_id:clientSecret}
          )).data;
          if(qq.status=="succeeded"){
            socket.current.emit("newSubscriber", {subscriber:user._id, subscribing:qq.creator})

            setUser(qq.user);

          }
    },[])






  return (
      <div>
      <div style={{marginRight:"auto", marginLeft:"auto",marginTop:"200px",width:"400px", border:"solid 2px #eeee", padding:"20px", backgroundColor:"green", color:"white"}}>
{options!=""?
    <Elements stripe={stripePromise} options={options}>
      <PaymentS ads={ads} setValidate={setValidate}/>
    </Elements>
    :null}

    </div>
    <div>
            {validate?
                      <Link to={"/profile/"+owner.creatorId} style={{ color: 'inherit', textDecoration: 'inherit'}}>

    <div style={{marginRight:"auto", marginLeft:"auto",marginTop:"50px",width:"400px", textAlign:"center", cursor:"pointer", fontSize:"20px"}}>
      Retourner sur le Profil de {owner.creatorName}</div>
    </Link>:
    null
    }
    </div>
    </div>
  );
};

export default App;
