import React, { useRef } from 'react';
import { useState, useEffect} from "react";
import './App.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { mobile,tablette, computer } from "../responsive";
import DeleteAccount from "./DeleteAccount";

import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: auto;
  align-items: left;
  width: 45vw;
  margin-left: auto;
  margin-right:auto;
  background-color:white;
  flex-direction:column;
  border:1px solid lightgrey;
  margin-top:30px;
margin-bottom:40px;
${mobile({border:"none", width:"auto", padding:"0", marginTop:"0", paddingBottom:"20px"})};
${tablette({width:"70vw", padding:"0", marginTop:"0", paddingBottom:"20px"})};
${computer({width:"65vw", padding:"0", marginTop:"0", paddingBottom:"20px"})};

`;



const InputContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;


const InputContainer2 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  ${mobile({border:"none", width:"auto", padding:"2", marginTop:"10px", marginLeft:"0"})};
  ${tablette({border:"none", width:"auto", padding:"2", marginTop:"10px", marginLeft:"0"})};
  ${computer({border:"none", width:"auto", padding:"2", marginTop:"10px",justifyContent:"center"})};

`;

const InputContainer1 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;

const Input = styled.input`
  border: none;
  outline: none;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 500;
  width:100%;
`;

const Try=styled.aside `
    width: 20%;
    text-align: right;
    align-items: center;
  `;
  const Try2=styled.div `
  width: 20%;
  text-align: right;
  align-items: start;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"65%", height:"50px"})}

`;

const Button1 = styled.button`
margin-left:auto;
margin-right:auto;
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  border-radius: 30px;
  height: auto;
  padding:20px;
  z-index:2;
  display: flex;
  align-items: center;
  justify-content:center;
  transition:transform .2s ease;
:hover {
 
}
${mobile({fontSize:"14px",width:"200px", padding:"8px",  marginTop:"30px",marginBottom:"30px", height:"30px"})}
${tablette({fontSize:"14px",width:"200px", padding:"8px", marginTop:"30px",marginBottom:"30px",height:"45px"})}
${computer({fontSize:"13px",width:"200px", padding:"8px", marginTop:"30px",marginBottom:"30px",height:"45px"})}

`;

const Inputs = ({ads,user}) => {
    const navigate = useNavigate();
    const [theFirstName, setFirstName] = useState("");
    const [theLastName, setLastName] = useState("");
    const [theUsername, setUsername] = useState("");
    const [theEmail, setEmail] = useState("");
    const [theFacebook, setFacebook] = useState("");
    const [theInsta, setInsta] = useState("");
    const [theTwitter, setTwitter] = useState("");
    const [theBio, setBio] = useState("");
    const [isVisible, setVisibility] = useState("");
    const [thePersonalW, setPersonalW] = useState("");
    const [open, setOpen1] = useState(false);

    const editorRef = useRef(null);

    const [isNew, setNew] = useState(true);

    const  SaveModif= async (e) =>{
        e.preventDefault();

        try{


          const myToken=await axios.put(ads+'api/users/'+user._id,
          { 
            "username":theUsername, 
            "name":theFirstName,
            "lastname":theLastName,
            "email":theEmail,
            "facebook":theFacebook,
            "instagram":theInsta,
            "description":editorRef.current.getContent(),
            "twitter":theTwitter,
            "perso":thePersonalW,
            "visibility":isVisible
         });
         navigate('/profile/'+user._id);
         window.location.reload();
        }catch(e) {
            //setError(s=>e.response.data);
        }    
      }

    useEffect(async() => {
        try { 
            if(isNew){
                setFirstName(user.name)
                setLastName(user.lastname)
                setUsername(user.username)
                setEmail(user.email)
                setFacebook(user.facebook)
                setInsta(user.instagram)
                setTwitter(user.twitter)
                setBio(user.description)
                setPersonalW(user.perso)
                setVisibility(user.visibility)

                setNew(false)
            }


        }catch(e){


        }
  
    },[])
    return (
      <>
        <div>
            
<Container>

                            
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Prénom
                        </div>
                    </Try>
                    
                    <InputContainer>
                    <Input value={theFirstName} onChange={(e) => setFirstName(e.target.value)}></Input>
                    </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Nom
                    </div>
                </Try>

                <InputContainer>
                <Input value={theLastName} onChange={(e) => setLastName(e.target.value)}></Input>
                </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                    Nom d'utilisateur
                    </div>
                </Try>

                <InputContainer>
                <Input value={theUsername} onChange={(e) => setUsername(e.target.value)}></Input>
                </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                    Email
                    </div>
                </Try>

                <InputContainer>
                <Input value={theEmail} onChange={(e) => setEmail(e.target.value)}></Input>
                </InputContainer>
                </div>


                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                Site web personnel
                </div>
                </Try>

                <InputContainer>
                <Input value={thePersonalW} onChange={(e) => setPersonalW(e.target.value)}></Input>
                </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                Instagram
                </div>
                </Try>

                <InputContainer>
                <Input value={theInsta} onChange={(e) => setInsta(e.target.value)}></Input>
                </InputContainer>
                </div>


                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                Facebook
                </div>
                </Try>

                <InputContainer>
                <Input value={theFacebook} onChange={(e) => setFacebook(e.target.value)}></Input>
                </InputContainer>
                </div>
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                Twitter
                </div>
                </Try>

                <InputContainer>
                <Input value={theTwitter} onChange={(e) => setTwitter(e.target.value)}></Input>
                </InputContainer>
                </div>

                <div style={{ alignItems:"center",marginTop:"30px"}}>
               
                    <div  style={{ justifyContent:"center",display:"flex",alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px", marginLeft:"auto", marginRight:"auto"}}>
                    Bio
                    </div>
                

                {/* <InputContainer>
                <Input value={theBio} onChange={(e) => setBio(e.target.value)}></Input>
                </InputContainer> */}
                <InputContainer2>






                <Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={theBio}

        init={{
          placeholder: "Bio",
          height: 300,
          width:710,
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link '
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />
      </InputContainer2>
                </div>
{!user.teacher?
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                    {/* Set my profile public */}
                    Profil public
                    </div>
                </Try>

                <InputContainer1>


                <label className="switch" >
                    <input type="checkbox" checked={isVisible} onClick={() => setVisibility(!isVisible)}/>
                    <span className="slider"></span>
                </label>


                </InputContainer1>
                
                </div>
                :null}
                <Button onClick={SaveModif} >ENREGISTRER </Button>


<Button1 onClick={() => setOpen1(true)}>
  SUPPRIMER MON COMPTE
</Button1>

        </Container>
        </div>
        {open && <DeleteAccount ads={ads} setOpen={setOpen1} user={user} />}
        

        </>
    )
}

export default Inputs




