import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState, useEffect} from "react";
import axios from "axios";
import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import SetupForm from '../components/SetupC';
import styled from 'styled-components'
import { mobile,tablette,computer } from "../responsive";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51MGtI7DCYXA5jB6uwiWw5Ul94nNL6x2CmuIpbtiOmX0LAYlBj5psxrp0pbBheGg1uh7Kt6ICcXBCNFoTENBiogcS00ncq9dDAN');
const stripePromise = loadStripe('pk_live_51MGtI7DCYXA5jB6u9SOvUgSKLWytCLb4mkMpQRxy8nC2pqoIbnuIP4iUfc1PMRPviKraaNyU5wt95tXA6G51yKwH00vYK0zzsK');

const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;





const DivCont= styled.div`
margin-left:auto;
margin-right:auto;
border:solid 2px #eeee;
padding:20px;
width:400px;
${mobile({width:"auto"})}

`;
const Follow1 = styled.button`
margin-top:15px;
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  border-radius: 30px;
  width:auto;
  height: auto;
  padding:10px;
  margin-right: 5vw;
  z-index:2;
  transition:transform .2s ease;

${mobile({fontSize:"13px", padding:"5px", left:"55%", top:"20px"})}
${tablette({fontSize:"13px", padding:"5px", left:"55%", top:"20px"})}
${computer({fontSize:"13px", padding:"5px", left:"410px", top:"20px"})}

`;
function App({ads,setNM,setUser, nM,socket,user,connected}) {
    const [options, setOptions] = useState("");
    const [cards, setCards] = useState(undefined);

    useEffect( async() => {
        setCards((await axios.get(ads+'api/checkout/savedcards/'+user._id)).data);
        const pp=(await axios.get(ads+'api/checkout/savecard/'+user._id)).data;
        setOptions ({
            // passing the client secret obtained in step 3
            clientSecret: pp.client_secret,
            // Fully customizable with appearance API.
            appearance: {theme: 'stripe'},
          });

    },[])


    const deleteCard = async (i) =>{

        const fol = await axios.post(ads+'api/checkout/deletecard/'+user._id,
        {
            id:i,
            card:cards[i]
        })


        window.location.reload();

    }



  return (
    <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
    <NavContainer >
    <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                {cards!=undefined&&cards.length>0?
                <h2 style={{textAlign:"center", marginTop:"30px", marginBottom:"30px"}}>MES CARTES</h2>
:null}
                {cards?.map((item,i) => (
                    
<div style={{marginBottom:"5px",marginLeft:"auto", marginRight:"auto",width:"400px", border:"solid 2px #eeee", padding:"20px"}}>
    <div>
        Carte <span style={{fontWeight:"800", marginLeft:"5px"}}>{item.brand}</span>
    </div>
    <div style={{marginTop:"10px"}}>
        Carte se terminant par
        <span style={{fontWeight:"800", marginLeft:"15px"}}>xxxx {item.last4}</span>
        
    </div>
    <div style={{position:"relative"}}>
        <Follow1 onClick={() => deleteCard(i)}>
            Supprimer
        </Follow1>
    </div>
</div>
                  )  )}


<h2 style={{textAlign:"center", marginTop:"30px", marginBottom:"30px"}}>AJOUTER UNE CARTE</h2>


      <DivCont >
{options!=""?
    <Elements stripe={stripePromise} options={options}>
      <SetupForm ads={ads}/>
    </Elements>
    :null}
    </DivCont>
    </ContainerRight>

</div>
  );
};

export default App;
