import React from 'react'
import styled from "styled-components";
import { useState,useEffect} from "react";
import Upload from './Upload';
import axios from "axios";
import Video from "./VideoMy";
import Delete from "./Delete";
import { useNavigate } from 'react-router-dom';
import VV from "./VideoPrivate1";
import NoVideo from './NoVideo';

import { Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
position: relative;
padding: 20px;
margin-top:30px;

${mobile({marginTop:"70px"})}

`;
const Wrapper = styled.div`
width: 100%;
position: relative;
display: grid;
${mobile({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "90vw",width:"90vw", padding:"0",gridGap:"2vw"})}
${tablette({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "38vw 38vw",width:"80vw", padding:"0",gridGap:"4vw"})}
${computer({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "36vw 36vw",width:"76vw", paddingTop:"0",gridGap:"4vw"})}  
   
`;

const Button = styled.button`
  width: 250px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #00000085;
    cursor: auto;
  }
  ${tablette({marginTop:"10px"})}
  ${mobile({marginTop:"10px"})}

`;
const Myvideoscourse = ({ads,user}) => {
    const navigate = useNavigate();
    const [isdisable, setDisable] = useState(true);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [id, setId] = useState("");
    const [thechecked, setcheck] = useState([]);
    const [noVideo, setNoVideo] = useState(false);

    const [videos, setVideos] = useState([]);
    const [videoSaved,setVideoSave]= useState("");

    // const selectVideo = (number) =>{
    //     videoSaved.includes(number) ? setVideoSave(videoSaved.filter(item => item !== number)) : setVideoSave((arr) =>[...arr, number]);
    // }
    function handleChange(e) {
        const value = e.target.value;
        var FF=[];
        for (let jj = 0; jj < videos.length; jj++) {
          if(jj==value){
            FF[jj]=true
          }else{
            FF[jj]=false
          }
        }
        setcheck(FF);
        setVideoSave(videos[value]._id);

      }

    useEffect(()=>{
        if(videoSaved.length>0){
            setDisable(false)
        }else{
            setDisable(true)
        }
    },[videoSaved])
    useEffect(async() => {
        try { 

           const res= (await axios.get(ads+'api/video/all/'+user._id)).data;
           if(res.length<=1){
             setNoVideo(true)
           }
           setVideos(res.reverse());
        }catch(e){

        }
    },[]);
    const saveAndContinue= () =>{
        localStorage.setItem('videoCourseId',videoSaved);
        navigate("/selectcourse1/"+videoSaved);
    }

    return (
      <>

        <div>
            <Container>
            <h2 style={{textAlign:"center", marginBottom:"20px"}}>
            Étape 1 : choisissez votre Vidéo
</h2>
            <Wrapper>

                {videos?.map((item,i) => (
            <div style={{position:"relative", border:"1px solid black", padding:"40px", marginTop:"10px", height:"auto"}}  key={item._id}>
            <div  >
            <input style={{width:"30px", height:"30px", cursor:"pointer"}}  type="checkbox" 
            
            value={i}
            onChange={(e) => handleChange(e)}
            checked={thechecked[i]} 
            />
            </div>
                    {/* <div style={{position:"relative",marginRight:"10px",cursor:"pointer"}} onClick={() => select(item._id)} key={item._id}> */}

                                                {/* <Link to={"/selectcourse1/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
                        <VV ads={ads} item={item} key={item._id} owner={user}/>

                       {/* </Link> */}
                    </div>

                        ))} 
            </Wrapper>
            <div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={saveAndContinue} disabled={isdisable}>SUIVANT</Button>

</div>
            </Container>
        </div>
                    {noVideo && <NoVideo user={user}/>}

                    </>

    )
}

export default Myvideoscourse
