import React, { useEffect, useState,  useRef  } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { mobile,tablette,computer } from "../responsive";
import { Editor } from '@tinymce/tinymce-react';
import * as tus from 'tus-js-client'

const Container = styled.div`
z-index:3;
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 600px;
  height: 90vh;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  ${mobile({padding:"0", paddingTop:"10px", height:"100vh"})}
  overflow-y: scroll;

`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  ${mobile({top:"10px", right:"10px"})}

`;
const Title = styled.h1`
  text-align: center;
  margin-top:40px;
  ${mobile({paddingBottom:"20px"})}

`;

const Input = styled.input`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  z-index: 999;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;
const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
  ${mobile({width:"50%", marginBottom:"100px", marginLeft:"auto", marginRight:"auto"})}

`;




const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;
`;
const Options=styled.div`
display:flex;
align-items: center;
margin-top:-40px;
margin-bottom:40px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;

const Containerfilters = styled.div`
  height: 1px;
  align-items: center;
  width: 400px;
  margin-left: 30px;
  margin-right:auto;
  flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 90px;
    margin-top:90px;
    ${mobile({width:"auto", marginTop:"15px", marginBottom:"155px", paddingBottom:"40px"})}

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
  ${mobile({marginLeft:"5px"})}

`;
const Option = styled.option``;



const Label = styled.label`
  font-size: 14px;
  margin-left:0px;
  ${mobile({marginLeft:"10px", width:"50px"})}

`;
const Upload = ({ user,ads,setOpen }) => {
  const [img, setImg] = useState(undefined);
  const [duration,setDuration]=useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [filters, setFilters] = useState({
                
    ["diff"]: "Beginner",
    ["intens"]: "Level 1",

  });
  const [theArray, setTheArray] = useState([]);
  const editorRef = useRef(null);
  const [error, setError] = useState("");


  const [showSuccess,setSuccess]=useState(false)
  const [progress, setProgress] = React.useState(0);
  const [progress2, setProgress2] = React.useState(0);

  const showD = useRef();

  useEffect(() => {

    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
}, []);


  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };




  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
    
  };
const validate = (number) =>{
    theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
}

  const navigate = useNavigate()

//   const handleChange = (e) => {
//     setInputs((prev) => {
//       return { ...prev, [e.target.name]: e.target.value };
//     });
//   };

//   const handleTags = (e) => {
//     setTags(e.target.value.split(","));
//   };



const loadVideo = file => new Promise((resolve, reject) => {
  try {
      let video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
          resolve(this)
      }

      video.onerror = function () {
          reject("Invalid video. Please select a video file.")
      }

      video.src = window.URL.createObjectURL(file)
  } catch (e) {
      reject(e)
  }
})



const tr = async (f)=>{
  setVideo(f)
  const videoss=await loadVideo(f)
  setDuration(videoss.duration)
}





  const handleUpload = async (e)=>{
    e.preventDefault();

    // const formData = new FormData()
    // formData.append('profile', video, video.name)
    const res = (await axios.post(ads+"api/video/bunny/"+user._id)).data




    const formDataThumb = new FormData()
    formDataThumb.append('profile', img, img.name)
    const thum = await axios.post(ads+"api/video/thumbnails/"+res._id,formDataThumb,
    {   
      onUploadProgress: progressEvent => {
        setProgress2((progressEvent.loaded / progressEvent.total)*100)
          console.log(progressEvent.loaded / progressEvent.total);
      }
    }
    )


    
        await axios.put(ads+'api/video/'+res._id,{"description":editorRef.current.getContent()});
    
    if (title != ''){
        await axios.put(ads+'api/video/'+res._id,{"title":title});
    }
    if (duration != undefined){
      await axios.put(ads+'api/video/'+res._id,{"duration":duration});
  }
    await axios.put(ads+'api/video/'+res._id,{"style":theArray, "difficulty":filters.diff,"intensity": filters.intens});
  


// Create a new tus upload
var upload = new tus.Upload(video, {
  endpoint: "https://video.bunnycdn.com/tusupload",
  retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
  headers: {
      AuthorizationSignature: res.key, // SHA256 signature (library_id + api_key + expiration_time + video_id)
      AuthorizationExpire: res.time, // Expiration time as in the signature,
      VideoId: res.videoId, // The guid of a previously created video object through the Create Video API call
      LibraryId: res.libraryId,
  },
  metadata: {
      filetype: video.type,
      title: video.name,
      collection: res.collectionId
  },
  onError: function (error) {
    if (window.confirm(`Upload failed because: ${error}\nDo you want to retry?`)) {
      upload.start()
    }else{
      setOpen(false)
    }
   },
  onProgress: function (bytesUploaded, bytesTotal) {
            setProgress((bytesUploaded / bytesTotal)*100)
  //         console.log(progressEvent.loaded / progressEvent.total);
   },
  onSuccess: function () { 
    showD.current.style.display="none"
    setSuccess(true)
  }
})

// Check if there are any previous uploads to continue.
upload.findPreviousUploads().then(function (previousUploads) {
  // Found previous uploads so we select the first one. 
  if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0])
  }

  // Start the upload
  upload.start()
})





  
  }

 
  



  return (
    <Container>
      <Wrapper >
        <Close onClick={() => setOpen(false)}>X</Close>
        <div style={{  display: "flex",flexDirection: "column", gap:"20px"}} ref={showD}>
        <Title>Télécharger une Nouvelle Vidéo</Title>
        <Label>Vidéo:</Label>

          <Input
            type="file"
            accept="video/*"
            onChange={ (e) => tr(e.target.files[0])}

          />



          <CircularProgressWithLabel value={progress} />









        
        <Input
          type="text"
          placeholder="Titre"
          name="title"
          onChange={(e) => setTitle(e.target.value)}
        />


<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />



        {/* <Desc
          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => setDescription(e.target.value)}
        /> */}
        {/* <Input
          type="text"
          placeholder="Separate the tags with commas."
          onChange={handleTags}
        /> */}
        <Label>Miniature:</Label>

          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setImg(e.target.files[0])}
          />
          <CircularProgressWithLabel value={progress2} />

<Options >

<Label>Style:</Label>


        <Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
            </FilternameS>
            : null}

            {theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
            </FilternameS>
            : null}

{theArray.includes(8)==false?
            <Filtername onClick={() => validate(8)}>
                PRANAYAMA
            </Filtername>
            : null}

            {theArray.includes(8)==true?
            <FilternameS onClick={() => validate(8)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
            </FilternameS>
            : null}

            {theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
            </FilternameS>
            : null}


{theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
            </FilternameS>
            : null}

            {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
            </FilternameS>
            : null}

{theArray.includes(7)==false?
            <Filtername onClick={() => validate(7)}>
                YIN
            </Filtername>
            : null}

            {theArray.includes(7)==true?
            <FilternameS onClick={() => validate(7)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
            </FilternameS>
            : null}
   
        </Containerfilters> 




        <Label>Difficulé:</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"45px"}}>Intensité:</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>



        </Options>


        <OptionsS >
<Label>Style:</Label>


        <Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
            </FilternameS>
            : null}

            {theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
            </FilternameS>
            : null}

{theArray.includes(8)==false?
            <Filtername onClick={() => validate(8)}>
                PRANAYAMA
            </Filtername>
            : null}

            {theArray.includes(8)==true?
            <FilternameS onClick={() => validate(8)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
            </FilternameS>
            : null}



{theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
            </FilternameS>
            : null}

            {theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
            </FilternameS>
            : null}



            {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
            </FilternameS>
            : null}

{theArray.includes(7)==false?
            <Filtername onClick={() => validate(7)}>
                YIN
            </Filtername>
            : null}

            {theArray.includes(7)==true?
            <FilternameS onClick={() => validate(7)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
            </FilternameS>
            : null}
   
        </Containerfilters> 




        <Label>Difficulté:</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label >Intensité:</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>



        </OptionsS>
        
        <Button onClick={handleUpload}>Télécharger</Button>
        </div>
 
        {showSuccess?
                <div style={{display: "flex",flexDirection: "column", gap:"20px",textAlign:"center"}}>
                Votre vidéo a été téléchargée avec succès !
                <Button onClick={() => window.location.reload()}>OK</Button>
      
              </div>:
              null
      }

      </Wrapper>
    </Container>
  );
};

export default Upload;