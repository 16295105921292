import styled from 'styled-components'
import React from 'react'
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";


const Subscribe = styled.button`
margin-left:30px;
margin-right:3vw;

display:block;
    text-align: center;
   border: none;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: #8798ef;
  /* background-color: #5f4fbb; */

  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({display:"none"})}

${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"45px"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"500", padding:"8px",width:"210px", height:"40px"})}

`;

const Subscribe1 = styled.button`

display:block;
    text-align: center;
   border: 1.8px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px",width:"auto",marginLeft:"auto", height:"auto",fontWeight:"500", backgroundColor:"#8798ef", border: "1.8px solid #8798ef", color:"white", marginRight:"3vw"})}

${tabletteLanding({fontSize:"17px",fontWeight:"500", padding:"8px",width:"190px", height:"auto", backgroundColor:"#8798ef", border: "1.8px solid #8798ef", color:"white", marginRight:"3vw"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"500", padding:"8px", width:"150px", height:"40px", backgroundColor:"#8798ef", border: "1.8px solid #8798ef", color:"white", marginRight:"3vw"})}

`;




const Container = styled.div`
  height: 70px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
`;

const Announcement = () => {
    return (
        <Container>

            <Link to={"/"} style={{ zIndex:"2",cursor:"pointer",marginLeft:"3vw",color: 'inherit', textDecoration: 'inherit'}}>

            <div style={{width:"auto"}}>
            SoYogis.
            </div>
            </Link>

            <Link to={"/signin"} style={{marginLeft: "auto", color: 'inherit', textDecoration: 'inherit'}}>

            <Subscribe1>
                Connection
            </Subscribe1>
            </Link>
            {/* <Subscribe>
                Devenez Professeur
            </Subscribe> */}
            {/* <div style={{marginLeft:"auto"}}>
                Connection
            </div> */}
        </Container>
    )
}

export default Announcement