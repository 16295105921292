import styled from "styled-components";
import { Link } from "react-router-dom";


const H1 = styled.h1`
margin-bottom:20px;
margin-top:20px;
`;

const H2 = styled.h2`
margin-bottom:15px;
margin-top:15px;
`;
const H3 = styled.h3`
margin-bottom:10px;
margin-top:10px;
`;

const H4 = styled.h4`
margin-bottom:10px;
margin-top:10px;
`;

const Login = () => {
    const date = "13/03/23";
    const professeur="Professeur";
    const eleve="Élève"
    const professeurs="Professeurs";
    const eleves="Élèves"
    const appName="SoYogis";
    const entrepriseFull = "OmTech Innovations S.A.S.U (dont le siège social est situé au 229 rue Saint-Honoré, 75001, Paris),"

    const entreprise="OmTech Innovations"
  return (
<div style={{marginLeft:"15px", marginRight:"15px"}}>

<div style={{borderBottom:"1px solid grey", marginBottom:"50px", paddingBottom:"30px", paddingTop:"10px"}}>

          <H1>
            Contrat type entre {eleves} et {professeurs}
            </H1>
          <div>
            Version du {date}
            </div>
</div>
            <div>
              Bienvenue sur {appName}, la plateforme qui permet aux professeurs de yoga de vivre de leur passion 
              et aux élèves de profiter d'un environnement propice à l'épanouissement et à la progression. En sa qualité d'opérateur de la plateforme {appName}, {entreprise} fournit le présent contrat liant les Eleves et les 
              professeur lors de toute transaction faite sur {appName}.
              </div>
              <br/>
              
              <div>
Le présent Contrat régis les termes qui régissent chaque transaction entre un {eleve} et un Professeur sur {appName}. Il s'applique chaque fois qu'une 
transaction {eleve}/Professeur est initiée sur {appName}. Ce contrat entre {eleve} et Professeur s'appliquera à l'exclusion de toute autre condition que l'{eleve} ou le Professeur 
pourrait proposer. Ce contrat liera légalement l'{eleve} et le Professeur participant à la transaction.


</div>

<H2>
 1. Parties 
</H2>
 Les seules parties à cet accord sont l'{eleve} et le Professeur participant à la Transaction {eleve}/Professeur. 
 Ni OmTech Innovations ni aucune de ses filiales n'est partie à cet accord et ni OmTech Innovations ni aucune de ses filiales n'accorde de
  droits à l'égard de, ni ne participe à, toute Transaction {eleve}/Professeur (bien que OmTech Innovations ou l'une de ses filiales puisse agir 
  en tant qu'intermédiaire de paiement).






            <H2>
              2. Définitions
              </H2>
              <div>
                Les termes mentionnés ci-dessous, ont dans le présent contrat, la signification suivante :

                </div>
                <ul>
  <li>
    « Abonnement » : désigne le droit d’accès aux Médias exclusifs et à la messagerie d'un Professeur en contrepartie de son prix ;

    </li>
    <br/>
    <li>
      « Abonné » : désigne l’Utilisateur ayant souscrit à un Abonnement auprès d’un Professeur ; 

    </li>
    <br/>
    <li>
      « Frais de service » : désigne un pourcentage du montant des transactions {eleve}/{professeur} retenu par {appName} en contrepartie de ses Services ;
   
    </li>
    <br/>
<li>
  « Contrat type entre {eleves} et {professeurs} »  : désigne les termes qui régissent chaque Transaction en un {eleve} et un {professeur};  

  </li>
  <br/>
<li>
  « Conditions Générales d’Utilisation » ou « CGU » ou « Contrat » : désigne les présentes conditions contractuelles mises à disposition sur la Plateforme, afin d’encadrer l’utilisation de celle-ci par tout Utilisateur ; 

  </li>
  <br/>



  <li>
    « Conditions d’Utilisation » : désigne l'ensemble des accords qu'un utilisateur s'engage à respecter lorsqu'il utilise {appName}. Les Conditions d'Utilisation
    regroupent : les présentes Conditions Générales d'Utilisation, le Contrat type entre {eleves} et {professeurs}, la Politique de confidentialité et de protection des données
, les politique d'utilisation des cookies.
  </li>
  <br/>
  <li>
    « Contenu » : désigne tout élément publié par un Utilisateur sur la Plateforme, que ce soit un Média publié par un {professeur}, 
    ou un message ou contenu de quelque nature que ce soit (texte, image, vidéo, son, multimédia) publié par tout Utilisateur ;

    
    </li>
    <br/>
    <li>
    « TVA » : désigne la taxe sur la valeur ajoutée en France et toute autre taxe imposée en plus ou en remplacement de celle-ci au taux imposé de temps à autre et toute taxe équivalente ou similaire gouvernementale
    , étatique, fédérale, provinciale ou municipale indirecte, charge, droit, impôt ou prélèvement dans toute autre juridiction ; et

    </li>
    <br/>
    <li>
      « Taxe » : comprend toutes les formes d'impôts et de charges, droits, impôts, contributions, prélèvements, retenues ou obligations statutaires, gouvernementaux, étatiques, fédéraux, provinciaux, locaux ou municipaux, partout où ils sont exigibles et qu'ils soient de France ou de toute autre juridiction.
      </li>
    <br/>
<li>
  « Professeur » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné sa volonté d'"enseigner le yoga" à son inscription sur {appName} ;

  </li>
  <br/>
  <li>
  «Élève » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné sa volonté d'"apprendre le yoga" à son inscription sur {appName} ou un Professeur ayant souscrit à au moins un abonnement depuis la création de son compte ;

  </li>
  <br/>
<li>
  
  « Médias » : désigne l’ensemble des photographies, vidéos, musiques, fichier audio, podcast, Lives, informations, textes, logos, marques, animations, dessins et modèles, 
  données et de façon générale tous les éléments et contenus créés par un {professeur} et publiés par ce dernier sous sa seule responsabilité sur la Plateforme selon les modalités, 
  la forme et les conditions qui lui sont proposées dans le cadre des Services ;

  </li>
  <br/>
  <li>
    « Média Public » : désigne tout Média publié par un Professeur sur son Profil et accessible à tout Utilisateur,  à titre gratuit ;  

    </li>
    <br/>
    <li>
      « Média Non-Public » : désigne tout Média communiqué sur le Profil, de manière floutée. Le Média Non-Public peut être un Média visionnable contre un Abonnement ;

      </li>
      <br/>
    <li>
      « Plateforme » : désigne la Plateforme en ligne accessible à l’adresse suivante : https://www.soyogis.com/. La Plateforme regroupe l’ensemble des pages web, application, Services et fonctionnalités proposés aux Utilisateurs ; 

      </li>
      <br/>
  <li>
    « Profil » : désigne la page de présentation des Utilisateurs. Dans le cas d'un {professeur}, sur cette page est présentée tant 
    l’activité de ce dernier que les Médias et Abonnements qu’il propose;

    
    </li>
    <br/>
  <li>
    « Utilisateur » : désigne toute personne qui accède et navigue sur la Plateforme ;

    </li>
  </ul>

<H2>3. Paiements</H2>


En concluant une Transaction {eleve}/Professeur, l'{eleve} s'engage à payer le Paiement du Professeur applicable à la Transaction {eleve}/Professeur
concernée, conformément au prix publié sur le compte du Professeur. 
L'{eleve} et le Professeur participant à la transaction autorisent OmTech Innovations ou l'une de ses filiales à agir en tant qu'intermédiaire de paiement 
et à collecter, détenir et traiter le paiement de l'{eleve} et toute TVA applicable, à déduire les frais de service et à payer les sommes dues aux Professeur.

<H3>
  3.1 Prix</H3> 

Le prix des abonnements sont visibles sur les profils des Professeurs. Les prix sont affichés toutes taxes comprises. 
Dans les taxes sont inclus :
<ul>
  <li>
    La Taxe sur la Valeur Ajoutée (TVA) ;</li>
    <li>
      Les frais bancaires, notamment les frais de transaction ;
      </li>
      <li>
        Les Frais de service relatifs au fonctionnement de {appName}.
        </li>
  </ul>
<H3>
  3.2 Moyen de paiement</H3>


  L'ensemble des transaction réalisées sur la plateforme se font par carte bancaire. Les transactions sont gérées par le prestataire de service Stripe. 
  La plateforme {appName} ne conserve pas les données relatives aux carte de paiement des {eleves}, les données sont transmises et gérées par l'entreprise Stripe.
  Stripe collectera le paiement des {eleves} et versera les gains du Professeur sur son compte bancaire.
  
  <br/>
  <br/>
L'{eleve} garanti qu'il possède toutes les autorisations pour utiliser le moyen de paiement.
<H3>
  3.3 Date de paiement
  </H3>
Pour les Abonnements le paiement initiale se fera dès la souscription puis à chaque date de renouvelement (tous les mois si abonnement mensuel, tous les trois mois si abonnement trimestriel, tous les six mois si abonnement semestriel et chaque année si abonnement annuel). 
<br/>
<br/>
Dans les cas d'un don, le paiement sera réalisé immédiatement.

<H3>
  3.4 Refus de paiement
  </H3>
  En cas de refus de paiement l'élève ne pourra consulté le contenu privé du professeur


<H3>
  3.5 Droit de rétractation
  
  </H3>

En accédant au contenu exclusif du Professeur (après avoir versé la somme nécessaire pour s'abonner), conformément aux articles L. 221-18 et suivants du code de la consommation, l'{eleve} reconnaît que le {professeur} a pleinement exécuté ses obligations, qu’il souhaite avoir immédiatement accès au contenu et qu’il renonce au bénéfice du délai de rétractation de quatorze (14) jours issu des dispositions susvisées du code de la consommation.

En effet, aucune Commande (ni l’Abonnement) ne peut en principe donner lieu à un droit de rétractation, conformément à l’article L221-28 du Code de la Consommation qui dispose que « Le droit de rétractation ne peut être exercé pour les contrats :

3° De fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés ;

13° De fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation.».


<H2>
  4. Propriété intellectuelle
  
  </H2>
  Conformément aux Conditions Générales d’Utilisation (CGU), les Médias publiés par le {professeur} sont la propriété exclusive de celui-ci, ce que l'Utilisateur accepte et reconnaît expressément. 
  <br/>
  Une fois que l'{eleve} a effectué un paiement pour accéder aux média exclusif du Professeur, le Professeur accorde a l'{eleve} une licence limitée
   pour accéder au Contenu auquel la Transaction se rapporte. Cette licence est non transférable, non sous-licenciable et non exclusive, 
   et permet a l'élève d'accéder et de visualiser le contenu pertinent sur l'appareil personnel de l'élève et via un navigateur Web normal ou l'application {appName}.
<br/>
Toute représentation ou reproduction, totale ou partielle, du Média par quel que procédé que ce soit, sans l’autorisation préalable du {professeur}, est interdite et constituera une contrefaçon sanctionnée par les dispositions du Code de la Propriété Intellectuelle, conformément aux Conditions Générales d’Utilisation.
<br/>
En particulier, l'élève participant à la Transaction reconnaît et accepte que la licence du Contenu fournie n'entraîne pas l'acquisition par l'Eleve de droits sur le Contenu du Professeur associé à la transaction, 
lesquels droits seront conservés par le Professeur.
<br/> 
<br/>
La licence accordée à un {eleve} en relation avec le Contenu concerné expirera automatiquement sans préavis dans les circonstances suivantes :
<ul>
<li>
  Si le Paiement de l'{eleve} lié à la Transaction a échoué ou est annulé pour quelque raison que ce soit ;

</li>
<li>
 A la fin de la période d'abonnement, à moins que l'Abonnement ne soit configuré pour se renouveler automatiquement ;

</li>
<li>
  Si le compte d'Utilisateur de l'{eleve} est suspendu ou résilié pour quelque raison que ce soit ;

</li>
<li>
  Si l'{eleve} agit en violation des Condition d'Utilisation (que ce soit en relation avec le contenu associé à la Transaction ou non) ;

</li>
<li>
  Si le Contenu est supprimé du compte du Professeur ;

</li>
</ul>
    
    
    <H2> 5. Engagements en matière de contenu et obligations entre {eleves} et Professeurs
    </H2>

<ul>
<li>
  L'{eleve} et le Professeur participant à la Transaction acceptent de se conformer à tout moment aux Conditions d'Utilisation de {appName} en 
  ce qui concerne le Contenu associé à la transaction, y compris lors de l'accès, de la visualisation et de l'interaction avec celui-ci.
</li>
<li>
  L'{eleve} participant à la Transaction s'engage à effectuer le Paiement requis pour accéder, visualiser ou interagir avec le Contenu concerné, 
  et s'engage à ne pas initier de rétrofacturation sauf s'il conteste la Transaction avec un motif légitime ou de bonne foi.

</li>
<li>
  Le Professeur participant à la Transaction s'engage à mettre à disposition de l'{eleve} le Contenu Pertinent une fois que celui-ci aura effectué le 
  Paiement associé au contenu.

</li>
<li>
  Le Professeur garantit (fait une promesse juridiquement exécutoire) qu'il possède tous les droits nécessaires sur le contenu associé aux transactions, 
   pour le concéder sous licence a l'{eleve} dans le territoire sur lequel l'{eleve} accédera au contenu, et a obtenu toutes les autorisations. et les consentements nécessaires pour accorder 
   la licence ;
</li>
<li>
  Le Professeur est seul responsable de la création et de la mise en ligne du Contenu pertinent
</li>
<li>
  Sauf en cas de négligence ou autre manquement au devoir du Professeur, l'accès par l'{eleve} au Contenu du Professeur se fait entièrement aux risques 
  et périls de l'{eleve}.
</li>
<li>
  Le Professeur ne donne aucune garantie quant à la fréquence de publication de Médias, leur caractère inédit ou leur nombre. L'{eleve} accepte ces conditions expressément.

</li>
</ul>
   


L'{eleve} participant à la Transaction reconnaît que le Professeur peut ajouter et supprimer du Contenu à tout moment de son compte de Professeur, 
et que les Professeurs ont le pouvoir discrétionnaire de décider du type de Contenu à rendre disponible sur leur compte. 
En outre, l'{eleve} participant à la transaction
 reconnaît qu'il peut y avoir des circonstances dans lesquelles il peut ne pas être possible pour l'{eleve} d'accéder au Contenu associé à la Transaction 
 , notamment :

 <ul>
  <li>

Si le compte du Professeur est suspendu ou supprimé ;
    </li>
    <li>
      Si le compte de l'{eleve} est suspendu ou supprimé ;
      
      </li>
    <li>
      Si la disponibilité de tout ou partie de {appName} est suspendue ou inaccessible ; 
      </li>
      <li>
        
Si le créateur est incapable de créer ou de télécharger du contenu pertinent à l'avenir.
        </li>
    </ul>








<H2>
  6. Termes relatifs aux litiges :
  </H2>
Nous conseillons aux Utilisateurs de {appName} de contacter directement {entreprise} par courrier électronique à l'adresse support@soyogis.com en cas de littige entre {eleve} et {professeur} pour 
entamer une procédure de gestion à l'amiable de leur éventuel litige. Cependant nous rappelons les faits suivants :

<ul>
<li>
  Les Conditions d'Utilisation ainsi que les relations entre les Utilisateurs de {appName} sont régis par le droit Français.

</li>
  <li>
    Si la transaction est conclue avec un {eleve} résidant en France ou dans l'Union européenne, 
    toute réclamation en vertu de cet accord peut être portée devant les tribunaux de France ou les tribunaux du pays où vit l'{eleve}.
    </li>
    <li>
      
      
Si la transaction est conclue avec un {eleve} résidant en dehors de France ou de l'Union européenne, 
toute réclamation en vertu de cet accord doit être portée devant les tribunaux de France.
      </li>
  </ul>

          </div>
  );
};

export default Login;