import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModifyPost from "./ModifyPost";
import { mobile,tablette,computer } from "../responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import axios from "axios";
import DeleteCom from '../components/DeleteCom'
import LogYou from "./LogYou";

const Container = styled.div`
  z-index:3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const Wrapper = styled.div`
  width: 75vw;
  height: 45vw;
  background-color: white;
  color: black;
  display: flex;
  position: relative;
  ${mobile({width:"100%", height:"100%", display:"block"})};
  ${tablette({height:"auto", display:"block",maxHeight:"85vh", overflowY:"scroll"})};

  
`;
const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
`;
const Title = styled.h1`
  text-align: center;
height:120px;
align-items: center;
display: flex;
justify-content: center;
`;

const Input = styled.input`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  z-index: 999;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;
const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
`;




const Image = styled.img`
width:45vw;
height: 45vw;
object-fit: cover;
z-index: 2;
${mobile({width:"100vw", height:"100vw"})};
${tablette({width:"100%", height:"75vw"})};

`;
const Info = styled.div`
display: flex;
width:100%;
flex-direction: column;
${mobile({display:"none"})};
line-height:25px;
padding-right:0px;
min-height:45vw;
`;

const Author = styled.div`
display: flex;
height:100px;
width:100%;
align-items: center;
position:relative;
`;
const Profilepict = styled.img`
width:50px;
height: 50px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 20px;
margin-left: 30px;
${mobile({marginLeft:"20px"})};

`;

const ProfilePictCom = styled.img`
width:30px;
height: 30px;
object-fit: cover;
border-radius: 50%;
margin-right: 20px;
margin-left: 30px;
${mobile({marginLeft:"20px"})};

`;



const Label = styled.label`
  font-size: 14px;
`;


const Description = styled.div`
display: flex;
height:100px;
width:100%;
align-items: center;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 46%;
  left: ${(props) => props.direction === "left" && "2vw"};
  right: ${(props) => props.direction === "right" && "2vw"};
  margin: auto;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
  cursor:pointer;
  ${mobile({display:"none"})};

`;
const AuteurS = styled.div`
 display:flex;
 width:100%;
 height:70px;
 align-items: center;
 font-weight: 600;
 ${tablette({display:"none"})};
 ${computer({display:"none"})};

`;
const InfoS = styled.div`
 width:auto;
 height:auto;
 margin-top: 20px;
 margin-left: 20px;
 overflow-y: scroll;
 ${tablette({display:"none"})};
 ${computer({display:"none"})};
margin-right:10px;


`;
const ScrollDiv= styled.div`
overflow-y:scroll;
height:calc(100vh - 110px);
${tablette({display:"none"})};
${computer({display:"none"})};

`;
const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  position:absolute;
  color:white;
  cursor:pointer;

  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"250px",display:"flex", justifyContent:"center", fontSize: "14px", padding:"5px", bottom:"0px", fontWeight:"600"})}
  ${tablette({width:"300px",  display:"flex", justifyContent:"center",fontSize: "14px", padding:"10px", bottom:"10px",fontWeight:"600"})}
  ${computer({width:"400px",  display:"flex", justifyContent:"center",fontSize: "19pxpx", padding:"10px", bottom:"40px",fontWeight:"600"})}

`
const ReturnS = styled.div`

 ${tablette({display:"none"})};
 ${computer({display:"none"})};


`;

const Input1 = styled.input`
  margin: 10px 10px 0px 0px;
  padding: 10px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: none;
  width: 100%;
  outline: none;

  :focus {
    outline: none;
}
`;

const Button1 = styled.button`
margin-top: 10px;
border: none;
padding: 10px 15px;
color: white;
background-color: #8798ef;
margin-left: auto;
margin-right: 20px;
cursor: pointer;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
const Button2 = styled.button`
margin-top: 0px;
border: none;
height: 35px;
padding: 10px 15px;
color: white;
background-color: #8798ef;
margin-left: auto;
margin-right: 20px;
cursor: pointer;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;

const Div1 = styled.div`
position:relative;
height:45vw;
${computer({overflowY: "scroll", width:"100%"})};
${mobile({overflowY: "scroll"})};


`;

const PostZoom = ({setUser, ads,setOpen , user,items,item,creator, setNumber,setItem,number,numbers}) => {
  const [img, setImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [comId, setComId] = useState("");

  const [larger, setLarger] = useState(false);
  const [liked, setLiked] = useState(false);
  const [favorited, setFavorited] = useState(false);
  const [commentaire, setCommentaire] = useState("");
  const [comments, setComments] = useState(undefined);
  const [postDate, setPostDate]= useState("");
  const [likesNumber, setLikesNumber] = useState(item.likesNumb);
  const [isdisable, setIsdisable] = useState(true);


  const navigate = useNavigate()

//   const handleChange = (e) => {
//     setInputs((prev) => {
//       return { ...prev, [e.target.name]: e.target.value };
//     });
//   };

//   const handleTags = (e) => {
//     setTags(e.target.value.split(","));
//   };

useEffect(() => {
  if(window.screen.width>480){
    setLarger(true)
  }
    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';


}, []);


useEffect( async() => {
  if(user!="unknown"){
    setLiked(item.likesId.includes(user._id));
    setFavorited(user.favoritePosts.includes(item._id));
  }

  const date = new Date(item.createdAt);
  const monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
  const monthName1 = monthNames[date.getMonth()];
  setPostDate(date.getDate() +" "+ monthName1+" "+ date.getFullYear())

  const res= (await axios.get(ads+'api/comments/posts/'+item._id)).data;
  res.sort(function(a,b) {
    return new Date(b.date) - new Date(a.date)
});

  setComments(res)
}, []);


const handleClick = (direction) => {
    if(direction=="left"){
        setItem(items[number-1])
        setNumber(number-1)
    }
    if(direction=="right"){
        setItem(items[number+1])
        setNumber(number+1)

    }
          };

const open = (item) => {

    setOpen2(true)
}

const likeIt = async() =>{
  if(user=="unknown"){
    setOpen4(true)
    return
}
  const res= (await axios.post(ads+'api/likes/posts/'+item._id+'/'+user._id)).data;
  setLiked(true)
  setLikesNumber(likesNumber+1)
}
const dislikeIt = async() =>{
  if(user=="unknown"){
    setOpen4(true)
    return
}
  const res= (await axios.put(ads+'api/likes/posts/'+item._id+'/'+user._id)).data;
  setLiked(false)
  setLikesNumber(likesNumber-1)

}

const favoriteIt = async() =>{
  if(user=="unknown"){
    setOpen4(true)
    return
}
  const res= (await axios.post(ads+'api/favorites/posts/'+item._id+'/'+user._id)).data;
  setFavorited(true)
  var user1=user
  user1.favoritePosts.push(item._id)
  setUser(user1)
}

const sendComment = async() =>{
  if(user=="unknown"){
    setOpen4(true)
    return
}
  const res= (await axios.post(ads+'api/comments/posts/'+item._id+'/'+user._id,
  {
    comment:commentaire
  })).data;
  var com1= [...comments]
  com1=[{username:user.username,userId:user._id, profilePicture:user.profilePicture, commentaire: commentaire,_id:res._id}].concat(comments)
  setComments(com1)
  setCommentaire("");
}
const deleteCom = (id) =>{
  setComId(id)
  setOpen3(true)

}

const deFavoriteIt = async() =>{
  const res= (await axios.put(ads+'api/favorites/posts/'+item._id+'/'+user._id)).data;
  setFavorited(false)
  var user1=user
  const index = user1.favorites.indexOf(item._id);
  if (index > -1) { // only splice array when item is found
      
      user1.favoritePosts.splice(index, 1); // 2nd parameter means remove one item only
  }
  setUser(user1)

}

  return (
    <>
    <Container>






                <Close onClick={() => setOpen(false)}>
                    <CloseIcon style={{color:"white", fontSize:"30px"}}/>
                </Close>

        {number!=0?
        
        <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined style={{color:"black",fontSize:"27px"}}/>
      </Arrow>
     :null}

      <Wrapper>

      <ReturnS style={{position:"relative"}}>
    <ArrowBackIosIcon style={{top:"15px",left:"15px",cursor:"pointer",position:"absolute", fontSize:"20px"}} onClick={()=>setOpen(false)}/>

    <div style={{justifyContent:"center",alignItems:"center",display:"flex", width:"100%", height:"50px",borderBottom:"2px solid #ececec"}}>
                <div>            POSTS
                </div>
            </div>
    </ReturnS>
    {larger?
    <div style={{position:"relative"}}>
    <Image src={ads+item.thumbnails[0]} />
    {item.privacy=="Subscribers"?
    <Lock>
        Abonnez-vous pour voir ce Post

    </Lock>
    :null
    }
    </div>
:null    
  
  }
    <ScrollDiv >
      <AuteurS>
<Profilepict src={ads+creator.profilePicture}/> 
<div>
{creator.username}

<div style={{marginTop:"5px",color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
</div>

</AuteurS>
<div style={{position:"relative"}}>
  {!larger?
<Image src={ads+item.thumbnails[0]} />
:null}
{item.privacy=="Subscribers"?
<Lock>
Abonnez-vous pour voir ce Post
</Lock>
:null
}
</div>


<InfoS>
<span style={{fontWeight:"600"}}>{creator.username}</span>

<div
      dangerouslySetInnerHTML={{__html:item.description}} style={{marginLeft:"0px", marginTop:"10px", marginBottom:"20px", lineHeight:"22px"}}
    />
 
  </InfoS>
  <div style={{display:"flex", marginTop:"20px", paddingBottom:"20px",borderBottom:"2px solid #ececec",paddingTop:"25px",borderTop:"2px solid #ececec"}}>
    {liked?
    <FavoriteOutlinedIcon onClick={()=>dislikeIt()} style={{marginRight:"5px", color:"#ff696e", cursor:"pointer", marginLeft:"30px"}}/>
:
    <FavoriteBorderIcon onClick={()=>likeIt()} style={{marginRight:"5px", cursor:"pointer", marginLeft:"30px"}}/>

  }
  {Number(likesNumber)>1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aimes</span>
:
null
  }
    {Number(likesNumber)==1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aime</span>
:
null
  }
      {Number(likesNumber)==0?
    <span style={{marginRight:"20px", width:"30px"}}></span>
:
null
  }
<ChatOutlinedIcon style={{marginRight:"5px", cursor:"pointer"}}/>
{comments?.length==1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaire</span>
:null}
{comments?.length>1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaires</span>
:null}
{favorited?
  <BookmarkAddedIcon onClick={()=>deFavoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>
:
<BookmarkAddOutlinedIcon onClick={()=>favoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>

}


  </div>

  
  <div style={{borderBottom:"2px solid #ececec",display:"flex", marginTop:"10px", paddingBottom:"10px", backgroundColor:"white"}}>
<Input1 style={{marginTop:"0px"}} placeholder="Ajouter un commentaire :-)" value={commentaire} 
onChange={(e) => {
  setCommentaire(e.target.value); 
  if(e.target.value!=""){
    setIsdisable(false)
  }else{
  setIsdisable(true)
}}}/>
<Button2 disabled={isdisable} onClick={()=>sendComment()}
        >Publier</Button2>
</div>
  <div>
  {comments?.map((item) => (

<div>
 
  <div style={{display:"flex", marginTop:"10px", paddingBottom:"10px"}}>
  <ProfilePictCom src={ads+item.profilePicture}/>  
  <span><span style={{fontWeight:"600", marginRight:"6px"}}>
    {item.username}
  </span>
    {item.commentaire}
  </span>
{user._id==item.userId?
  <CloseIcon style={{marginLeft:"auto",  color:"grey", cursor:"pointer"}} onClick={()=>deleteCom(item._id)}/>
  :null}
</div>
<div style={{display:"flex",  paddingBottom:"10px",borderBottom:"1px solid #ececec", color:"grey", paddingLeft:"30px", fontSize:"13px"}}>
{item.delai}
</div>
</div>
 ))}
 </div>
  </ScrollDiv>
  <Div1 >
  <Info >
  <div style={{borderBottom:"2px solid #ececec", paddingBottom:"30px"}}>


<Author>
               <Profilepict src={ads+creator.profilePicture}/> 
               <div>
{creator.username}

<div style={{color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
</div>
               {/* <Follow>
                            <h2 style={{letterSpacing: "2.5px"}}>FOLLOW</h2>
                        </Follow> 
              
                <Subscribe>
                        <h2 style={{letterSpacing: "2.5px"}}>SUBSCRIBE</h2>

                    </Subscribe> */}
                    {user._id==creator._id?
                  <MoreHorizIcon onClick={()=>open(item)} style={{position:"absolute",right:"30px",alignItems:"center",cursor:"pointer"}}/>
                    :null}
            </Author>
            <div
      dangerouslySetInnerHTML={{__html:item.description}} style={{marginLeft:"30px",paddingRight:"20px"}}
    />

  </div>
  <div style={{display:"flex", marginTop:"20px", paddingBottom:"20px",borderBottom:"2px solid #ececec"}}>
    {liked?
    <FavoriteOutlinedIcon onClick={()=>dislikeIt()} style={{marginRight:"5px", color:"#ff696e", cursor:"pointer", marginLeft:"30px"}}/>
:
    <FavoriteBorderIcon onClick={()=>likeIt()} style={{marginRight:"5px", cursor:"pointer", marginLeft:"30px"}}/>

  }
  {Number(likesNumber)>1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aimes</span>
:
null
  }
    {Number(likesNumber)==1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aime</span>
:
null
  }
      {Number(likesNumber)==0?
    <span style={{marginRight:"20px", width:"30px"}}></span>
:
null
  }
<ChatOutlinedIcon style={{marginRight:"5px", cursor:"pointer"}}/>
{comments?.length==1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaire</span>
:null}
{comments?.length>1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaires</span>
:null}
{favorited?
  <BookmarkAddedIcon onClick={()=>deFavoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>
:
<BookmarkAddOutlinedIcon onClick={()=>favoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>

}


  </div>
  <div>
  {comments?.map((item) => (

<div>
 
  <div style={{display:"flex", marginTop:"10px", paddingBottom:"10px"}}>
  <ProfilePictCom src={ads+item.profilePicture}/>  
  <span><span style={{fontWeight:"600", marginRight:"6px"}}>
    {item.username}
  </span>
    {item.commentaire}
  </span>
{user._id==item.userId?
  <CloseIcon style={{marginLeft:"auto",  color:"grey", cursor:"pointer"}} onClick={()=>deleteCom(item._id)}/>
  :null}
</div>
<div style={{display:"flex",  paddingBottom:"10px",borderBottom:"1px solid #ececec", color:"grey", paddingLeft:"30px", fontSize:"13px"}}>
{item.delai}
</div>
</div>
 ))}
 </div>
{/* <div style={{display:"flex", marginTop:"10px", paddingBottom:"10px",borderBottom:"1px solid #ececec"}}>
  <ProfilePictCom src={ads+creator.profilePicture}/>  
  <span><span style={{fontWeight:"600",marginRight:"6px"}}>
    Leonard
  </span>
    Lorem ipsum dolor sit amet, consectetur adipisicing azoijdoaz azdjoaz ziadj 
  </span>

  
</div> */}

</Info >
<div style={{borderTop:"1px solid #ececec", position:"sticky", bottom:"0",display:"flex", marginTop:"10px", paddingBottom:"10px",borderBottom:"1px solid #ececec", backgroundColor:"white"}}>
<Input1 placeholder="Ajouter un commentaire :-)" value={commentaire} 
onChange={(e) => {
  setCommentaire(e.target.value); 
  if(e.target.value!=""){
    setIsdisable(false)
  }else{
  setIsdisable(true)
}}}/>
<Button1 disabled={isdisable} onClick={()=>sendComment()}
        >Publier</Button1>
</div>
  </Div1>


        
      </Wrapper>
      {number!=(numbers-1)?

      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined style={{color:"black",fontSize:"27px"}}/>
      </Arrow>:null}
    </Container>
    {open2 && <ModifyPost ads={ads} setOpen2={setOpen2} item={item} />}
    {open3 && <DeleteCom  ads={ads} setOpen={setOpen3} item={item} user={user} comId={comId}/>}
    {open4 && <LogYou   setOpen={setOpen4} />}

    </>

  );
};

export default PostZoom;