import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState, useEffect} from "react";
import axios from "axios";

import SetupForm from '../components/SetupC';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51MGtI7DCYXA5jB6uwiWw5Ul94nNL6x2CmuIpbtiOmX0LAYlBj5psxrp0pbBheGg1uh7Kt6ICcXBCNFoTENBiogcS00ncq9dDAN');

function App() {
    const [options, setOptions] = useState("");

    useEffect( async() => {
        const pp=(await axios.get('http://51.178.60.185/api/checkout/savecard')).data;
        console.log(pp.client_secret)
        setOptions ({
            // passing the client secret obtained in step 3
            clientSecret: pp.client_secret,
            // Fully customizable with appearance API.
            appearance: {theme: 'stripe'},
           
                
              
          });

    },[])






  return (
      <div style={{width:"400px", border:"solid 2px #eeee", padding:"20px"}}>
{options!=""?
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
    :null}
    </div>
  );
};

export default App;
