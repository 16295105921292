import { ExploreOutlined, Search,  SupervisorAccountOutlined } from '@mui/icons-material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { useState, useEffect} from "react";
import axios from "axios";
import { cover } from "../data";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Settings from './Settings';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Publish from './Publish';
import Upload from './Upload';
import PlayCircleOutlineSharpIcon  from '@mui/icons-material/PlayCircleOutlineSharp';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { mobile,tablette } from "../responsive";
import NotificationsM from './NotificationsM';





const Wrapper = styled.div`
    position: fixed;
    display:flex;
    width:100%;
    z-index:3;
    top:0;
    height: 60px;
    background-color:white;
    align-items:center;
    border-bottom: solid 2px #ececec;

`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
`;

const Left = styled.div`
   
    display: flex;
    align-items: center;

`
const Center = styled.div`
    margin-left: 20px;
    margin-bottom: 33px;
`
const Logo = styled.h1`
font-size: 25px;
margin-top: 30px;
  font-weight: bold;
  ${tablette({ display:"none" })};

`;
const Logo1 = styled.h2`
margin-top: 20px;
  font-weight: bold;
  ${tablette({ display:"block",textAlign:"center" })};

`;
const Right = styled.div`
    height:75vh;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: flex-end; */
`

const End= styled.div`
display: flex;
bottom:0px;
position:relative;
${tablette({ justifyContent:"center"})};

/* align-items: center;
justify-content: flex-end; */
`


const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  margin-top:50px;
  height: 80px;
    z-index:2;
    ${tablette({ justifyContent:"center"})};

`;

const MenuItemT = styled.div`
    position: relative;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 60px;
    z-index:2;
    ${tablette({ justifyContent:"center"})};

`;
const MenuItemS = styled.div`
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  align-items: center;
  text-align: center;
  letter-spacing: 1.1px;
  float:right;
`;
const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  
`;



const NavText= styled.div`
margin-left:15px;
font-size:16px;
${tablette({ display:"none" })};


`;


const NavMore= styled.div`
margin-left:15px;
font-size:16px;
${tablette({ display:"none" })};


`;

const Input = styled.input`
  border: none;
`;
const Grad = styled.div`
  height: 15px;
  background-image: linear-gradient(to top, rgba(245, 251, 253,0), rgba(0, 0, 0,0.13));
  z-index:-1;
`;

const Littlepicture = styled.img`
width: 36px;
height: 36px;
object-fit: cover;
border-radius: 50%;
border:2px solid black;
cursor: pointer;
z-index:2;
`;
const NewMessage = styled.div`
height:10px;
width:10px;
border-radius: 50%;
border:2px solid white;
background-color: #7c74fd;
position:absolute;
top:15px;
${mobile({ left:"18px" })};

`;

const Navbar = ({ads,setUser,socket, user,connected}) => {
    const [logged, setLogged] = useState(false);
    const [notif, setNotif] = useState(false);
    const [newFollowers, setNewFollowers] = useState([]);
    const [newSubscribers, setNewSubscribers] = useState([]);
    const [newTeas, setNewTeas] = useState([]);
    const [followAccepted,setFollowAccepted]=useState([]);



    useEffect(async() => {



        const qq=(await axios.get(ads+'api/connections/newfollowers/'+user._id)).data;
        setNewFollowers(qq)
        const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
        setNewSubscribers(aa)
        const bb=(await axios.get(ads+'api/connections/newteas/'+user._id)).data;
        setNewTeas(bb)
        if(qq.length>0||aa.length>0||bb.length>0){
            setNotif(true)
        }



        socket.current.on("newFollowing", async(data)=>{
            const qq=(await axios.get(ads+'api/connections/newfollowers/'+user._id)).data;
            setNewFollowers(qq);
            if(qq.length>0){
                setNotif(true)
            }

          })

        socket.current.on("followingAccepted", async(data)=>{
            const user1=(await axios.get(ads+'api/users/'+user._id)).data;

            setFollowAccepted(user1.acceptedRequests);
            
            setNotif(true);
            // var U={...user}
            // U.follow.push(data.following)
            const user11=(await axios.get(ads+'api/users/'+user._id)).data;

            setUser(user11);
            
          })
        socket.current.on("newSubscribing", async(data)=>{
            const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
            setNewSubscribers(aa)
            if(aa.length>0){
                setNotif(true)
            }
        })


        


        socket.current.on("newTeaBought", async(data)=>{
            const aa=(await axios.get(ads+'api/connections/newteas/'+user._id)).data;
            setNewTeas(aa)
            if(aa.length>0){
                setNotif(true)
            }
        })



        // socket.current.on("newTeaBought", async(data)=>{
        //     const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
        //     setNewSubscribers(aa)
        //     if(aa.length>0){
        //         setNotif(true)
        //     }
        // })
    },[])



    const [isHovering, setIsHovering] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [iskeep, setKeep] = useState(true);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);


    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
      setKeep(true);

    };

    const openParam = ()=>{
        if(isOpen){
            setOpen(false);
            setKeep(false);
        }else{
            setOpen(true);
        }
    }
    const openNotif= async()=>{
        setOpen2(!open2)
        if(followAccepted.length>0){
            setNotif(false)

            const q=(await axios.put(ads+'api/connections/followaccepted/'+user._id)).data;
   
        }
        if(newFollowers.length>0){
            setNotif(false)

            const q=(await axios.put(ads+'api/connections/newfollowers/'+user._id)).data;
   
        }
        if(newSubscribers.length>0){
            setNotif(false)
            const q=(await axios.put(ads+'api/connections/newsubscribers/'+user._id)).data;
   
        }
        if(newTeas.length>0){
            setNotif(false)
            const q=(await axios.put(ads+'api/connections/newteas/'+user._id)).data;
   
        }
    }
    return (
<>
        


        
            <Wrapper>
                {/* <Left>
                    <Language>
                        EN
                    </Language>
                    <SearchContainer>
                        <Input/>
                        <Search/>
                    </SearchContainer>
                </Left> */}

                
<Center>
                    <Logo>
                        SoYogis.
                    </Logo>

                </Center>


                {user.teacher&&connected==true?
                
                    <div style={{display:"flex",justifyContent:"end",width:"100%"}}>


                        <MenuItemT onClick={()=>openNotif()}>
                            <FavoriteBorderIcon style={{ color: "#424242", fontSize:"28px" }}/>
                            {notif?
                            <NewMessage/>
                            :null}
                        </MenuItemT>                      

                         <MenuItemT onClick={()=>setOpen1(true)}>
                            <LibraryAddOutlinedIcon style={{ color: "#424242", fontSize:"28px"}}/>

                        </MenuItemT>



                </div>
            :null}

            {!user.teacher&&connected==true?
                    <div style={{display:"flex",justifyContent:"end",width:"100%"}}>


                    <MenuItemT onClick={()=>openNotif()}>
                        <FavoriteBorderIcon style={{ color: "#424242", fontSize:"28px" }}/>
                        {notif?
                            <NewMessage/>
                            :null}
                    </MenuItemT>                      

                     <MenuItemT onClick={()=>setOpen1(true)}>
                        <LibraryAddOutlinedIcon style={{ color: "#424242", fontSize:"28px" }}/>

                    </MenuItemT>



            </div>


                :null}

                


                    {connected==false?
                    <div style={{position:"absolute", right:"30px"}}>
                    <Link to="/signin" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <MenuItemS >SE CONNECTER</MenuItemS>
                    </Link>
                    </div>

                    :null}






                    {/* <MenuItem>
                        <Badge badgeContent={4} color="primary">
                            <ExploreOutlined />
                        </Badge>
                    </MenuItem> */}

               
                {/* <End>
                <MenuItem onClick={openParam} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

                    <MenuSharpIcon style={{ color: "#424242", fontSize:"38px" }}/>

                    <NavMore >
                        MORE
                        </NavMore>
                    </MenuItem>

                </End> */}
            </Wrapper>
            {(isOpen )? 
            <Settings ads={ads} style={{zIndex:"10"}}/>:null}
    
        {open1 && <Publish ads={ads} setOpen={setOpen1} user={user} />}
    {/* {open2 && <NotificationsM newTeas={newTeas} newSubscribers={newSubscribers} newFollowers={newFollowers} style={{zIndex:"10"}} />} */}
    {open2 && <NotificationsM ads={ads} setOpen={setOpen2} followAccepted={followAccepted} socket={socket} user={user} newTeas={newTeas} newSubscribers={newSubscribers} newFollowers={newFollowers} />}

        </>
    )
}

export default Navbar
