import React from 'react'
import styled from 'styled-components'
import Articles from '../components/Articles'
import Coverpicture1 from '../components/Coverpicture1'
import Filter from '../components/Filter'
import Navbar from '../components/Navbar'
import Series from '../components/Series'
import Tabs from '../components/Tabs'
import Videos from '../components/Videos'
import { useLocation } from "react-router-dom";
import { useState, useEffect} from "react";
import axios from "axios";
import Posts from '../components/Posts'
import { mobile,tablette,computer } from "../responsive";
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import NavbarTProfile from '../components/NavbarTProfile'
import Favorites from '../components/Favorites'

import TabsS from '../components/TabsS'
import PrivateAccount from '../components/PrivateAccount'
import Coverpicture from '../components/Coverpicture'

const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div``

const Teacherprofile = ({ads,nM,setNM, setUser,socket,user,connected}) => {
    const [tab, setTab] = useState(0);
    // const [teacherUsername, setTeacherUsername] = useState(location.pathname.split("/")[2]);

    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [me, setMe] = useState(false);

    const [teacher, setTeacher] = useState({});
    const [follower, setFollower] = useState(false);

    useEffect(async() => {
        try { 
            setTab(0);
            const teacher=(await axios.get(ads+'api/users/'+id)).data;
            if(user!="unknown"){
                setFollower(user.follow.includes(teacher._id))
            }
            setTeacher(teacher);

        }catch(e){


        }
  
    },[])

    useEffect(async() => {
        const id = location.pathname.split("/")[2];
        setTab(0)
        const teacher=(await axios.get(ads+'api/users/'+id)).data;
        if(user!="unknown"){
            setFollower(user.follow.includes(teacher._id))
            setMe(user._id==teacher._id)
        }
        setTeacher(teacher);
        //window.location.reload()
      }, [location.pathname]);
    return (
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>


<NavContainer >
<Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        {me?
        <NavbarTProfile ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>:
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>

    }
        

                    {connected?
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
        :null
                    }
                </BottomNav>
{((teacher._id&&user._id!=teacher._id)||(teacher._id&&user=="unknown"))?
            <Coverpicture1 ads={ads} connected={connected} user={user} socket={socket} teacher={teacher}/>

:
null
}

{teacher._id&&user._id==teacher._id?
        <Coverpicture ads={ads} user={user}/>
:null
}
{teacher._id&&user._id!=teacher._id&&teacher.visibility==false&&!follower?
    <PrivateAccount ads={ads}/>
:
null
}

{(teacher.teacher==false&&teacher.visibility==true)||(teacher.teacher==false&&teacher.visibility==false&&follower)?
    <TabsS tab={tab} setTab={setTab} />
:null
}

{teacher.teacher?
                <Tabs tab={tab} setTab={setTab} />
:null
}


{teacher.teacher||user._id==teacher._id||teacher.teacher==false&&teacher.visibility==false&&follower||teacher.teacher==false&&teacher.visibility==true?
<div>
{(tab==0&&teacher._id)?

                <Posts setUser={setUser} ads={ads} connected={connected} user={user} owner={teacher}/>
                :null}
{(tab==1&&teacher._id)?

                <Videos ads={ads} connected={connected} owner={teacher} user={user}/>
                :null}
{(tab==2&&teacher._id)?

                <Series ads={ads} connected={connected} owner={teacher} user={user}/>
                :null}
{(tab==3&&teacher._id)?

                <Articles ads={ads} connected={connected} user={user} owner={teacher}/>:null}
</div>
:null}
{
    tab==4&&user._id==teacher._id&&!teacher.teacher?
    <Favorites setUser={setUser} ads={ads} connected={connected} user={user} owner={teacher}/>

    :
    null
}
</ContainerRight >

    </div>


    )
}

export default Teacherprofile
