import React from 'react'
import styled from "styled-components";
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";


const Desc = styled.p`
  width: 100%;
  margin: 50px 0px;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-weight: 500;
  text-align: center;
  ${mobileLanding({fontSize:"14px", marginTop:"75px", width:"90%", marginLeft:"auto", marginRight:"auto"})};

`;
const Title = styled.p`
  width: 100%;
  margin: 9vh 0px;
  margin-bottom: -25px;
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 700;
  text-align: center;
  ${tabletteLanding({margin:" 4vh 0px",width:"92%", marginLeft:"auto", marginRight:"auto"})}

  ${mobileLanding({fontSize:"20px", padding:"8px", marginTop:"25px",width:"85%", height:"35px",fontWeight:"600", marginLeft:"auto", marginRight:"auto"})}

`;
const Wrapper = styled.div`
  height: 27vh;
  width:100%;
  text-align: center;
  ${mobileLanding({height:"30vh"})};
  ${tabletteLanding({height:"20vh"})};

`;
const Container = styled.div`
  width: 100%;
  height: 27vh;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  ${mobileLanding({height:"30vh"})};
  ${tabletteLanding({height:"20vh"})};

  
`;

const Button = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: #8798ef;
  /* background-color: #5f4fbb; */

  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"35px",width:"85%", height:"35px",fontWeight:"600", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({fontSize:"18px", padding:"8px",fontWeight:"700", marginTop:"4vh",width:"200px", height:"48px", marginLeft:"auto", marginRight:"auto"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px", display:"none"})}

`;

const Message = () => {
    return (
      <div>
                      <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                                <Button>S'INSCRIRE</Button>
                      </Link>
        <Container>

            <Wrapper>
                {/* <Title>
                    SELECT A CATEGORY WHICH FITS YOUR NEEDS
                </Title> */}
                <Title>
                    SELECTIONNEZ LES CATÉGORIES ASSOCIÉES À VOS ENVIES
                </Title>                
                {/* <Desc>
                AND LET US GUIDE YOU TO FIND THE TEACHER THAT WILL BECOME YOUR MENTOR.
                </Desc> */}
                <Desc>
                ET LAISSEZ-NOUS VOUS GUIDER POUR TROUVER LE PROFESSEUR QUI VOUS CONVIENT.
                </Desc>
                {/* <Button>VOIR PLUS DE CATÉGORIES</Button> */}

            </Wrapper>
        </Container>
        </div>

    )
}

export default Message
