
  import styled from "styled-components";
  import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
  import { mobile,tablette,computer } from "../responsive";

  const Info = styled.div`
    width: 100%;
    height: 20%;
    position: relative;
    text-align: center;
    align-items: center;
    cursor: pointer;

  `;
  
  const Container = styled.div`
    width: 20vw;
    height: 20vw;
    position:"relative";
    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
    &:hover ${Info}{
      opacity: 1;
    }
    ${mobile({width:"33vw", height:"33vw"})}
    ${tablette({width:"25vw", height:"25vw"})}

  `;
  
  const Circle = styled.div`
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid white;
    position: absolute;
    margin-top: 18%;
    margin-left:10vw;
  `;

const Play  = styled.div`
    margin: 0 auto ;
    margin-top: 42%;
    position: relative;  
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5em 0 0.5em 1em;
    border-color: transparent transparent transparent white;
    
  `
  
  const Image = styled.img`
    width:100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;
  const Title = styled.h1`
  position: relative;
  align-items: center;
  font-size: 20px;
  left: 50%;
  top:40%;
  transform: translate(-50%, -50%);
  color:black;
`
const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  position:absolute;
  color:white;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"0px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"0px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"0px"})}

`
const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  position:absolute;
  color:#5f4fbb;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:172px;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"0px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"0px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"0px"})}

`
  
  const Video = ({ ads,item, status, handleImageLoad }) => {

    return (
      <Container>
        {item.privacy=="Subscribers"&&status!="subscriber"?
        <Lock>
          S'abonner
        </Lock>
        :null
        }
        {(item.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
        <Lock1>
          Follow
        </Lock1>
        :null
        }
        <Image src={ads+item.thumbnails[0]} 
        onLoad={() => handleImageLoad(item)}
        />

      </Container>
    );
  };
  
  export default Video;