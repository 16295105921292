import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState, useEffect} from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

import PaymentC from '../components/PaymentC';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51MGtI7DCYXA5jB6u9SOvUgSKLWytCLb4mkMpQRxy8nC2pqoIbnuIP4iUfc1PMRPviKraaNyU5wt95tXA6G51yKwH00vYK0zzsK');

function App({user}) {
    const [options, setOptions] = useState("");
    const [validate, setValidate] = useState(false);

    useEffect( async() => {
        // const pp=(await axios.get('http://51.178.60.185/api/checkout/secretk')).data;
        // setOptions ({
        //     // passing the client secret obtained in step 3
        //     clientSecret: pp,
        //     // Fully customizable with appearance API.
        //     appearance: {theme: 'stripe'},
           
                
              
        //   });

    },[])






  return (
      <div>
      <div style={{marginRight:"auto", marginLeft:"auto",marginTop:"200px",width:"400px", border:"solid 2px #eeee", padding:"20px", backgroundColor:"green", color:"white"}}>

    <Elements stripe={stripePromise} options={options}>
      <PaymentC setValidate={setValidate}/>
    </Elements>


    </div>
    <div>
            {validate?
                      <Link to={"/profile/"+user._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

    <div style={{marginRight:"auto", marginLeft:"auto",marginTop:"50px",width:"400px", textAlign:"center", cursor:"pointer"}}>
      Retourner sur mon Profil
      </div>
    </Link>:
    null
    }
    </div>
    </div>
  );
};

export default App;
