
  import styled from "styled-components";
  import { mobile,tablette,computer } from "../responsive";
  import { useState, useEffect} from "react";
  import StarIcon from '@mui/icons-material/Star';
  import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
  import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
  const Info = styled.div`
    width: 100%;
    ${computer({height: "120px"})}
    ${tablette({height: "120px"})}

    position: relative;
  
    /* text-align: center;
    align-items: center; */
    cursor: pointer;

  `;
  const Item=styled.h4`
  letter-spacing: 2px;
  font-weight:600;
  display:flex;
  align-items: baseline;
  color: grey;
  position:absolute;
  z-index:2;
  color:white;
  bottom:10px;
  left:10px;
  
  `;
  
  const Container = styled.div`
    margin-top: 50px;;
    width: 32.5vw;
    height: 350px;
    ${mobile({width:"70vw", height:"auto",marginTop:"5px", marginBottom:"30px"})}
    ${tablette({width:"30vw", height:"auto",marginTop:"5px"})}
    ${computer({width:"30vw", height:"auto",marginTop:"5px"})}

    justify-content: center;
    background-color: white;
    position: relative;
    &:hover ${Info}{
      opacity: 1;
    }
  `;

const Duration = styled.div`
height:auto;
width:auto;
padding-left: 8px;
padding-right: 8px;
padding-top:3px;
padding-bottom:3px;

border-radius:10px;
background-color: black;
color:white;
position:absolute;
z-index:2;
right:10px;
bottom:10px;
`;

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-around;
${mobile({height: "39.54vw"})}
${tablette({height: "22.5vw"})}
${computer({height: "20.25vw"})}


align-items: center;
width: 100%;

background-color: black;

`;
  
const Circle = styled.div`
width: 100px;
height: 100px;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
cursor: pointer;
align-items: center;
  display:flex;
z-index:2;
${mobile({height: "70px", width:"70px"})}
${tablette({height: "100px", width:"100px"})}

`;

const Play  = styled.div`
margin: 0 auto ;
margin-top: 0;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;
cursor: pointer;
${mobile({marginTop: "0px"})}
${tablette({marginTop: "0px"})}

`
  
  const Image = styled.img`
    width:100%;
    object-fit: cover;
    z-index: 2;
    ${mobile({height: "39.54vw"})}
    ${tablette({height: "22.5vw"})}
    ${computer({height: "20.25vw"})}

  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;
  const Title = styled.h1`
  position: relative;
  align-items: start;
  font-size: 20px;
  ${mobile({height: "110px"})}
  ${tablette({height: "110px"})}

  color:black;
`
const Profilepict = styled.img`
width:45px;
height: 45px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;

`;
const Info2=styled.div`
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
z-index:2;
color:white;
bottom:-2px;
`
const Lock  = styled.button`
  background-color: #5f4fbb;
  padding:0px;
  border:none;
  margin-right:5px;
  color:white;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
position: relative;
  width:auto;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"0px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"0px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", marginTop:"5px"})}

`



const Lock2  = styled.div`
  background-color: #5f4fbb;
  padding:0px;
  z-index:2;
  position:absolute;
  color:white;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 10px; /* position the left edge of the element at the middle of the parent */
  width:auto;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"10px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"10px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"10px"})}

`
const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  z-index:2;
  position:absolute;
  color:#5f4fbb;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 10px; /* position the left edge of the element at the middle of the parent */
  width:172px;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"10px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"10px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"10px"})}

`
  const Video = ({ ads,item,owner,status }) => {
    const [duration, setDuration] = useState("");
    const [style, setStyle] = useState([]);

    function fancyTimeFormat(duration)
    {   
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
    
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
    
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs+ "";
        return ret;
    }
    useEffect(async() => {
      try { 
          setDuration(fancyTimeFormat(item.duration));
          const ss=["ASHTANGA","HATHA","KUNDALINI","RESTORATIVE","PRENATAL","VINYASA","YIN","PRANAYAMA"];
          var Style=[]
          for (let jj = 0; jj < item.style.length; jj++) {
              Style.push(ss[item.style[jj]-1]);
          }
          setStyle(Style);

      }catch(e){


      }

  },[])
    return (
      <Container>

        <Wrapper>
        <Image src={ads+item.thumbnails[0]} />

        <Circle>
            <Play/>
        </Circle>
        <Duration>
{duration}
        </Duration>


        {item.privacy=="Subscribers"&&status!="subscriber"?
        <Lock2>
          Abonnés
        </Lock2>
        :null
        }
        {(item.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
        <Lock1>
          Followers
        </Lock1>
        :null
        }


        </Wrapper>

        <Info>
          <div style={{display:"flex", marginTop:"15px"}}>

<Title>
{item.title}
<div style={{color:"grey",fontWeight:"400", fontSize:"16px", marginBottom:"10px"}}>
</div>
<div style={{display:"flex", alignItems:"center"}}>


<div style={{color:"black",fontWeight:"400", fontSize:"16px"}}>
Intensité
</div>
{item.intensity=="Level 1"?
<div>
 {/* <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon> */}

 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 </div>
:null}
{item.intensity=="Level 2"?
<div>
 {/* <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon> */}
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 </div>
:null}

{item.intensity=="Level 3"?
<div>
<StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 {/* <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon> */}
 </div>
:null}
{item.intensity=="Level 4"?
<div>
 {/* <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon> */}
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>

 </div>
:null}
 {/* <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"rgb(253 193 8)"}}></StarIcon> */}
 {/* <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon> */}
 <div style={{color:"black",fontWeight:"400", fontSize:"16px", marginLeft:"10px"}}>
Difficulté
</div>
{item.difficulty=="For All"?
<div>
  <span style={{color:"black",fontWeight:"600", fontSize:"16px", marginLeft:"6px"}}>Pour Tous</span>
  

 </div>
:null}
{item.difficulty=="Beginner"?
<div>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>

 </div>
:null}
{item.difficulty=="Moderate"?
<div>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>

 </div>
:null}

{item.difficulty=="Intermediate"?
<div>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 </div>
:null}
{item.difficulty=="Advanced"?
<div>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 <SelfImprovementOutlinedIcon style={{fontSize:"18px",color:"#5f4fbb"}}></SelfImprovementOutlinedIcon>
 </div>
:null}
 {/* <StarIcon style={{fontSize:"12px",color:"#fdd005"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"#fdd005"}}></StarIcon>
 <StarIcon style={{fontSize:"12px",color:"#fdd005"}}></StarIcon> */}
</div>
<div style={{width:"auto"}}>
{style?.map((item) => (
<Lock>
{item}
</Lock>
))}
</div>
</Title>
          </div>
          
        </Info>
      </Container>
    );
  };
  
  export default Video;