import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState, useEffect} from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import SetupForm from '../components/SetupP';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51MGtI7DCYXA5jB6uwiWw5Ul94nNL6x2CmuIpbtiOmX0LAYlBj5psxrp0pbBheGg1uh7Kt6ICcXBCNFoTENBiogcS00ncq9dDAN');
const stripePromise = loadStripe('pk_live_51MGtI7DCYXA5jB6u9SOvUgSKLWytCLb4mkMpQRxy8nC2pqoIbnuIP4iUfc1PMRPviKraaNyU5wt95tXA6G51yKwH00vYK0zzsK');

function App({ads, user}) {
    const [options, setOptions] = useState("");
    const [information, setInformation] = useState({});

    const [validate, setValidate] = useState(false);

    useEffect( async() => {
        setInformation(JSON.parse(localStorage.teainfo))
        const pp=(await axios.get(ads+'api/checkout/secretk/'+user._id)).data;
        setOptions ({
            // passing the client secret obtained in step 3
            clientSecret: pp,
            // Fully customizable with appearance API.
            appearance: {theme: 'stripe'},
           
                
              
          });

    },[])






  return (
      <div>
          
          
          <div style={{marginRight:"auto", marginLeft:"auto",marginTop:"200px",width:"400px",  padding:"20px"}}>
          <h2 style={{color:"grey",fontWeight:"600", marginLeft:"-20px"}}>
              Offrez un thé à {information.creatorName} 
          </h2>
          {information.currency=="U.S. Dollar"?
                    <h2 style={{fontSize:"26px", fontWeight:"600", marginLeft:"-20px"}}>
                    ${information.price} 
                </h2>
:null}
                {information.currency=="Euro"?
                <h2 style={{fontSize:"26px", fontWeight:"600", marginLeft:"-20px"}}>
                {information.price}€ 
                </h2>
                :null}

          </div>
        









          {/* "periode":"month", "creatorId":owner._id, "price":price, "creatorName": owner.username */}
      <div style={{marginRight:"auto", marginLeft:"auto",width:"400px", border:"solid 2px #eeee", padding:"20px"}}>
      {options!=""?
    <Elements stripe={stripePromise} options={options}>
      <SetupForm ads={ads}/>
    </Elements>
    :null}

    </div>
    <div>

    </div>
    </div>
  );
};

export default App;
