import React,  { useRef } from 'react'
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { mobile,tablette,computer } from "../responsive";
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useState, useEffect} from "react";
import axios from "axios";
import * as tus from 'tus-js-client'
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({width:"100%", padding:"0", marginTop:"70px"})};

`;



const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;

    text-align: center;
    ${tablette({width:"auto"})};

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    ${tablette({width:"auto"})};

    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
  ${tablette({fontSize:"13px"})};
  ${mobile({fontSize:"13px"})};

`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  ${mobile({fontSize:"13px"})};


`;
const Label = styled.label`
  font-size: 18px;
`;
const LabelS = styled.label`
  font-size: 18px;
  ${mobile({display:"none"})};

`;
const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;
${mobile({width:"80vw", height:"45.19vw", marginLeft:"auto", marginRight:"auto"})};


`;
const Image1 = styled.img`
width:70vw;
height: 39.55vw;
object-fit: cover;
${mobile({width:"100vw", height:"56.5vw"})};
${tablette({width:"77vw", height:"43.5vw"})};

cursor: pointer;
`;
const Circle = styled.div`
width: 100px;
height: 100px;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
cursor: pointer;
align-items: center;
  display:flex;
z-index:2;
${mobile({height: "70px", width:"70px"})}
${tablette({height: "100px", width:"100px"})}

`;
const Play  = styled.div`
margin: 0 auto ;
margin-top: 0;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;
cursor: pointer;
${mobile({marginTop: "0px"})}
${tablette({marginTop: "0px"})}

`

const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  position:absolute;
  color:white;
  cursor:pointer;

  border-radius: 60px;
  font-size: 18px;
  font-weight: 450;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"90%",display:"flex", justifyContent:"center", fontSize: "14px", padding:"5px", bottom:"10px", fontWeight:"600"})}
  ${tablette({width:"350px",  display:"flex", justifyContent:"center",fontSize: "14px", padding:"10px", bottom:"10px",fontWeight:"600"})}
  ${computer({width:"450px",  display:"flex", justifyContent:"center",fontSize: "19pxpx", padding:"10px", bottom:"40px",fontWeight:"600"})}

`

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #727374;
    cursor: auto;
  }
  ${mobile({marginTop:"50px", marginBottom:"100px"})};

`;
const Th = styled.div`
border:1px solid black;
padding:40px;
width:640px;
${tablette({width:"500px"})}
${mobile({width:"60vw"})}
`;

const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;
const Options1=styled.div`
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: auto;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right:auto;
  position:relative;
  ${mobile({width:"95%"})}

`;
const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 30px;
`;
const Option = styled.option``;

const Modifvideo = ({ user,ads,Video }) => {
    const navigate = useNavigate();
    const [progress, setProgress] = React.useState(0);
    const [progress1, setProgress1] = React.useState(0);

    const [duration,setDuration]=useState(undefined);
    const [showPro,setShowPro]=useState(false);
    const [success1,setSuccess1]=useState(false);
    const [showPro1,setShowPro1]=useState(false);
    const [success,setSuccess]=useState(false);
    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState(Video.description);
    const [img, setImg] = useState(undefined);
    const [video, setVideo] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [theArray, setTheArray] = useState([]);
    const editorRef = useRef(null);
    const [isdisable, setIsdisable] = useState(false);
    const [thechecked, setcheck] = useState([true]);
    const [videoSaved,setVideoSave]= useState(0);
    const [newT, setT] = useState(true);
    const [imgURL, setImgUrl] = useState(undefined);


    const handleFilters = (e) => {
        const value = e.target.value;
        setFilters({
          ...filters,
          [e.target.name]: value,
        });
      };
    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }

    useEffect(async() => {
        try { 

            setCreator(user);
            setTitle(Video.title);
            setFilters({
                
              ["diff"]: Video.difficulty,
              ["intens"]: Video.intensity
          
            });
            setTheArray(Video.style)

        }catch(e){


        }
  
    },[])


    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }
    
    function handleNew(e){
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {

          FF[jj]=false
        
      }
      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true);

    }




    CircularProgressWithLabel.propTypes = {
      /**
       * The value of the progress indicator for the determinate variant.
       * Value between 0 and 100.
       * @default 0
       */
      value: PropTypes.number.isRequired,
    };

    const loadVideo = file => new Promise((resolve, reject) => {
      try {
          let video = document.createElement('video')
          video.preload = 'metadata'
    
          video.onloadedmetadata = function () {
              resolve(this)
          }
    
          video.onerror = function () {
              reject("Invalid video. Please select a video file.")
          }
    
          video.src = window.URL.createObjectURL(file)
      } catch (e) {
          reject(e)
      }
    })
    
    const changePP=(e)=>{
      setImg(e.target.files[0]);
      setImgUrl(URL.createObjectURL(e.target.files[0]))
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
          FF[jj]=false
        }

      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true)
      // if(e.target.files[0].size>1000000){
      //     setTooLarge(true);
      // }else{
      //     setTooLarge(false);
      // }
      // setshowImage(true)
    }
    
    const tr = async (f)=>{
      setVideo(f)
      const videoss=await loadVideo(f)
      setDuration(videoss.duration)
    }

    function handleChange(e) {
      const value = e.target.value;
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
        if(jj==value){
          FF[jj]=true
        }else{
          FF[jj]=false
        }
      }
      setcheck(FF);
      setVideoSave(value);
      if(newT==true){
        setT(false)
      }
    }
    const updateVideo= async () =>{
            setIsdisable(true)
            
            await axios.put(ads+'api/video/'+Video._id,{"description":editorRef.current.getContent()});
            
            if (title != ''){
                await axios.put(ads+'api/video/'+Video._id,{"title":title});
            }
            await axios.put(ads+'api/video/'+Video._id,{"style":theArray, "difficulty":filters.diff,"intensity": filters.intens});

            if(img!=undefined){
              var Thumb;

              if(videoSaved==Video.thumbnails.length){
                setShowPro1(true)
                const formData = new FormData();
                formData.append('profile', img, img.name);
                await axios.post(ads+'api/video/thumbnails/'+Video._id, formData,
                {   
                  onUploadProgress: progressEvent => {
                    setProgress1((progressEvent.loaded / progressEvent.total)*100)
                  }
                });

                const Video1=(await axios.get(ads+'api/video/'+Video._id)).data;
                Thumb=[Video1.thumbnails[Video1.thumbnails.length-1]].concat(Video.thumbnails)
                await axios.put(ads+'api/video/'+Video._id,{"thumbnails":Thumb});
                setSuccess1(true)
                setShowPro1(false)

              }else{
                Video.thumbnails=[Video.thumbnails[videoSaved]].concat(Video.thumbnails);
                
                Video.thumbnails.splice(Number(videoSaved)+1,1)
        
        
                Thumb=Video.thumbnails
                await axios.put(ads+'api/video/'+Video._id,{"thumbnails":Thumb});
                setSuccess1(true)

              }





              // const formDataThumb = new FormData()
              // formDataThumb.append('profile', img, img.name)
              // const thum = await axios.post(ads+"api/video/thumbnails/"+Video._id,formDataThumb)
          }
          if(videoSaved!=0&&img==undefined){
            Video.thumbnails=[Video.thumbnails[videoSaved]].concat(Video.thumbnails);
                
            Video.thumbnails.splice(Number(videoSaved)+1,1)
    
    
            const Thumb=Video.thumbnails
            await axios.put(ads+'api/video/'+Video._id,{"thumbnails":Thumb});
            setSuccess1(true)

          }


            if(video!=undefined){
              if (duration != undefined){
                await axios.put(ads+'api/video/'+Video._id,{"duration":duration});
            }
                // const formData = new FormData()
                // formData.append('profile', video, video.name)
                // const res1 = await axios.put(ads+"api/video/video/"+Video._id,formData,{   
                //     onUploadProgress: progressEvent => {
                //         console.log(progressEvent.loaded / progressEvent.total);
                //     }
                //   })
                
                const res = (await axios.put(ads+"api/video/bunny/"+user._id+"/"+Video._id)).data
                setShowPro(true)
// Create a new tus upload
var upload = new tus.Upload(video, {
  endpoint: "https://video.bunnycdn.com/tusupload",
  retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
  headers: {
      AuthorizationSignature: res.key, // SHA256 signature (library_id + api_key + expiration_time + video_id)
      AuthorizationExpire: res.time, // Expiration time as in the signature,
      VideoId: res.videoId, // The guid of a previously created video object through the Create Video API call
      LibraryId: res.libraryId,
  },
  metadata: {
      filetype: video.type,
      title: video.name,
      collection: res.collectionId
  },
  onError: function (error) {
    if (window.confirm(`Upload failed because: ${error}\nDo you want to retry?`)) {
      upload.start()
    }
   },
  onProgress: function (bytesUploaded, bytesTotal) {
            setProgress((bytesUploaded / bytesTotal)*100)
  //         console.log(progressEvent.loaded / progressEvent.total);
   },
  onSuccess: async function () { 
    // showD.current.style.display="none"
    setSuccess(true)
    setShowPro(false)
  }
})

// Check if there are any previous uploads to continue.
upload.findPreviousUploads().then(function (previousUploads) {
  // Found previous uploads so we select the first one. 
  if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0])
  }

  // Start the upload
  upload.start()
})




            }

            //navigate('/videos');
        }
    


    return (
        
        <Container>
                        <Wrapper2>
                        <Label>Titre :</Label>

<Input
value={title}
  type="text"
  placeholder="Title"
  name="title"
  onChange={(e) => setTitle(e.target.value)}
/>
<br/>
                        <div style={{display:"flex", alignItems:"center"}}>

            <Label>Vidéo :</Label>

          </div>
            </Wrapper2>
{Video.bunnyId&&Video.status==4?
            <Wrapper1>
            <iframe src={"https://iframe.mediadelivery.net/embed/"+process.env.REACT_APP_BUNNY_ID+"/"+Video.bunnyId+"?autoplay=true"} 
                 
                 style={{border: "none",position:"relative",height:"39.37vw", width: "70vw",display:"block",margin: "0 auto"}} 
                 loading="lazy" 
                 // allow={{accelerometer, gyroscope, autoplay, encryptedMedia, pictureInPicture}} 
                 allowfullscreen="true">
                 </iframe>
            </Wrapper1>
            
            :null}
            {Video.bunnyId&&(Video.status==3||Video.status==2||Video.status==1)?

            <Wrapper>

<Image1 src={ads+Video.thumbnails[0]} />
<Circle>
    <Play/>
</Circle>

    <Lock>
      {/* Sign in to watch this class */}
      Veuillez patienter, la vidéo est en cours d'encodage
    </Lock>



</Wrapper>
:null}
            <Wrapper2>
            <div style={{display:"flex", alignItems:"center"}}>

<Label>Modifier ma vidéo :</Label>
<InputT
type="file"
accept="video/*"
onChange={ (e) => tr(e.target.files[0])}
/>
</div>

<br/>
<Label>Description :</Label>

<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={description}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />


{/* <Desc
value={description}

          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => setDescription(e.target.value)}
        /> */}
<br/>
<div style={{display:"flex", alignItems:"center"}}>
<Label>Miniature :</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => {
    changePP(e)
  }}
/>




</div>
{imgURL?
  <div style={{border:"1px solid black", padding:"40px",width:"640px"}}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" checked={newT} onChange={(e) => handleNew(e)} />
    </div>
<Image src={imgURL}/>
</div>

:null}
{Video.thumbnails?.map((item,i) => (
<Th  key={i}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" 
      
      value={i}
      onChange={(e) => handleChange(e)}
      checked={thechecked[i]} 
      
      
      />
    </div>
<Image  src={ads+item} />

</Th>

))}






{/* <Image src={ads+Video.thumbnails[0]} /> */}
<br/>

<Options 
>
  
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"40px"}}>Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>
</Options>


<OptionsS >

<Label>Style :</Label>


        <Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
            </FilternameS>
            : null}

            {theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
            </FilternameS>
            : null}


   
        </Containerfilters> 

        <Containerfilters >

        {theArray.includes(8)==false?
<Filtername onClick={() => validate(8)}>
    PRANAYAMA
</Filtername>
: null}

{theArray.includes(8)==true?
<FilternameS onClick={() => validate(8)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
</FilternameS>
: null}


            {theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
            </FilternameS>
            : null}

{theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
            </FilternameS>
            : null}

   
        </Containerfilters> 
        <Containerfilters >

        {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
            </FilternameS>
            : null}

{theArray.includes(7)==false?
<Filtername onClick={() => validate(7)}>
    YIN
</Filtername>
: null}

{theArray.includes(7)==true?
<FilternameS onClick={() => validate(7)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
</FilternameS>
: null}






</Containerfilters> 

<div style={{marginTop:"10px"}}>
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>
          </div>
          <div style={{marginTop:"30px"}}>
          <Label >Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>

          </div>

        </OptionsS>

        <LabelS>Style:</LabelS>

<Options1>


        <Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
            </FilternameS>
            : null}

{theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
            </FilternameS>
            : null}

{theArray.includes(8)==false?
<Filtername onClick={() => validate(8)}>
    PRANAYAMA
</Filtername>
: null}

{theArray.includes(8)==true?
<FilternameS onClick={() => validate(8)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
</FilternameS>
: null}








        </Containerfilters> 


        <Containerfilters >


        {theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
            </FilternameS>
            : null}

            {theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
            </FilternameS>
            : null}

        {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
            </FilternameS>
            : null}


{theArray.includes(7)==false?
<Filtername onClick={() => validate(7)}>
    YIN
</Filtername>
: null}

{theArray.includes(7)==true?
<FilternameS onClick={() => validate(7)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
</FilternameS>
: null}


        </Containerfilters> 

</Options1>


            </Wrapper2>





{!isdisable?
  <div style={{display:"flex", marginTop:"0px", marginBottom:"10px"}}>
<Button disabled={isdisable} onClick={updateVideo} >ENREGISTRER</Button>

</div>
:null
}
{showPro1?

<div style={{display:"flex", justifyContent:"center",marginBottom:"40px"}}>


<CircularProgressWithLabel value={progress1} />
</div>
:null}

{showPro?

<div style={{display:"flex", justifyContent:"center",marginBottom:"40px"}}>


<CircularProgressWithLabel value={progress} />
</div>
:null}
{success1?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", marginTop:"20px",display:"flex", justifyContent:"center",marginBottom:"40px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Miniature enregistrées
            </div>
                : null}

{success?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", marginTop:"20px",display:"flex", justifyContent:"center",marginBottom:"40px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Modifications enregistrées
            </div>
                : null}
           
        </Container>
        
    )
}

export default Modifvideo
