import styled from "styled-components";
import { Link } from "react-router-dom";




const Login = () => {
    const date = "13/03/23";
    const appName="SoYogis";
    const entreprise="OmTech Innovations"
    const professeur="Professeur";
    const eleve="Élève"
    const professeurs="Professeurs";
    const eleves="Élèves"
    const siteWeb="https://www.soyogis.com"
    const entrepriseFull = "OmTech Innovations S.A.S.U (dont le siège social est situé au 229 rue Saint-Honoré, 75001, Paris),"
  
  return (
<div style={{marginLeft:"15px", marginRight:"15px"}}>

<div style={{borderBottom:"1px solid grey", marginBottom:"50px", paddingBottom:"30px", paddingTop:"10px"}}>

<h1>
  Mentions Légales
  </h1>

</div>
Le présent site internet soyogis.com est édité et exploité par {entrepriseFull}.
<br/>
<br/>
Vous pouvez nous joindre par courrier électronique à l'adresse support@soyogis.com.
<br/>
<br/>
Le directeur de la publication est Monsieur Léonard TOROSSIAN, Président de OmTech Innovations.

<br/>
<br/>
Ce site est hébergé par la société OVH SAS dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France.
          </div>
  );
};

export default Login;