import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { mobile, computer,tablette } from "../responsive";
import { useLocation } from "react-router-dom";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';


const Container = styled.div`
  width: 100vw;

  min-height: calc(100vh - 63px);
  /* background: linear-gradient(
      rgba(104,146,181, 0.5),
      rgba(104,146,181, 0.5)
    ),
    url("./signup.svg")
      center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  ${mobile({ background:"none",minHeight: "calc(100vh - 100px)" })};
  ${tablette({ alignItems:"center" })};

  ${computer({ alignItems:"center" })};
`;

const Wrapper = styled.div`
  padding: 20px;
  border-radius:10px;
  border:1px solid lightgrey;

  background-color: white;
  ${mobile({ width:"100%", borderRadius:"0px",border:"none"})};
  ${tablette({ width:"450px", marginTop:"40px", marginBottom:"40px" })};
  ${computer({ width:"450px", marginTop:"40px", marginBottom:"40px" })};

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align:center;
  margin-bottom:30px;
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"60px" })}

`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
`;
const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  background-color:#8798ef;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
  margin-bottom: 30px;
  :disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;



const Login = ({ads,setUser, setConnected}) => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const number = location.pathname.split("/")[3];

  const navigate = useNavigate();
  const [isdisable, setIsdisable] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState('');
  const [error, setError] = useState("");
  const [show, setShow]=useState(false);
  const LogMe = async (e) =>{
    e.preventDefault();
    setIsdisable(true);
    setError("");
    try{
       const myToken=await axios.post(ads+'api/auth/resetpassword',{ "number":number, "password":password, "cpassword":cPassword, "id":id });

      setShow(true);
    }catch(e) {
        setError(s=>e.response.data);
    }    
  }
  const handleCPassword = (e) => {

    setCPassword(e.target.value);


}

  useEffect(() => {
    if(cPassword!=''){
        if (password === cPassword) {
          setShowErrorMessage(false);
      } else {
          setShowErrorMessage(true)
      }
      }

  })

  return (
    <div>
    <Container>
      <Wrapper>
      <Link to={"/signin"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <Title>
        <h2 style={{letterSpacing: "1.0px", fontWeight:"700"}}>SoYogis.</h2>
        </Title>
        </Link>
        <Title>
        <h5 style={{letterSpacing: "1.0px", fontWeight:"500"}}>
        Créez un nouveau mot de passe </h5>
        </Title>
        <Form>
        <Input type="password" placeholder="Mot de passe" 
          onChange={(e) => setPassword(e.target.value)}/>
          <Input type="password" placeholder="Confirmez votre mot de passe" onChange={(e)=>{
            handleCPassword(e);
            if(e.target.value!=""&&e.target.value==password){
              setIsdisable(false)
            }else{
              setIsdisable(true)
            }
            
            }}/>
          {showErrorMessage  ? <div> Les mots de passe ne correspondent pas </div> : ''}
      
          <Button disabled={isdisable} onClick={LogMe} >Modifier le mot de passe</Button>

        </Form>

        {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}
                        {show?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Mot de passe modifié
            </div>
                : null}
      </Wrapper>
    </Container>
                <Footer/>
                <FooterS/>
        
                </div>
  );
};

export default Login;