import { Link } from "react-router-dom";
import { mobile, computer,tablette } from "../responsive";

import styled from "styled-components";


const Container = styled.div`
border-top:1px solid #ececec;
${mobile({ display:"none"})};

display: flex;
bottom:0;
height:60px;
justify-content: center;
position: sticky;
`
const Items = styled.div`
height: 60px;
display: flex;
align-items: center;
margin-left: 20px;
margin-right: 20px;
font-family: 'Montserrat', sans-serif;
font-size:12px;
color: grey;
cursor: pointer;
`
const Copyright = styled.div`
margin-top: 30px;
padding:20px;
text-align: center;
`

const Footer = () => {
    return (
        <div>
        <Container>
        <Link to={"/mentionslegales"} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Items>
                Mentions légales
            </Items>
        </Link>
        <Link to={"/contrattype"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Items>
                Contrat type élève/professeur
            </Items>
        </Link>

        <Link to={"/conditionsgenerales"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Items>
                Conditions générales d'utilisation
            </Items>
        </Link>

        <Link to={"/politiqueconfidentialite"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Items>
                Politique de confidentialité
            </Items>
        </Link>


        </Container>
        </div>

    )
}

export default Footer
