import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { mobile, computer,tablette } from "../responsive";
import translations from '../components/translations'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';


const Container = styled.div`
  width: 100vw;

  min-height: calc(100vh - 63px);
  /* background: linear-gradient(
      rgba(104,146,181, 0.5),
      rgba(104,146,181, 0.5)
    ),
    url("./signup.svg")
      center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  ${mobile({ background:"none",minHeight: "calc(100vh - 100px)" })};
  ${tablette({ alignItems:"center" })};

  ${computer({ alignItems:"center" })};
`;

const Wrapper = styled.div`
  padding: 20px;
  border-radius:10px;
  border:1px solid lightgrey;

  background-color: white;
  ${mobile({ width:"100%", borderRadius:"0px",border:"none"})};
  ${tablette({ width:"450px", marginTop:"40px", marginBottom:"40px" })};
  ${computer({ width:"450px", marginTop:"40px", marginBottom:"40px" })};

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align:center;
  margin-bottom:30px;
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"60px" })}

`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  background-color:#8798ef;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
  margin-bottom: 30px;
  :disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;



const Login = ({ads,setUser,socket, setConnected}) => {
  const navigate = useNavigate();
  const [isdisable, setIsdisable] = useState(true);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState("");

  const LogMe = async (e) =>{
    e.preventDefault();
    try{
      const myToken=await axios.post(ads+'api/auth/login',{ "username":username, "password":password });
      // localStorage.setItem('user',JSON.stringify(myToken.data));
      // localStorage.setItem('userDetails',JSON.stringify(myToken.data));
      setUser(myToken.data);
      setConnected(true);
      socket.current.emit("addUser", myToken.data._id)
      socket.current.emit("addUserRoom", myToken.data._id)
      navigate('/profile/'+myToken.data._id);
    }catch(e) {
        setError(s=>e.response.data.error);
        if(e.response.data.error=="Vous n'avez pas validé votre adresse email"){
          setShowEmail(true)
          setEmail(e.response.data.email)
        }
    }    
  }
const ValidateEmail= ()=>{
  localStorage.setItem('usermail',email);
  navigate('/verifyemail');
}

  useEffect(() => {
    if(username!=""&&password!=""){
      setIsdisable(false);
    }
  })

  return (
    <div>
    <Container>
      <Wrapper>
        <Title>
        <h2 style={{letterSpacing: "1.0px", fontWeight:"700"}}>SoYogis.</h2>
        </Title>
        <Form>
          <Input placeholder="Nom d'utilisateur ou email" onChange={(e) => setUsername(e.target.value)}/>
          <Input placeholder={translations["français"]["password"]} type="password" onChange={(e) => setPassword(e.target.value)}/>
          {!showEmail?
            
                        <Button onClick={LogMe} disabled={isdisable}>{translations["français"]['LOG IN']}</Button>

            
            :
            <Button onClick={()=>ValidateEmail()} >{translations["français"]["VALIDATE MY EMAIL"]}</Button>

          }
          {/* <Link to={"/forgotpassword"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

          <div style={{textAlign:"center", marginBottom:"25px", color:"#2a2a9d", cursor:"pointer"}}>{translations["français"]['Forgotten your password']}</div>
        </Link> */}
        </Form>
        {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}

        <div style={{display:"flex", flexDirection:"column"}}>
          <Link to="/forgotpassword" style={{margin: "5px 0px",fontSize: "12px",color:"black"}}>{translations["français"]['DO NOT YOU REMEMBER YOUR PASSWORD?']}</Link>
          <Link to="/signup" style={{margin: "5px 0px",fontSize: "12px",color:"black"}}>{translations["français"]["DON'T HAVE AN ACCOUNT? SIGN UP"]}</Link>
          </div>

      </Wrapper>
      
    </Container>
    <Footer/>
    <FooterS/>
    </div>
  );
};

export default Login;