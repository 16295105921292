import React from 'react'
import styled from "styled-components";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

import Modifysubscription from "./Modifysubscription";

import Deletesubscription from "./Deletesubscription";

import FollowUser from "./FollowUser";
import SubscribeUser from "./SubscribeUser";

import Validatesubscription from "./Validatesubscription";
import Validatesubscription1 from "./Validatesubscription1";
import Validatesubscription2 from "./Validatesubscription2";
import RenewSub from "./RenewSub";

import BuyTea from "./BuyTea";
import LogYou from "./LogYou";

import CoffeeOutlinedIcon from '@mui/icons-material/CoffeeOutlined';
import Validatesubscription3 from "./Validatesubscription3";

import axios from "axios";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { mobile,tablette, computer } from "../responsive";
import { Socket } from 'socket.io-client';



const Container = styled.div`
width: 100%;
position: relative;
`;

const Description= styled.div`
display: flex;
position: relative;
height:auto;
justify-content: space-around;
align-items: unset;
${mobile({height:"auto"})}

`;

const Picture = styled.img`
width: 100%;
height: 65vh;
object-fit: cover;
${mobile({height:"35vh", marginTop:"62px"})};

`;

const Profilepicture = styled.img`

  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  position:relative;
  border: 6px solid #f2f9fb;
  ${tablette({width:"180px",height:"180px"})}
  ${mobile({width:"100px",height:"100px", border:"3px solid  #f2f9fb", marginTop:"40px"})}


  
`;

const Picturecontainer= styled.div`

width: 250px;
height: 250px;
${mobile({width:"130px",height:"130px", top:"-90px", right:"26vw"})}

position:relative;
top:-60px;
margin-left:7%;
margin-right: 3%;
${tablette({width:"180px",height:"180px", top:"-90px", right:"25vw"})}
${computer({width:"230px",height:"230px", top:"-90px", right:"25vw"})}


`;

const Covercontainer = styled.div`
    display:flex;
    align-items:center ;
    text-align: center;
    flex:1;

`;


const InfoContainer = styled.div`
  margin-left:3vw;
  margin-right:2vw;

  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.6;
  padding:35px;
  height: auto;
  margin-top:-70px;
  ${computer({marginTop:"-50px"})}
  ${tablette({marginTop:"-80px", fontSize:"18px"})}

  ${mobile({display:"none"})}


`;

const InfoContainerM = styled.div`
  margin-left:0vw;
  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.6;
  padding:25px;

  height: auto;
  margin-top:-100px;
  ${tablette({display:"none"})}
  ${computer({display:"none"})}
  ${mobile({marginTop:"-70px"})}

`;
const Networks = styled.div`
    margin-top:100px;
    display:flex;
    position:absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    
`;

const Icon = styled.div`
padding:15px;
cursor: pointer;
position: relative;
transition:transform .15s ease;
:hover {
  transform:scale(1.5);
}
`;


const Title = styled.h1`
    position: absolute;
    font-size: 70px;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    ${mobile({fontSize:"40px"})}

`;

const Line = styled.div`
    width:85%;
    border-bottom: 3px solid #ececec;
    margin-left:auto;
    margin-right:auto;
    ${mobile({width:"100%", borderBottom:"2px solid #ececec", marginTop:"10px"})}
    ${tablette({width:"100%", borderBottom:"2px solid #ececec", marginTop:"10px"})}
    ${computer({width:"100%", borderBottom:"2px solid #ececec", marginTop:"10px"})}


`;
const Subscription = styled.div`
height: auto;
width:100%;
align-items: center;
justify-content: center;
flex-direction: column;
margin-bottom:4vw;
${mobile({marginTop:"5px",borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec", height:"105px",marginBottom:"0px"
})}
${tablette({marginTop:"5px",borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec", height:"125px",marginBottom:"0px"
})}
${computer({marginTop:"25px",borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec", height:"125px",marginBottom:"0px"
})}
`;
const Sub = styled.div`
color:#8a96a3;
font-size:13px;
font-weight: 700;
letter-spacing: 1.5px;
margin-top:10px;
${tablette({marginTop:"15px"})}
${mobile({fontSize:"12px"})}

margin-left:10px;
`;

const SubscriptionB = styled.div`
height: auto;
width:100%;
align-items: center;
justify-content: center;
flex-direction: column;
margin-bottom:4vw;
${mobile({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
${tablette({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
${computer({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
`;


const Text01 = styled.h2`
letter-spacing: 0.5px;
${mobile({fontSize:"12px"})}
float:left;
margin-left:1px;
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px",marginLeft:"0vw"})}


`;

const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"11px"})}
float:left;
margin-left:7px;
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px",marginLeft:"2vw"})}


`;
const Text3 = styled.h2`
letter-spacing: 0.5px;
${mobile({fontSize:"12px"})}
margin-left:1px;
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px",marginLeft:"10px",marginRight:"10px"})}


`;
const Text1 = styled.h2`
letter-spacing: 2px;
${mobile({fontSize:"11px"})}
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px", marginRight:"2vw"})}

margin-right:7px;
float:right;
word-spacing: 0.5px;


`;

const Follow = styled.button`
    border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  width:auto;
  height: auto;
  padding:20px;
  margin-right: 5vw;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px"})}
`;
const Follow1 = styled.button`
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  border-radius: 30px;
  width:auto;
  height: auto;
  padding:20px;
  margin-right: 5vw;
  z-index:2;
  display: flex;
  align-items: center;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"12px", padding:"8px", left:"55%", top:"20px", height:"30px"})}
${tablette({fontSize:"30px", padding:"8px", left:"55%", top:"20px",height:"45px"})}
${computer({fontSize:"13px", padding:"8px", left:"410px", top:"20px",height:"45px"})}

`;

const Follow3 = styled.button`
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  border-radius: 50%;
  width:40px;
  height: 40px;
  margin-right: 5vw;
  z-index:2;
  transition:transform .2s ease;
  align-items: center;
  justify-content: center;
  display: flex;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"18px", padding:"8px", left:"55%", top:"20px", height:"30px", width:"30px"})}
${tablette({fontSize:"22px", padding:"8px", left:"55%", top:"20px",height:"45px", width:"45px"})}
${computer({fontSize:"24px",  left:"520px", top:"20px",height:"45px", width:"45px"})}

`;

const NextP = styled.div`
color:grey;
float:right;
margin-top:10px;
margin-right: 15px;
font-size: 13px;
${mobile({fontSize:"12px"})}

`

const Options=styled.div`
position:absolute;
display:flex;
${computer({left:"40vw", top:"60px"})}
${tablette({left:"40vw", top:"30px"})}
${mobile({left:"42vw", top:"20px"})}

`;

const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: #8798ef;
  /* background-color: #5f4fbb; */

  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"45px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"15px",width:"65%", height:"50px"})}

`;



const Coverpicture1 = ( {ads,connected,user, socket,teacher }) => {
    const [Month, setMonth] = useState("");
    const [ThreeMonth, setThreeMonth] = useState("");
    const [SixMonth, setSixMonth] = useState("");
    const [Year, setYear] = useState("");
    const [currency, setCurrency] = useState("");
    const [up, setUp] = useState(false);

    const [open, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);

    const [status,setStatus]=useState(null);

    //const known = JSON.parse(localStorage.userDetails);
    //if(known=!undefined){
        const [me,setMe]=useState({});
        const [follower, setFollower]=useState(false);
        const [subscriber, setSubscriber]=useState(false);
        const [renewer, setRenew]=useState(null);
        const [requestSended, setRequestSended]=useState(null);
        
        const [subInfo, setSubInfo]=useState({});

    // }else{
    //     const [me,setMe]=useState({});
    //     const [follower, setFollower]=useState(false);
    //     const [subscriber, setSubscriber]=useState(false)   
    // }


    useEffect( async() => {
        if(teacher.teacher){
            const Stat=await axios.get(ads+'api/checkout/status/'+teacher._id);
            setStatus(Stat.data)
        

        const pp=(await axios.get(ads+'api/checkout/subscriptionprice/'+teacher._id)).data;
        setMonth(pp.oneMonth) 
        setThreeMonth(pp.threeMonth) 
        setSixMonth(pp.sixMonth) 
        setYear(pp.oneYear) 
        setCurrency(pp.currency) 
        }
        if(connected==true){
            setMe(user);
            if(user.subscribe.includes(teacher._id)){
                const p1=(await axios.get(ads+'api/connections/subscriptiondetails/'+user.subID+'/'+teacher._id)).data;
                if(p1!="empty"){
                    setSubInfo(p1)
                }
            }
            setSubscriber(user.subscribe.includes(teacher._id));


            if(user.renew.includes(teacher._id)){
                const p1=(await axios.get(ads+'api/connections/subscriptiondetails/'+user.subID+'/'+teacher._id)).data;
                if(p1!="empty"){
                    setSubInfo(p1)
                }
            }
            setRenew(user.renew.includes(teacher._id))
            setFollower(user.follow.includes(teacher._id));
            console.log(user.follow.includes(teacher._id))
            setRequestSended(user.followInProgress.includes(teacher._id))
            setUp(!user.subscribe.includes(teacher._id))
            setFollower(user.follow.includes(teacher._id))
            setSubscriber(user.subscribe.includes(teacher._id))
        }else{
            setUp(true)

            console.log("null")
        }
    },[user,teacher])



    const follow = async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(me._id!=undefined){
            const fol = (await axios.post(ads+'api/connections/follow/'+me._id,
            {
                id:teacher._id
            })).data
            socket.current.emit("newFollower", {follower:me._id, following:teacher._id})

            //setFollower(true);
            window.location.reload();
        }

    }
    const subscribe2 = async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(subInfo.periode!="three_month"&&subscriber){
            setOpen7(true)
        }
        if(!subscriber){
            setOpen2(true)
        }
    }
    const subscribe3 = async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(subInfo.periode!="six_month"&&subscriber){
            setOpen8(true)
        }
        if(!subscriber){
            setOpen3(true)
        }
    }
    const subscribe4 = async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(subInfo.periode!="one_year"&&subscriber){
            setOpen9(true)
        }
        if(!subscriber){
            setOpen4(true)
        }
    }
    const subscribe = async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(subInfo.periode!="one_month"&&subscriber){
            setOpen6(true)
        }
        if(!renewer&&subscriber==false&&teacher.freeSub==false){
            setOpen1(true)
        }
        if(renewer&&teacher.freeSub==false){
            setOpen14(true)
        }
        if(teacher.freeSub==true){
            const body =  {
              buyer:user._id
            }
            const check=await axios.post(ads+'api/checkout/subscription/free/'+teacher._id, body);
            socket.current.emit("newSubscriber", {subscriber:user._id, subscribing:teacher._id})

            window.location.reload();

        }


        //
    //     if(me._id!=undefined){

    //     const fol = await axios.post(ads+'api/connections/subscribe/'+me._id,
    //     {
    //         id:tempuser._id
    //     })

    //     setSubscriber(true);
    //     localStorage.setItem('userDetails',JSON.stringify(fol.data));

    //     window.location.reload();
    // }
    }

    const startConversation= async () =>{
        if(user=="unknown"){
            setOpen13(true)
            return
        }
        if(teacher.teacher){
            if(user.subscribe.includes(teacher._id)){
                const Conv=await axios.post(ads+'api/chat/conversation/'+user._id+"/"+teacher._id)
                window.location.href ="/chat/"+Conv.data.conversationId
            }else{
                setOpen11(true)
            }
        }else{
            if(teacher.visibility){
                const Conv=await axios.post(ads+'api/chat/conversation/'+user._id+"/"+teacher._id)
                window.location.href ="/chat/"+Conv.data.conversationId
            }else{
                if(user.follow.includes(teacher._id)){
                    const Conv=await axios.post(ads+'api/chat/conversation/'+user._id+"/"+teacher._id)
                    window.location.href ="/chat/"+Conv.data.conversationId
                }else{
                    setOpen12(true)

                }
            }
        }

    }



    const unfollow = async () =>{
        const unfol = (await axios.put(ads+'api/connections/follow/'+me._id,
        {
            id:teacher._id
        })).data
        setFollower(false);

        window.location.reload();
    }

    const unsubscribe = async () =>{
        setOpen10(true)


    }
    return (
        <>
        <div>
            <Container>
                <Covercontainer>
                    <div style={{width:"100%" ,position:"relative"}}>
                    <Picture src={ads+teacher.coverPicture}/>

                    </div>
                    <Title>{teacher.username}</Title>
                    <Networks>
                    {teacher.instagram!=""?
                    <a href={teacher.instagram}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >

                        <Icon >
                            < InstagramIcon />
                        </Icon>
                        </a>

                        :null
                    }
                    {teacher.facebook!=""?
                    <a href={teacher.facebook}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <FacebookOutlinedIcon/>
                        </Icon>
                    </a>
                        :null
                    }
                    {teacher.twitter!=""?
                    <a href={teacher.twitter}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <TwitterIcon/>
                        </Icon>
                    </a>
                         :null
                    }       
                    {teacher.perso!=""?
                    <a href={teacher.perso}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <LanguageIcon/>
                        </Icon>
                    </a>
                         :null
                    }                    
                    </Networks>

                </Covercontainer>





               


                <Description>
                    <Picturecontainer>
                    <Profilepicture src={ads+teacher.profilePicture}/>

                    
                    </Picturecontainer>
                    <Options>
{user=="unknown"?
    <Follow1 onClick={() => setOpen13(true)}>
                    <Text3 >SUIVRE</Text3>
                    </Follow1>
                    :null}
{follower==true?
                        <Follow1 onClick={() => unfollow()}>
                            <Text01 >NE PLUS SUIVRE</Text01>
                        </Follow1>
                    :
null
                    }
{follower==false&&requestSended==false?
    <Follow1 onClick={() => follow()}>
                    <Text3 >SUIVRE</Text3>
                    </Follow1>
                    :null}


{requestSended==true?
                        <Follow1 >
                            <Text01 >REQUÊTE ENVOYÉE</Text01>
                        </Follow1>
                    :null
                    }
{user!="unknown"&&status==true?
<Follow3 onClick={() => setOpen5(true)}>
<CoffeeOutlinedIcon style={{fontSize:'inherit'}} />
</Follow3>
:null}

{user=="unknown"?
<Follow3 onClick={() => setOpen13(true)}>
<CoffeeOutlinedIcon style={{fontSize:'inherit'}} />
</Follow3>
:null}

<Follow3  onClick={() => startConversation()}>
<SmsOutlinedIcon style={{fontSize:'inherit'}}/>
</Follow3>

</Options>

                </Description>

                <InfoContainer>
                    <div
      dangerouslySetInnerHTML={{__html:teacher.description}}
    />
                    
                    </InfoContainer>

                <InfoContainerM>
                    <div
      dangerouslySetInnerHTML={{__html:teacher.description}}
    />
                                    

                    </InfoContainerM>
{teacher.teacher==true?          
<div>


                <Subscription>
                    <Sub>
                    ABONNEMENT
                    </Sub>

                {subscriber&&subInfo.periode=="one_month"?
                <div>
                    <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                        <Text >ABONNÉ</Text>
                        {currency=="U.S. Dollar"&&Month!=""?
                        <Text1 >{"$" +Month+ "  par mois"}</Text1>
                        :null}
                        {currency=="Euro"&&Month!=""?
                            <Text1 >{Month+ "€  par mois"}</Text1>
                        :null}
                    </Subscribe>
                    <NextP>
                    {/* Next payment {new Date(subInfo.nextPayment).toDateString()} */}
                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                    </NextP>
                </div>
                    :
                    
                
null
                }
                                {subscriber&&subInfo.periode=="three_month"?
                <div>
                    <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                        <Text >ABONNÉ</Text>
                        {currency=="U.S. Dollar"&&ThreeMonth!=""?
                        <Text1 >{"$" +ThreeMonth+ "  pour 3 mois"}</Text1>
                        :null}
                        {currency=="Euro"&&ThreeMonth!=""?
                            <Text1 >{ThreeMonth+ "€  pour 3 mois"}</Text1>
                        :null}
                    </Subscribe>
                    <NextP>
                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                    </NextP>
                </div>
                    :null}


{subscriber&&subInfo.periode=="six_month"?
                <div>
                    <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                        <Text >ABONNÉ</Text>
                        {currency=="U.S. Dollar"&&SixMonth!=""?
                        <Text1 >{"$" +SixMonth+ "  pour 6 mois"}</Text1>
                        :null}
                        {currency=="Euro"&&SixMonth!=""?
                            <Text1 >{SixMonth+ "€  pour 6 mois"}</Text1>
                        :null}
                    </Subscribe>
                    <NextP>
                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                    </NextP>
                </div>
                    :null}

{subscriber&&subInfo.periode=="one_year"?
                <div>
                    <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                        <Text >ABONNÉ</Text>
                        {currency=="U.S. Dollar"&&Year!=""?
                        <Text1 >{"$" +Year+ "  par an"}</Text1>
                        :null}
                        {currency=="Euro"&&Year!=""?
                            <Text1 >{Year+ "€  par an"}</Text1>
                        :null}
                    </Subscribe>
                    <NextP>
                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                    </NextP>
                </div>
                    :null}


                                {subscriber&&subInfo.free==true?
                <div>
                    <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                        <Text >ABONNÉ</Text>
                        <Text1 >GRATUITEMENT</Text1>

                    </Subscribe>

                </div>
                    :
                    
                
null
                }

{!renewer&&!subscriber?           
 <Subscribe onClick={() => subscribe()}>
                    <Text >S'ABONNER</Text>
                        {currency=="U.S. Dollar"&&Month!=""?
                        <Text1 >{"$" +Month+ "  par mois"}</Text1>
                        :null}
                        {currency=="Euro"&&Month!=""?
                        <Text1 >{Month+ "€  par mois"}</Text1>
                        :null}

                        {teacher.freeSub==true?
                        <Text1 >GRATUITEMENT</Text1>
                        :null}
                </Subscribe>:null}
                {renewer&&teacher.freeSub?
                <Subscribe onClick={() => subscribe()}>
                <Text >SE RÉABONNER</Text>


                    
                    <Text1 >GRATUITEMENT</Text1>
                    
            </Subscribe>
:null}
                {renewer&&!teacher.freeSub&&Month!=""?
                <div>
                <Subscribe onClick={() => subscribe()}>
                <Text >SE RÉABONNER</Text>
                    {currency=="U.S. Dollar"&&Month!=""?
                    <Text1 >{"$" +Month+ "  par mois"}</Text1>
                    :null}
                    {currency=="Euro"&&Month!=""?
                    <Text1 >{Month+ "€  par mois"}</Text1>
                    :null}

                    {teacher.freeSub==true?
                    <Text1 >GRATUITEMENT</Text1>
                    :null}
            </Subscribe>
                            <div style={{color:"grey", float:"right", marginTop:"10px", marginRight:"15px", fontSize:"14px"}}>Fin de votre abonnement {new Date(subInfo.end_subscription).toDateString()}</div>
                            </div>
            :null}

                </Subscription>
                {teacher.freeSub==false&&(ThreeMonth!=""||SixMonth!=""||Year!="")?
                <SubscriptionB>
                    <div style={{display:"flex"}}>
                    <Sub>
                    OFFRES D'ABONNEMENTS
                    </Sub>
                    {up?
                    <KeyboardArrowUpIcon onClick={() => setUp(false)} style={{cursor:"pointer",marginLeft:"auto", marginRight:"10px", paddingTop:"5px", fontSize:"30px"}}/>:
                    <KeyboardArrowDownIcon onClick={() => setUp(true)} style={{cursor:"pointer",marginLeft:"auto", marginRight:"10px", paddingTop:"5px", fontSize:"30px"}}/>
                    }

                    </div>

                {up?
                <div>
                {ThreeMonth!=""&&subInfo.periode!="three_month"?
                <Subscribe onClick={() => subscribe2()}>
                <Text >3 MOIS</Text>
                {currency=="U.S. Dollar"&&ThreeMonth!=""?
                <Text1 >{"$" +ThreeMonth+ " au total"}</Text1>
                :null}
                {currency=="Euro"&&ThreeMonth!=""?
                <Text1 >{ThreeMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                
                :null}
                {subInfo.periode=="three_month"?
                <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                <Text >ABONNÉ POUR 3 MOIS</Text>
                {currency=="U.S. Dollar"&&ThreeMonth!=""?
                <Text1 >{"$" +ThreeMonth+ " au total"}</Text1>
                :null}
                {currency=="Euro"&&ThreeMonth!=""?
                <Text1 >{ThreeMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                :null}
                {subInfo.periode=="three_month"?
                <NextP>                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                </NextP>
                :null}
                
                {SixMonth!=""&&subInfo.periode!="six_month"?
                <Subscribe onClick={() => subscribe3()}>
                <Text >6 MOIS</Text>
                {currency=="U.S. Dollar"?
                <Text1 >{"$" +SixMonth+ "au total"}</Text1>
                :null}
                {currency=="Euro"?
                <Text1 >{SixMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                :null}



{subInfo.periode=="six_month"?
                <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                <Text >ABONNÉ POUR 6 MOIS</Text>
                {currency=="U.S. Dollar"&&SixMonth!=""?
                <Text1 >{"$" +SixMonth+ " au total"}</Text1>
                :null}
                {currency=="Euro"&&SixMonth!=""?
                <Text1 >{SixMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                :null}
                {subInfo.periode=="six_month"?
                <NextP>                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                </NextP>
                :null}


                {Year!=""&&subInfo.periode!="one_year"?
                <Subscribe onClick={() => subscribe4()}>
                <Text >1 AN</Text>
                {currency=="U.S. Dollar"?
                <Text1 >{"$" +Year+ "au total"}</Text1>
                :null}
                {currency=="Euro"?
                <Text1 >{Year+ "€ au total"}</Text1>
                :null}

                </Subscribe>
                :null}

{subInfo.periode=="one_year"?
                <Subscribe onClick={() => unsubscribe()} style={{backgroundColor:"white", color:"#8798ef", border:"2px solid #8798ef"}}>
                <Text >ABONNÉ POUR 1 AN</Text>
                {currency=="U.S. Dollar"&&Year!=""?
                <Text1 >{"$" +Year+ " au total"}</Text1>
                :null}
                {currency=="Euro"&&Year!=""?
                <Text1 >{Year+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                :null}
                {subInfo.periode=="one_year"?
                <NextP>                    Prochain paiement {new Date(subInfo.nextPayment).toDateString()}
                </NextP>
                :null}



                </div>
                :null}
                </SubscriptionB>
:null}
</div>: <Line/>}

               
            </Container>

        </div>
        {open && <Validatesubscription ads={ads} tempuser={user} socket={socket} setOpen={setOpen1} owner={teacher} price={Month} currency={currency} subInfo={subInfo}/>}
        {open2 && <Validatesubscription1 ads={ads} tempuser={user} socket={socket} setOpen={setOpen2} owner={teacher} price={ThreeMonth} currency={currency} subInfo={subInfo}/>}
        {open3 && <Validatesubscription2 ads={ads} tempuser={user} socket={socket} setOpen={setOpen3} owner={teacher} price={SixMonth} currency={currency} subInfo={subInfo}/>}
        {open4 && <Validatesubscription3 ads={ads} tempuser={user} socket={socket} setOpen={setOpen4} owner={teacher} price={Year} currency={currency} subInfo={subInfo}/>}
        {open6 && <Modifysubscription user={user} ads={ads} setOpen={setOpen6} owner={teacher} Frequency={"one_month"} Month={Month} currency={currency} subInfo={subInfo}/>}
        {open7 && <Modifysubscription user={user} ads={ads} setOpen={setOpen7} owner={teacher} Frequency={"three_month"} Month={ThreeMonth} currency={currency} subInfo={subInfo}/>}
        {open8 && <Modifysubscription user={user} ads={ads} setOpen={setOpen8} owner={teacher} Frequency={"six_month"} Month={SixMonth} currency={currency} subInfo={subInfo}/>}
        {open9 && <Modifysubscription user={user} ads={ads} setOpen={setOpen9} owner={teacher} Frequency={"one_year"} Month={Year} currency={currency} subInfo={subInfo}/>}
        {open10 && <Deletesubscription  ads={ads} setOpen={setOpen10} owner={teacher} customer={user} subInfo={subInfo}/>}
        {open12 && <FollowUser  ads={ads} setOpen={setOpen12} owner={teacher} customer={user} subInfo={subInfo}/>}
        {open11 && <SubscribeUser ads={ads} socket={socket} user={user} Year={Year} SixMonth={SixMonth}  ThreeMonth={ThreeMonth} currency={currency} Month={Month} setOpen={setOpen11} owner={teacher} customer={user} subInfo={subInfo}/>}
        {open13 && <LogYou   setOpen={setOpen13} />}
        {open14 && <RenewSub user={user} ads={ads} setOpen={setOpen14} owner={teacher} Frequency={"one_month"} Month={Month} currency={currency} subInfo={subInfo}/>}

        {open5 && <BuyTea ads={ads} user={user} socket={socket} setOpen={setOpen5} owner={teacher} currency={currency}/>}
        

    </>
    )
}

export default Coverpicture1
