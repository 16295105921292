import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useState, useEffect} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";
import NoVideo from './NoVideo';


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({width:"100%", padding:"0", marginTop:"80px"})};

`;

const Label1 = styled.label`
  font-size: 18px;
  ${mobile({display:"none"})};

`;
const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
  ${tablette({fontSize:"13px"})};
  ${mobile({fontSize:"13px"})};
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  ${tablette({fontSize:"15px"})};

  ${mobile({fontSize:"15px"})};

`;
const Label = styled.label`
  font-size: 18px;
`;
const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;

`;
const Button = styled.button`
  width: 250px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;
const Options1=styled.div`
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
`;
const Option = styled.option``;

const Seriesparameters = ({user,ads}) => {
    const navigate = useNavigate();

    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState(undefined);
    const [cover, setCover] = useState(undefined);
    const [noVideo, setNoVideo] = useState(false);

    const [video, setVideo] = useState(undefined);
    const [filters, setFilters] = useState({diff:"Beginner",intens:"Level 1"});
    const [theArray, setTheArray] = useState([]);
    const editorRef = useRef(null);
    const [progress, setProgress] = React.useState(0);
    const [progress2, setProgress2] = React.useState(0);

    const handleFilters = (e) => {
        const value = e.target.value;
        setFilters({
          ...filters,
          [e.target.name]: value,
        });
        
      };
    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }

    useEffect(async() => {
        try { 
          const res= (await axios.get(ads+'api/video/all/'+user._id)).data;
          if(res.length<=1){
            setNoVideo(true)
          }
            setCreator(user);
        }catch(e){


        }
  
    },[])

    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }

    CircularProgressWithLabel.propTypes = {
      /**
       * The value of the progress indicator for the determinate variant.
       * Value between 0 and 100.
       * @default 0
       */
      value: PropTypes.number.isRequired,
    };

    const createSeries= async()=>{
      const course = {
        "title":title,
        "description":editorRef.current.getContent(),
        "difficulty":filters.diff,
        "intensity": filters.intens,
        "style":theArray
      }


    const id = (await axios.post(ads+"api/series/"+creator._id,course)).data



    const formDataThumb = new FormData()
    formDataThumb.append('profile', img, img.name)
    const thum = await axios.post(ads+"api/series/thumbnails/"+id._id,formDataThumb,
    {   
      onUploadProgress: progressEvent => {
        setProgress2((progressEvent.loaded / progressEvent.total)*100)
          console.log(progressEvent.loaded / progressEvent.total);
      }
    })

    const formDataCover = new FormData()
    formDataCover.append('profile', cover, cover.name)
    const cov = await axios.post(ads+"api/series/cover/"+id._id,formDataCover,
    {   
      onUploadProgress: progressEvent => {
        setProgress((progressEvent.loaded / progressEvent.total)*100)
          console.log(progressEvent.loaded / progressEvent.total);
      }
    })

      navigate('/selectseries1/'+id._id);

    }



    return (
      <>

        <Container>
            <h2 style={{textAlign:"center", maxWidth:"80%", marginLeft:"auto", marginRight:"auto"}}>
            Étape 1 : renseignez les caractéristiques de votre Série
</h2>
            <Wrapper2>
            <Label>Titre :</Label>

<Input
value={title}
  type="text"
  placeholder="Titre"
  name="title"
  onChange={(e) => setTitle(e.target.value)}
/>
<br/>
<Label>Description :</Label>

{/* <Desc
value={description}

          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => setDescription(e.target.value)}
        />
<br/> */}
{/* <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        init={{
          placeholder: "Description",
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'emoticons'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          '|emoticons|'+
          'removeformat | help',
          content_style: 'body { font-family:Urbanist, sans-serif; font-size:18px,font-weight:400,letter-spacing: 15px;line-height: 1.6; }'


        }}
      /> */}

<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />


<div style={{display:"flex", alignItems:"center"}}>
<Label>Miniature :</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => setImg(e.target.files[0])}
/>
</div>
<CircularProgressWithLabel value={progress2} />

<br/>
<div style={{display:"flex", alignItems:"center"}}>
<Label>Image de couverture de votre Série :</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => setCover(e.target.files[0])}
/>
</div>
<CircularProgressWithLabel value={progress} />

{/* <Image src={"http://51.178.60.185/"+Video.thumbnails[0]} /> */}
<br/>
<Options 
>
  
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"40px"}}>Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>
</Options>


<OptionsS >

<Label>Style :</Label>


<Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
            </FilternameS>
            : null}

            {theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
            </FilternameS>
            : null}


   
        </Containerfilters> 

        <Containerfilters >

        {theArray.includes(8)==false?
<Filtername onClick={() => validate(8)}>
    PRANAYAMA
</Filtername>
: null}

{theArray.includes(8)==true?
<FilternameS onClick={() => validate(8)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
</FilternameS>
: null}


            {theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
            </FilternameS>
            : null}

{theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
            </FilternameS>
            : null}

   
        </Containerfilters> 
        <Containerfilters >

        {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
            </FilternameS>
            : null}

{theArray.includes(7)==false?
<Filtername onClick={() => validate(7)}>
    YIN
</Filtername>
: null}

{theArray.includes(7)==true?
<FilternameS onClick={() => validate(7)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
</FilternameS>
: null}






</Containerfilters> 



<div style={{marginTop:"10px"}}>
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>
          </div>
          <div style={{marginTop:"30px"}}>
          <Label >Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>

          </div>

        </OptionsS>
   
        <Label1>Style :</Label1>

        <Options1>


<Containerfilters >
    {theArray.includes(1)==false?
    <Filtername onClick={() => validate(1)}>
        ASHTANGA
    </Filtername>
    : null}

    {theArray.includes(1)==true?
    <FilternameS onClick={() => validate(1)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> ASHTANGA
    </FilternameS>
    : null}

{theArray.includes(2)==false?
    <Filtername onClick={() => validate(2)}>
        HATHA
    </Filtername>
    : null}

    {theArray.includes(2)==true?
    <FilternameS onClick={() => validate(2)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> HATHA
    </FilternameS>
    : null}



    {theArray.includes(3)==false?
    <Filtername onClick={() => validate(3)}>
        KUNDALINI
    </Filtername>
    : null}

    {theArray.includes(3)==true?
    <FilternameS onClick={() => validate(3)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> KUNDALINI
    </FilternameS>
    : null}

{theArray.includes(8)==false?
<Filtername onClick={() => validate(8)}>
PRANAYAMA
</Filtername>
: null}

{theArray.includes(8)==true?
<FilternameS onClick={() => validate(8)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRANAYAMA
</FilternameS>
: null}








</Containerfilters> 


<Containerfilters >


{theArray.includes(5)==false?
    <Filtername onClick={() => validate(5)}>
        PRENATAL
    </Filtername>
    : null}

    {theArray.includes(5)==true?
    <FilternameS onClick={() => validate(5)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> PRENATAL
    </FilternameS>
    : null}

    {theArray.includes(4)==false?
    <Filtername onClick={() => validate(4)}>
        RESTORATIVE
    </Filtername>
    : null}

    {theArray.includes(4)==true?
    <FilternameS onClick={() => validate(4)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> RESTORATIVE
    </FilternameS>
    : null}

{theArray.includes(6)==false?
    <Filtername onClick={() => validate(6)}>
        VINYASA
    </Filtername>
    : null}

    {theArray.includes(6)==true?
    <FilternameS onClick={() => validate(6)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> VINYASA
    </FilternameS>
    : null}


{theArray.includes(7)==false?
<Filtername onClick={() => validate(7)}>
YIN
</Filtername>
: null}

{theArray.includes(7)==true?
<FilternameS onClick={() => validate(7)}>
<DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> YIN
</FilternameS>
: null}


</Containerfilters> 

</Options1>
            </Wrapper2>






<div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={createSeries} >SUIVANT</Button>

</div>



           
        </Container>
                    {noVideo && <NoVideo user={user}/>}

                    </>
    )
}

export default Seriesparameters
